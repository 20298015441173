import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy, useEffect } from 'react';

import NotFoundView from 'views/other/NotFound';
import Preloader from 'components/Preloader';
import routes from './routes';
import url from './url';
import useAppState from 'hooks/useAppState';
import useInstanceSettings from 'hooks/useInstanceSettings';

const DashboardLayout = lazy(() => import('core/DashboardLayout'));

const routesCache = {};
const renderRoutes = (key) => {
    if (!routesCache[key]) {
        routesCache[key] = routes[key].map((route) => {
            if (route.redirect) {
                return (
                    <Redirect
                        key={route.path || ''}
                        from={route.path}
                        exact
                        to={route.redirect}
                    />
                );
            }

            return (
                <Route
                    key={route.path || ''}
                    exact
                    path={route.path}
                    component={lazy(route.view)}
                />
            );
        });
    }

    return <Switch>{routesCache[key]}</Switch>;
};

const getRoutesPaths = (namespace) =>
    routes[namespace].map((route) => route.path);
const publicRoutesPaths = getRoutesPaths('public');
let guestRoutesPaths = getRoutesPaths('guest');
const adminRoutesPaths = getRoutesPaths('admin');
const dashRoutesPaths = getRoutesPaths('dash');
const publicRoutes = (
    <Route
        exact
        path={publicRoutesPaths}
        render={() => renderRoutes('public')}
    />
);

const Router = () => {
    const { user } = useAppState();
    const { allow_login, allow_register } = useInstanceSettings();

    useEffect(() => {
        if (allow_login && allow_register) {
            return;
        }
        guestRoutesPaths = guestRoutesPaths.filter((route) => {
            if (!allow_login && route.url === url('auth.login')) {
                return false;
            }
            if (!allow_register && route.url === url('auth.register')) {
                return false;
            }

            return true;
        });
    }, [allow_login, allow_register]);

    return (
        <Suspense fallback={<Preloader show />}>
            <BrowserRouter>
                <Route
                    render={() => {
                        if (!user || !user.uuid) {
                            return (
                                <Suspense fallback={<Preloader show />}>
                                    <Switch>
                                        <Route
                                            exact
                                            path={[
                                                '/',
                                                ...adminRoutesPaths,
                                                ...dashRoutesPaths,
                                            ]}
                                            render={() => (
                                                <Redirect
                                                    to={url('auth.login')}
                                                />
                                            )}
                                        />
                                        {publicRoutes}
                                        <Route
                                            path={guestRoutesPaths}
                                            render={() => renderRoutes('guest')}
                                        />
                                    </Switch>
                                </Suspense>
                            );
                        }

                        const userNamespace = user.is_admin ? 'admin' : 'dash';
                        const redirectTo = url(userNamespace);
                        return (
                            <DashboardLayout>
                                <Suspense fallback={<Preloader show />}>
                                    <Switch>
                                        <Route
                                            exact
                                            path={['/', ...guestRoutesPaths]}
                                            render={() => (
                                                <Redirect to={redirectTo} />
                                            )}
                                        />
                                        <Route
                                            path={
                                                user.is_admin
                                                    ? url('dash')
                                                    : url('admin')
                                            }
                                            render={() => (
                                                <Redirect to={redirectTo} />
                                            )}
                                        />
                                        {publicRoutes}
                                        <Route
                                            exact
                                            path={getRoutesPaths(userNamespace)}
                                            render={() =>
                                                renderRoutes(userNamespace)
                                            }
                                        />
                                        <Route component={NotFoundView} />
                                    </Switch>
                                </Suspense>
                            </DashboardLayout>
                        );
                    }}
                />
            </BrowserRouter>
        </Suspense>
    );
};

export default Router;
