import {
    ACTION_CONSENT_RESET,
    ACTION_STORE,
    ACTION_UPDATE,
    ACTION_WITHDRAW,
} from 'constants/consentActions';
import { Col, Row } from 'reactstrap';

import { CONSENT_POLICY_GRANULAR_OPTIN } from 'constants/consentPolicies';
import CountryFlag from 'components/CountryFlag';
import FormattedDate from 'components/FormattedDate';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './ConsentListItem.scss';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);

const ConsentListItem = ({ item }) => {
    const [trans] = useTranslate('common:consents_list_view.audit_trail_item');
    const usesGranular = item.consent_policy === CONSENT_POLICY_GRANULAR_OPTIN;

    return (
        <div className={cx('item')}>
            <Row>
                <Col xs="12" md="auto" className={cx('mb-2')}>
                    <div className={cx('country')}>
                        {item.visitor_country ? (
                            <CountryFlag
                                className={cx('countryFlag')}
                                country={item.visitor_country}
                            />
                        ) : (
                            <Icon
                                className={cx('noCountryIcon')}
                                icon="fa/light/globe"
                            />
                        )}
                    </div>
                </Col>
                <Col xs="12" md>
                    <Row
                        className={cx(
                            'align-items-start',
                            'text-center',
                            'text-md-left'
                        )}
                    >
                        <Col md>
                            <h3 className={cx('visitorId')}>
                                {item.visitor_id}
                            </h3>
                            <div className={cx('text-muted')}>
                                {[ACTION_STORE, ACTION_UPDATE].includes(
                                    item.action
                                ) && (
                                    <strong>
                                        {usesGranular
                                            ? trans('consented_services', {
                                                  consentCount:
                                                      item.consent_count,
                                              })
                                            : trans('consented_categories', {
                                                  consentCount:
                                                      item.consent_count,
                                              })}
                                    </strong>
                                )}
                                {ACTION_WITHDRAW === item.action && (
                                    <strong>{trans('withdrawed')}</strong>
                                )}
                                {ACTION_CONSENT_RESET === item.action && (
                                    <strong>{trans('reset')}</strong>
                                )}
                            </div>
                            <div>
                                {trans('granular_enabled')}
                                <Icon
                                    icon={
                                        usesGranular
                                            ? 'fa/light/check'
                                            : 'fa/light/times'
                                    }
                                    className={cx('ml-2', {
                                        'text-success': usesGranular,
                                        'text-danger': !usesGranular,
                                    })}
                                />
                            </div>
                        </Col>

                        <Col md="auto">
                            <FormattedDate
                                from={item.created_at}
                                format="L LT [UTC]"
                                utc
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

ConsentListItem.propTypes = {
    item: PropTypes.object,
};

export default ConsentListItem;
