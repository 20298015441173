import PropTypes from 'prop-types';
import React from 'react';

const MethodField = ({ method }) => {
    return <input type="hidden" name="_method" value={method} />;
};

MethodField.propTypes = {
    method: PropTypes.oneOf(['PUT', 'PATCH', 'DELETE']),
};

export default MethodField;
