import {
    Dropdown as BsDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import React, { useState } from 'react';

import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _trim from 'lodash/trim';
import classNames from 'helpers/classNames';
import styles from './Dropdown.scss';

const cx = classNames(styles);

const Dropdown = ({
    className,
    toggleClassName,
    onChoose,
    multiple,
    label,
    color,
    outline,
    icon,
    options,
    value,
    disabled,
    searchable,
    renderLabel,
    alwaysMainLabelOnToggle,
    invalid,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const currValue = Array.isArray(value) ? value : [value];
    const handleTogle = () => setIsOpen((prevOpen) => !prevOpen);

    const handleChoose = (item) => {
        if (disabled) {
            return;
        }

        if (!multiple) {
            onChoose([item.value]);
            return;
        }

        const newValue = [...currValue]
            .filter((i) => i !== item.value)
            .filter((i) => !!i);

        // only add selected item if not currently added (to allow for deseleting)
        if (!currValue.includes(item.value)) {
            newValue.push(item.value);
        }
        onChoose(newValue);
    };
    const selectedItems = options.filter((i) => currValue.includes(i.value));
    const items = searchValue
        ? options.filter((i) =>
              i.label.toLowerCase().includes(_trim(searchValue.toLowerCase()))
          )
        : options;
    const showSearch = !!searchable && options.length > 0;
    return (
        <BsDropdown
            className={cx(className, { multiple })}
            isOpen={isOpen}
            toggle={handleTogle}
            aria-invalid={invalid}
        >
            <DropdownToggle
                className={cx('toggle', toggleClassName)}
                color={color}
                caret
                outline={outline}
                disabled={disabled}
            >
                {!!icon && <Icon icon={icon} className={cx('mr-1')} />}
                <span className={cx('values')}>
                    {(!selectedItems.length || alwaysMainLabelOnToggle) && (
                        <span className={cx('text')}>{label}</span>
                    )}

                    {!alwaysMainLabelOnToggle &&
                        selectedItems.map((item, index) => (
                            <span key={item.value}>
                                {renderLabel(item)}
                                {index !== selectedItems.length - 1 && (
                                    <>,&nbsp;</>
                                )}
                            </span>
                        ))}
                </span>
            </DropdownToggle>
            <DropdownMenu className={cx('menu')}>
                {showSearch && (
                    <input
                        autoFocus
                        className={cx('ml-4', 'my-2')}
                        placeholder="Type to filter..."
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                    />
                )}
                {items.map((item) => (
                    <DropdownItem
                        disabled={item.disabled}
                        active={currValue.includes(item.value)}
                        key={item.value}
                        onClick={() => handleChoose(item)}
                    >
                        {renderLabel(item)}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </BsDropdown>
    );
};

Dropdown.propTypes = {
    className: PropTypes.string,
    toggleClassName: PropTypes.string,
    onChoose: PropTypes.func,
    label: PropTypes.node,
    icon: PropTypes.string,
    outline: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.node,
        })
    ),
    invalid: PropTypes.bool,
    value: PropTypes.any,
    searchable: PropTypes.bool,
    alwaysMainLabelOnToggle: PropTypes.bool,
    renderLabel: PropTypes.func,
};

Dropdown.defaultProps = {
    className: '',
    toggleClassName: '',
    onChoose: _noop,
    label: 'Dropdown',
    icon: '',
    outline: false,
    color: 'primary',
    disabled: false,
    multiple: false,
    options: [],
    value: null,
    searchable: false,
    alwaysMainLabelOnToggle: false,
    invalid: false,
    renderLabel: (i) => i.label,
};

export default Dropdown;
