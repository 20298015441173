import bsCSSModule from 'styles/bootstrap';
import clsx from 'clsx';

// helper function to bind css modules styles from bootstrap and component in one convenient function
// usage:
// import classNames from 'helpers/classNames';
// import styles from './styles.scss';
// const cx = classNames(styles)
// <div className={cx('col-md-6', 'componentClassName')} />

function bind(styles = {}) {
    return function (...args) {
        let i = 0;
        let arr = [];
        let out = '';
        let tmp = clsx(args);

        if (tmp) {
            for (arr = tmp.split(' '); i < arr.length; i++) {
                if (out) {
                    out += ' ';
                }
                const original = arr[i];
                out += styles[original] || original;
            }
        }

        return out;
    };
}
export default (styles = {}) => bind({ ...bsCSSModule, ...styles });
