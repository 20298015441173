import { Col, Row, Table } from 'reactstrap';

import API from 'helpers/api';
import InvoiceListItem from '../InvoiceListItem';
import React from 'react';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './InvoiceListTab.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import useAppState from 'hooks/useAppState';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const InvoiceListTab = () => {
    const { t } = useTranslation();
    const { user } = useAppState();
    const apiNS = useApiNamespace();
    const [invoices, fetchErr, fetchState] = useRequest(
        API.get(`/${apiNS}/clients/${user.client.uuid}/get-invoices`)
    );

    return (
        <Row>
            <Col xs="12">
                {fetchState.isLoading && <Spinner />}
                {!fetchState.isLoading && fetchState.isSuccess && (
                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        {t(
                                            'common:profile.billing.invoice_listing_columns.number'
                                        )}
                                    </th>
                                    <th>
                                        {t(
                                            'common:profile.billing.invoice_listing_columns.date'
                                        )}
                                    </th>
                                    <th>
                                        {t(
                                            'common:profile.billing.invoice_listing_columns.description'
                                        )}
                                    </th>
                                    <th>
                                        {t(
                                            'common:profile.billing.invoice_listing_columns.amount'
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice, index) => (
                                    <InvoiceListItem
                                        key={index}
                                        invoice={invoice}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    </>
                )}
            </Col>
        </Row>
    );
};

export default InvoiceListTab;
