import useAppState from './useAppState';
import { useTranslation } from 'react-i18next';

const useCookieCategories = () => {
    const { t } = useTranslation();
    const { cookieCategories } = useAppState();

    const categories = cookieCategories.map((i) => ({
        value: i,
        label: t(`common:categories.${i}`),
    }));

    return [categories];
};

export default useCookieCategories;
