import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './ScriptLogo.scss';

const cx = classNames(styles);

const ScriptLogo = ({ src, alignLogo, className, ...props }) => (
    <div {...props} className={cx(className)}>
        <div
            className={cx('logo', `align-${alignLogo || 'center'}`, {
                empty: !src,
            })}
            style={{ backgroundImage: `url('${src}')` }}
        />
    </div>
);

ScriptLogo.propTypes = {
    alignLogo: PropTypes.oneOf(['left', 'right', 'center']),
    src: PropTypes.string,
    className: PropTypes.string,
};

ScriptLogo.defaultProps = {
    alignLogo: 'center',
    src: '',
    className: '',
};

export default ScriptLogo;
