import useApiNamespace from './useApiNamespace';
import { useCallback } from 'react';
import useLang from './useLang';
import { useTranslation } from 'react-i18next';

const useTranslate = (prefix = '') => {
    const { t } = useTranslation();
    const { lang } = useLang();
    const userNS = useApiNamespace();
    const translate = useCallback(
        (key, ...args) => {
            let finalKey = prefix;
            // add dot between prefix and key, if prefix was provided
            finalKey = finalKey ? `${finalKey}.${key}` : key;
            if (finalKey.indexOf('userNS') === 0) {
                finalKey = finalKey.replace('userNS', userNS);
            }

            return t(finalKey, ...args);
        },
        [prefix, lang, userNS]
    );

    return [translate, lang];
};

export default useTranslate;
