import React, { useEffect } from 'react';

import Button from 'components/Button';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './AssetsVersionNotifier.scss';
import useAppState from 'hooks/useAppState';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const AssetsVersionNotifier = () => {
    const { assetsVersion, initialAssetsVersion } = useAppState();
    const notify = useNotifications();
    const { t } = useTranslation();

    useEffect(() => {
        if (assetsVersion && assetsVersion !== initialAssetsVersion) {
            notify.info(
                <>
                    <p>{t('common:common.new_assets_version')}</p>
                    <p className={cx('mt-2')}>
                        <Button
                            color="info"
                            outline
                            icon="md/ic_refresh"
                            label={t('common:common.load_new_version')}
                            onClick={() => window.location.reload()}
                        />
                    </p>
                </>,
                { timeout: -1 }
            );
        }
    }, [assetsVersion, initialAssetsVersion]);

    return null;
};

AssetsVersionNotifier.propTypes = {};

AssetsVersionNotifier.defaultProps = {};

export default AssetsVersionNotifier;
