import {
    ACTION_CONSENT_RESET,
    ACTION_STORE,
    ACTION_UPDATE,
    ACTION_WITHDRAW,
} from 'constants/consentActions';
import React, { useMemo } from 'react';

import AcceptedBadge from '../AcceptedBadge/AcceptedBadge';
import { CONSENT_POLICY_GRANULAR_OPTIN } from 'constants/consentPolicies';
import CountryFlag from 'components/CountryFlag';
import FormattedDate from 'components/FormattedDate';
import Icon from 'components/Icon/Icon';
import PropTypes from 'prop-types';
import _upperFirst from 'lodash/upperFirst';
import classNames from 'helpers/classNames';
import geoData from 'constants/geoData';
import styles from './AuditTrailItem.scss';
import useCookieCategories from 'hooks/useCookieCategories';
import useLang from 'hooks/useLang';
import useTranslate from 'hooks/useTranslate';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const AuditTrailItem = ({ item = {} }) => {
    const { t } = useTranslation();
    const [trans] = useTranslate('common:consents_list_view.audit_trail_item');
    const { lang } = useLang();

    const [categories] = useCookieCategories();

    const [country, region] = useMemo(() => {
        if (!item.visitor_country) {
            return [null, null];
        }

        const country = geoData[item.visitor_country] || null;
        if (!country) {
            return [null, null];
        }

        const region = country.regions.find(
            (r) => r.iso === item.visitor_region
        );

        return [country, region];
    }, [item.visitor_country, item.visitor_region]);

    const deviceInfo = useMemo(() => {
        const {
            device_browser,
            device_os,
            device_brand,
            device_model,
            device_type,
        } = item;
        const platform = [device_os, device_brand, device_model].join(' ');
        const type = t(`common:devices.${device_type}`);
        return `${device_browser} (${platform}) / ${type}`;
    }, [item, lang]);

    const usesGranular = item.consent_policy === CONSENT_POLICY_GRANULAR_OPTIN;

    return (
        <div>
            <div className={cx('header', 'd-flex', 'align-items-center')}>
                <div className={cx('country')}>
                    {item.visitor_country ? (
                        <CountryFlag
                            className={cx('countryFlag')}
                            country={item.visitor_country}
                        />
                    ) : (
                        <Icon
                            className={cx('noCountryIcon')}
                            icon="fa/light/globe"
                        />
                    )}
                </div>
                <div className={cx('date')}>
                    <FormattedDate
                        from={item.created_at}
                        format="LLL [UTC]"
                        utc
                    />
                    {', '}
                    {[ACTION_STORE, ACTION_UPDATE].includes(item.action) && (
                        <span className={cx('font-weight-bold')}>
                            {usesGranular
                                ? trans('consented_services', {
                                      consentCount: item.consent_count,
                                  })
                                : trans('consented_categories', {
                                      consentCount: item.consent_count,
                                  })}
                        </span>
                    )}
                    {ACTION_WITHDRAW === item.action && (
                        <span className={cx('font-weight-bold')}>
                            {trans('withdrawed')}
                        </span>
                    )}
                    {ACTION_CONSENT_RESET === item.action && (
                        <span className={cx('font-weight-bold')}>
                            {trans('reset')}
                        </span>
                    )}
                </div>
            </div>
            {ACTION_CONSENT_RESET !== item.action && (
                <ul className={cx('content')}>
                    <li className={cx('contentItem')}>
                        <strong className={cx('listTitle')}>
                            {trans('device')}:
                        </strong>
                        {deviceInfo}
                    </li>
                    <li className={cx('contentItem')}>
                        <span className={cx('listTitle')}>{trans('ip')}:</span>
                        {item.visitor_ip}
                    </li>
                    <li className={cx('contentItem')}>
                        <span className={cx('listTitle')}>{trans('url')}:</span>
                        {item.referer}
                    </li>
                    <li className={cx('contentItem')}>
                        <span className={cx('listTitle')}>
                            {trans('location')}:
                        </span>
                        {country ? (
                            <>
                                {country.name}
                                {/* show region for US if available */}
                                {country.iso === 'US' &&
                                    region &&
                                    ` / ${region.name}`}
                            </>
                        ) : (
                            '---'
                        )}
                    </li>
                    <li className={cx('contentItem')}>
                        {trans('granular_enabled')}
                        <Icon
                            icon={
                                usesGranular
                                    ? 'fa/light/check'
                                    : 'fa/light/times'
                            }
                            className={cx('ml-2', {
                                'text-success': usesGranular,
                                'text-danger': !usesGranular,
                            })}
                        />
                    </li>
                    {ACTION_WITHDRAW !== item.action && (
                        <li className={cx('contentItem')}>
                            {usesGranular
                                ? Object.entries(item.preferences).map(
                                      ([key, accepted]) => {
                                          const meta =
                                              item.granular_metadata[key] || {};

                                          return (
                                              <AcceptedBadge
                                                  key={key}
                                                  accepted={accepted}
                                                  label={meta.name || key}
                                              />
                                          );
                                      }
                                  )
                                : categories.map((cat) => {
                                      const accepted =
                                          cat === 'necessary' ||
                                          !!item.preferences[cat.value];
                                      return (
                                          <AcceptedBadge
                                              key={cat}
                                              accepted={accepted}
                                              label={t(
                                                  `common:categories.${cat.value}`
                                              )}
                                          />
                                      );
                                  })}
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

AuditTrailItem.propTypes = {
    item: PropTypes.object,
};

export default AuditTrailItem;
