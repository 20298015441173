import { Col, Row } from 'reactstrap';
import React, { useEffect } from 'react';

import Button from 'components/Button';
import ChangeSettingsForm from 'views/common/account/ChangeSettingsForm';
import api from 'helpers/api';
import classNames from 'helpers/classNames';
import styles from './PersonalDataTab.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const PersonalDataTab = () => {
    const { t } = useTranslation();
    const { user } = useAppState();
    const [del, delRes, delErr, delMeta] = useExecuteRequest();
    const notify = useNotifications();
    const apiNamespace = useApiNamespace();
    const canDeleteHimself = user.uuid === user.client.admin_user_uuid;
    const handleDelete = () => {
        const monit = t('common:profile.are_you_sure_delete_account');
        if (!confirm(monit)) {
            return;
        }

        del(api.del(`${apiNamespace}/clients/${user.client.uuid}`));
    };

    useEffect(() => {
        if (delMeta.isSuccess) {
            notify.success('Bye');
            window.location.reload();
        }
        if (delMeta.isError) {
            notify.error(delErr.message);
        }
    }, [delMeta.isSuccess, delMeta.isError]);

    if (delMeta.isSuccess) {
        return null;
    }

    return (
        <>
            <ChangeSettingsForm />
            {canDeleteHimself && (
                <Row>
                    <Col xs="12" className={cx('mt-5')}>
                        <h2>{t('common:profile.delete_account_title')}</h2>
                    </Col>
                    <Col xs="12" lg="6">
                        <p>
                            {t(
                                `common:profile.delete_account_intro${
                                    user.client.is_reseller ? '_reseller' : ''
                                }`
                            )}
                        </p>
                    </Col>
                    <Col xs="12">
                        <Button
                            icon="md/ic_delete_forever"
                            disabled={delMeta.isLoading}
                            loading={delMeta.isLoading}
                            color="danger"
                            onClick={() => handleDelete()}
                        >
                            {t('common:buttons.delete')}
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};

PersonalDataTab.propTypes = {};

PersonalDataTab.defaultProps = {};

export default PersonalDataTab;
