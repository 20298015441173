import React, { useCallback, useMemo, useRef } from 'react';

import NotificationsContainer from './components/NotificationsContainer';
import PropTypes from 'prop-types';
import _upperFirst from 'lodash/upperFirst';
import notificationsContext from 'context/notifications';

const NotificationsProvider = (props) => {
    const container = useRef(null);
    const call = useCallback((type, ...args) => {
        const func = `add${_upperFirst(type)}Notification`;
        if (
            !container.current ||
            typeof container.current[func] !== 'function'
        ) {
            return;
        }

        container.current[func](...args);
    }, []);
    const success = useCallback((...a) => call('success', ...a), []);
    const info = useCallback((...a) => call('info', ...a), []);
    const error = useCallback((...a) => call('error', ...a), []);
    const warning = useCallback((...a) => call('warning', ...a), []);
    const ctx = useMemo(() => ({ success, info, error, warning }), []);
    return (
        <notificationsContext.Provider value={ctx}>
            {props.children}
            <NotificationsContainer ref={container} />
        </notificationsContext.Provider>
    );
};

NotificationsProvider.propTypes = {
    children: PropTypes.node,
};

export default React.memo(NotificationsProvider);
