import React, { useCallback, useMemo } from 'react';
import usePlans, { clearCache as clearPlansCache } from 'hooks/usePlans';

import Form from 'components/Form';
import Modal from 'components/Modal';
import PlanChoice from 'components/PlanChoice';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import Tabs from 'components/Tabs';
import classNames from 'helpers/classNames';
import dayjs from 'dayjs';
import styles from './ChoosePlanModal.scss';
import useAppState from 'hooks/useAppState';
import useTranslate from 'hooks/useTranslate';

const TABS = [
    {
        label: 'billing_period.monthly',
        key: 'monthly',
        component: () => null,
    },
    {
        label: 'billing_period.yearly',
        key: 'yearly',
        component: () => null,
    },
];
const selectPlansForTab = (plan, tab) => {
    return plan.billing_period === tab.key;
};
const applyResellerDiscount = (plan) => ({
    ...plan,
    price: (plan.price * 0.7).toFixed(2),
});

const cx = classNames(styles);
const ChoosePlanModal = ({
    title,
    trialEndsAt,
    preselectedPlan,
    isModalOpen,
    clientUuid,
    siteUuid,
    modalDisabled,
    isLoading,
    onClose,
    onPlanChosen,
}) => {
    const [trans] = useTranslate('components:choose_plan');
    const { user } = useAppState();
    const [plans, plansMeta] = usePlans({
        client_uuid: Array.isArray(clientUuid) ? clientUuid[0] : clientUuid,
        site_uuid: siteUuid,
    });

    const billingPeriods = useMemo(() => {
        return plans.data
            .map((p) => p.billing_period)
            .filter((p, index, array) => array.indexOf(p) === index);
    }, [plansMeta.isSuccess]);

    const handleSubmit = useCallback((fieldName, newPlan) => {
        if (isLoading) {
            return;
        }
        const plan = plans.data.find((p) => p.uuid === newPlan);
        onPlanChosen(plan);
        clearPlansCache();
    });

    const trialEndDate =
        trialEndsAt && trialEndsAt > dayjs().unix()
            ? dayjs.unix(trialEndsAt).format('DD-MM-YYYY')
            : '';

    const planToOption = (plan) => ({
        // eslint-disable-next-line
        label: (labelProps) => (
            <PlanChoice
                {...labelProps}
                trialEndDate={trialEndDate}
                plan={user.is_reseller ? applyResellerDiscount(plan) : plan}
            />
        ),
        value: plan.uuid,
    });

    let modalTitle = title || '';
    // add info about choosing yearly only if there is a choice
    if (billingPeriods.length > 1) {
        modalTitle += ' | ';
        modalTitle += user.is_reseller
            ? trans('billing_period.intro_reseller')
            : trans('billing_period.intro');
    }

    const modalProps = {
        isOpen: isModalOpen,
        onClose,
        disabled: modalDisabled,
        title: modalTitle,
        size: 'lg',
    };

    if (plansMeta.isLoading || !plansMeta.isSuccess || isLoading) {
        return (
            <Modal {...modalProps}>
                <Modal.Body>
                    <Spinner />
                </Modal.Body>
            </Modal>
        );
    }
    return (
        <Modal {...modalProps}>
            <Form
                initialValues={{ selected_plan: preselectedPlan || '' }}
                onSubmit={() => {}}
            >
                <Modal.Body>
                    <div className={cx('text-center', 'mb-3', 'px-5')}>
                        <h6>{trans('title')}</h6>
                        <strong>{trans('subtitle')}</strong>
                    </div>
                    <Tabs
                        alignButtons="center"
                        tabs={TABS
                            // don't show tab if there are no plans for that billing period available
                            .filter((tab) => {
                                return billingPeriods.includes(tab.key);
                            })
                            .map((tab) => {
                                return {
                                    ...tab,
                                    label: trans(tab.label),
                                    component: () => {
                                        const tabPlans = plans.data
                                            .filter((p) =>
                                                selectPlansForTab(p, tab)
                                            )
                                            .map(planToOption);
                                        return (
                                            <>
                                                <h3
                                                    className={cx(
                                                        'h5',
                                                        'mb-2',
                                                        'mt-4',
                                                        'text-center'
                                                    )}
                                                >
                                                    {trans(tab.label)}
                                                </h3>
                                                <Form.Field
                                                    className={cx(
                                                        'plan-choice'
                                                    )}
                                                    type="Radio"
                                                    name="selected_plan"
                                                    onChange={handleSubmit}
                                                    options={tabPlans}
                                                />
                                            </>
                                        );
                                    },
                                };
                            })}
                    />
                </Modal.Body>
            </Form>
        </Modal>
    );
};

ChoosePlanModal.propTypes = {
    title: PropTypes.string,
    isModalOpen: PropTypes.bool,
    trialEndsAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool,
    modalDisabled: PropTypes.bool,
    siteUuid: PropTypes.string,
    clientUuid: PropTypes.string,
    onClose: PropTypes.func,
    preselectedPlan: PropTypes.string,
    onPlanChosen: PropTypes.func,
};

ChoosePlanModal.defaultProps = {
    isModalOpen: false,
    isLoading: false,
    modalDisabled: false,
    preselectedPlan: null,
    trialEndsAt: null,
};

export default ChoosePlanModal;
