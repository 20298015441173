import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children, target }) =>
    ReactDOM.createPortal(children, target);

Portal.propTypes = {
    children: PropTypes.node,
    target: PropTypes.object,
};

Portal.defaultProps = {
    children: null,
    target: document.body,
};

export default Portal;
