import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Link from 'components/Link';
import Spinner from 'components/Spinner';
import Tabs from 'components/Tabs';
import UpdateCardDetailsTab from '../UpdateCardDetailsTab';
import UpdateSepaDetailsTab from '../UpdateSepaDetailsTab';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'helpers/classNames';
import styles from './UpdatePaymentDetailsTab.scss';
import useAppState from 'hooks/useAppState';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const getTabs = (t) => [
    {
        label: t('common:profile.billing.tabs.card_details'),
        component: UpdateCardDetailsTab,
    },
    {
        label: t('common:profile.billing.tabs.sepa_details'),
        component: UpdateSepaDetailsTab,
    },
];

const UpdatePaymentDetailsTab = () => {
    const { t } = useTranslation();
    const { user } = useAppState();
    const [cardData, fetchErr, fetchState, reload] = useRequest(
        API.get(`/client/clients/${user.client.uuid}/get-payment-data`)
    );
    if (fetchState.isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <Row>
                <Col xs="12">
                    {fetchState.isSuccess && !_isEmpty(cardData) && (
                        <>
                            <h4>
                                {t(
                                    'common:profile.billing.current_card_details'
                                )}
                            </h4>
                            <p>
                                {t('common:profile.billing.last4_digits')}:{' '}
                                {cardData.last4}
                            </p>
                            <p>
                                {t('common:profile.billing.owner')}:{' '}
                                {cardData.cardHolder}
                            </p>
                            {cardData.brand && (
                                <p>
                                    {t('common:profile.billing.brand')}:{' '}
                                    {cardData.brand}
                                </p>
                            )}
                            <Link
                                external
                                to={cardData.portal_url}
                                button
                                className={cx('mb-5')}
                            >
                                {t('common:profile.billing.open_portal')}
                            </Link>
                        </>
                    )}
                </Col>
            </Row>
            <Tabs tabs={getTabs(t)} reload={reload} />
        </>
    );
};

export default UpdatePaymentDetailsTab;
