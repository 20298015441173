import Button from 'components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './FormSubmit.scss';

const cx = classNames(styles);

const FormSubmit = ({ children, ...props }) => {
    return (
        <Button {...props} type="submit">
            {children}
        </Button>
    );
};

FormSubmit.propTypes = {
    children: PropTypes.node,
};

FormSubmit.defaultProps = {};

export default FormSubmit;
