import { CONFIRM_CODE, SHOW_QR, SHOW_RECOVERY_CODES } from './steps';
import React, { useEffect, useMemo, useState } from 'react';

import API from 'helpers/api';
import ConfirmCodeStep from '../ConfirmCodeStep';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import ShowQrStep from '../ShowQrStep';
import ShowRecoveryCodesStep from '../ShowRecoveryCodesStep';
import Steps from 'components/Steps';
import classNames from 'helpers/classNames';
import styles from './SetCheckQrModal.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useModalSteps from './useModalSteps';
import useNotifications from 'hooks/useNotifications';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);

const SetCheckQrModal = ({ isOpen, onClose, qrCode, userUuid }) => {
    const [trans] = useTranslate('common:profile.manage_twofa');
    const [showQr, setShowQr] = useState(null);
    const [showSubmitCode, setShowSubmitCode] = useState(null);
    const [check, resCheck, , checkState] = useExecuteRequest();
    const notify = useNotifications();

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        setShowQr(null);
        setShowSubmitCode(null);
    }, [isOpen]);

    const currentStep = useMemo(() => {
        if (!qrCode) {
            return -1;
        }
        if (!showQr) {
            return SHOW_QR;
        }
        if (showQr && !showSubmitCode) {
            return CONFIRM_CODE;
        }

        if (showQr && showSubmitCode) {
            return SHOW_RECOVERY_CODES;
        }
    }, [showQr, showSubmitCode, qrCode, checkState.isLoading]);

    const steps = useModalSteps();

    const handleCheckQr = (form) => {
        if (!form.code) {
            return;
        }

        check(
            API.post(`/common/users/${userUuid}/twofa/check-token`, {
                code: form.code,
            })
        );
    };

    useEffect(() => {
        if (checkState.isSuccess && resCheck === true) {
            setShowSubmitCode(1);
        }
        if (checkState.isError || (checkState.isSuccess && resCheck !== true)) {
            notify.error(trans('error'));
        }
    }, [checkState.isSuccess, checkState.isError]);

    return (
        <Modal
            size="lg"
            title={trans('setup_modal_title')}
            contentClassName={cx('content')}
            isOpen={isOpen}
            className={cx('confirm-modal')}
            onClose={onClose}
            disabled={checkState.isLoading}
        >
            <Modal.Body className={cx('text-center')}>
                <Steps
                    className={cx('mb-3')}
                    steps={steps}
                    currentStep={currentStep}
                />
                {currentStep === SHOW_QR && (
                    <Row>
                        <ShowQrStep
                            qrCode={qrCode.qr.svg}
                            onSubmit={() => setShowQr(1)}
                        />
                    </Row>
                )}
                {currentStep === CONFIRM_CODE && (
                    <Row className={cx('justify-content-center')}>
                        <ConfirmCodeStep
                            handleCheckQr={handleCheckQr}
                            isChecking={checkState.isLoading}
                        />
                    </Row>
                )}
                {currentStep === SHOW_RECOVERY_CODES && (
                    <Row className={cx('justify-content-center')}>
                        <ShowRecoveryCodesStep
                            codes={qrCode.codes}
                            onSubmit={() => onClose()}
                        />
                    </Row>
                )}
            </Modal.Body>
        </Modal>
    );
};

SetCheckQrModal.propTypes = {
    onClose: PropTypes.func,
    qrCode: PropTypes.object,
    isOpen: PropTypes.bool,
    userUuid: PropTypes.string,
};

export default SetCheckQrModal;
