import React, { useMemo } from 'react';

import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from '../SiteStatsTab.scss';
import useLang from 'hooks/useLang';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const AreaChart = ({ data, isLoading }) => {
    const { t } = useTranslation();
    const { lang } = useLang();

    const chartData = useMemo(
        () => ({
            series: [
                {
                    name: t('common:site_stats_tab.total'),
                    data: data.map((i) => i.total || 0),
                },
                { name: 'Opt-in', data: data.map((i) => i.optins || 0) },
                { name: 'Opt-out', data: data.map((i) => i.optouts || 0) },
                {
                    name: t('common:site_stats_tab.withdrawals'),
                    data: data.map((i) => i.withdrawals || 0),
                },
                {
                    name: t('common:site_stats_tab.resets'),
                    data: data.map((i) => i.resets || 0),
                },
            ],
            options: {
                chart: {
                    height: 350,
                    type: 'area',
                    fontFamily: 'Noto Sans, sans-serif',
                    toolbar: {
                        show: false,
                        offsetY: 0,
                    },
                },
                grid: {
                    padding: {
                        top: 20,
                        left: 60,
                        right: 20,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                colors: [
                    styles['total-color'],
                    styles['optin-color'],
                    styles['optout-color'],
                    styles['withdrawal-color'],
                    styles['reset-color'],
                ],
                stroke: {
                    curve: 'smooth',
                },
                xaxis: {
                    categories: data.map((i) => i.date),
                },
                tooltip: {
                    x: {},
                },
            },
        }),
        [isLoading, lang]
    );

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <Chart
            {...chartData}
            type="area"
            height={320}
            className={cx('custom-shadow')}
        />
    );
};

AreaChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.string,
            total: PropTypes.number,
            optins: PropTypes.number,
            optouts: PropTypes.number,
            withdrawals: PropTypes.number,
        })
    ),
    isLoading: PropTypes.bool,
};

export default AreaChart;
