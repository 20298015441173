/* global Promise */
import _get from 'lodash/get';
import _trimStart from 'lodash/trimStart';
import qs from 'qs';
import superagent from 'superagent';

class API {
    client() {
        const csrfToken = this._getCsrfToken();
        return superagent
            .agent()
            .withCredentials()
            .accept('json')
            .set('X-CSRF-TOKEN', csrfToken)
            .on('error', (e) => this._reportError(e))
            .on('response', (res) => {
                res.json = () => res.body || null;
            });
    }

    get(url, data) {
        return this.client()
            .get(this._getUrl(url))
            .query(qs.stringify(data, { arrayFormat: 'brackets' }));
    }

    post(url, data) {
        return this.client().post(this._getUrl(url)).send(data);
    }

    patch(url, data) {
        return this.client().patch(this._getUrl(url)).send(data);
    }

    put(url, data) {
        return this.client().put(this._getUrl(url)).send(data);
    }

    del(url, data) {
        return this.client().del(this._getUrl(url)).send(data);
    }

    options(url) {
        return this.client().options(this._getUrl(url));
    }

    request(method, url, data = {}) {
        method = method.toLowerCase();
        const client = this.client();
        if (typeof client[method] !== 'function') {
            throw new Error('Invalid request method used.');
        }

        if (typeof data !== 'object') {
            return this[method](url);
        }

        // check if request data includes a file
        const hasFile = Object.values(data).reduce((prev, value) => {
            if (prev) {
                return prev;
            }

            return value instanceof File || value instanceof Blob;
        }, false);

        // no file, return basic request
        if (!hasFile) {
            return this[method](url, data);
        }

        // contains a file, need to send multipart POST request with method spoofing
        const request = client.post(this._getUrl(url));
        request.field('_method', method.toUpperCase());

        for (const key in data) {
            const value = data[key];
            if (Array.isArray(value)) {
                request.field(`${key}[]`, value);
                continue;
            }
            if (typeof value === 'boolean') {
                request.field(key, value ? 1 : 0);
                continue;
            }
            if (value instanceof File || value instanceof Blob) {
                request.attach(key, value);
                continue;
            }

            // laravel will convert empty strings to null
            if (typeof value === 'undefined' || value === null) {
                request.field(key, '');
                continue;
            }

            request.field(key, value);
        }

        return request;
    }

    upload(url, file) {
        if (!(file instanceof File) && !(file instanceof Blob)) {
            return;
        }
        return this.client().post(this._getUrl(url)).attach('file', file);
    }

    parseError(e) {
        if (!e.status) {
            return e;
        }
        const err = new Error(e.message);
        err.status = e.status;
        err.validation = {};

        if (e.response && e.response.body && e.response.body.message) {
            err.message = e.response.body.message;
        }

        if (err.status === 422 || err.status === 429) {
            err.validation = e.response.body.errors;
        }
        return err;
    }

    _getUrl(url) {
        return `/api/${_trimStart(url, '/')}`;
    }

    _reportError(e) {
        return;
    }

    _getCsrfToken() {
        const tokenMeta = document.head.querySelector(
            'meta[name="csrf_token"]'
        );
        return tokenMeta ? tokenMeta.getAttribute('content') : '';
    }
}

export default new API();
