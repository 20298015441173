import Client from 'models/Client';
import _get from 'lodash/get';

class User {
    data = {
        uuid: null,
        full_name: '',
        display_name: '',
        email: '',
        preferred_language: 'en',
        is_admin: false,
        client: new Client(),
        client_uuid: null,
        is_twofa_enabled: false,
        twofa_activated: false,
        permissions: {},
    };
    _client = new Client();

    constructor(data = {}) {
        for (const key in this.data) {
            if (typeof data[key] === 'undefined') {
                continue;
            }

            this.data[key] = data[key];
        }
    }

    get uuid() {
        return this.data.uuid || '';
    }

    get display_name() {
        return this.data.display_name || '';
    }

    get full_name() {
        return this.data.full_name || '';
    }

    get email() {
        return this.data.email || '';
    }

    get client() {
        if (typeof this.data.client === 'object') {
            if (this._client.uuid !== this.data.client.uuid) {
                this._client = new Client(this.data.client);
            }

            return this._client;
        }

        return new Client();
    }

    get client_uuid() {
        return this.data.client_uuid || null;
    }

    get is_admin() {
        return this.data.is_admin === true;
    }

    get sites() {
        const client = this.client;
        if (Array.isArray(client.sites)) {
            return client.sites;
        }

        return [];
    }

    get preferred_language() {
        return this.data.preferred_language || 'en';
    }

    get is_reseller() {
        return this.client.is_reseller || false;
    }

    get is_twofa_enabled() {
        return this.data.is_twofa_enabled;
    }

    get is_twofa_activated() {
        return this.data.twofa_activated;
    }

    can(permission = '') {
        return _get(this.data.permissions || {}, permission) === 1;
    }

    canAny(permissions = []) {
        for (const permission of permissions) {
            if (this.can(permission)) {
                return true;
            }
        }

        return false;
    }
}

export default User;
