import { Alert, Col, Row } from 'reactstrap';
import React, { useMemo } from 'react';

import { FEATURE_WHITELABEL } from 'constants/features';
import Form from 'components/Form';
import PropTypes from 'prop-types';
import ScriptLogo from 'components/ScriptLogo';
import classNames from 'helpers/classNames';
import styles from './WhitelabelBannerSettings.scss';
import useAppState from 'hooks/useAppState';
import useFormState from 'hooks/useFormState';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);
const DATA_CHANGE_WARNING_FIELDS = [
    'whitelabel_banner_logo',
    'whitelabel_banner_link',
    'whitelabel_banner_primary_color',
];

const WhitelabelBannerSettings = () => {
    const [trans] = useTranslate('common:edit_client_view.fields');
    const { enabledFeatures } = useAppState();
    const { data, initialValues } = useFormState();

    const shouldShowDataChangedWarning = useMemo(() => {
        for (const field of DATA_CHANGE_WARNING_FIELDS) {
            if (data[field] !== initialValues[field]) {
                return true;
            }
        }
        return false;
    }, [data, initialValues]);

    const isWhitelabelEnabled = !!enabledFeatures[FEATURE_WHITELABEL];

    return (
        <>
            {/* Whitelabel banner settings - applied on domains with paid plans */}
            <h2 className={cx('mt-3')}>
                {trans('whitelabel.banner.section_title')}
            </h2>
            <h3 className={cx('mb-2')}>
                {trans('whitelabel.banner.branding_section_title')}
            </h3>
            <Form.HelpText>
                {trans('whitelabel.banner.branding_section_help')}
            </Form.HelpText>
            <Row className={cx('logoRow')}>
                <Col md="4" xl="2">
                    <Form.Field
                        className={cx('logoRowField')}
                        label={trans('whitelabel.banner.logo')}
                        renderPreview={ScriptLogo}
                        previewClassName={cx('border', 'rounded', 'mb-md-3')}
                        type="ImagePicker"
                        name="whitelabel_banner_logo"
                    />
                </Col>
                <Col md="6">
                    <Form.Field
                        label={trans('whitelabel.banner.link')}
                        helpText={trans('whitelabel.banner.link_help')}
                        type="url"
                        name="whitelabel_banner_link"
                    />
                </Col>
            </Row>
            {isWhitelabelEnabled && !!data.whitelabel_banner_enabled && (
                <Row>
                    <Col md="6">
                        <Form.Field
                            label={trans('whitelabel.banner.cdn')}
                            type="url"
                            name="whitelabel_banner_cdn"
                        />
                    </Col>
                    {!!false && (
                        <>
                            <Col xs="12">
                                <h3
                                    className={cx(
                                        'mb-2',
                                        'mt-3',
                                        'font-weight-bold'
                                    )}
                                >
                                    {trans(
                                        'whitelabel.banner.styling_section_title'
                                    )}
                                </h3>
                                <Form.HelpText>
                                    {trans(
                                        'whitelabel.banner.styling_section_help'
                                    )}
                                </Form.HelpText>
                            </Col>
                            <Col md="6">
                                <Form.Field
                                    label={trans(
                                        'whitelabel.banner.primary_color'
                                    )}
                                    allowTransparency={false}
                                    type="ColorPicker"
                                    name="whitelabel_banner_primary_color"
                                />
                            </Col>
                        </>
                    )}
                </Row>
            )}
            {!!shouldShowDataChangedWarning && (
                <Alert color="warning">
                    {trans('whitelabel.banner.data_change_warning')}
                </Alert>
            )}
        </>
    );
};
WhitelabelBannerSettings.propTypes = {};

export default WhitelabelBannerSettings;
