import React, { useMemo } from 'react';

import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import styles from '../SiteStatsTab.scss';
import useLang from 'hooks/useLang';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);
const devices = ['smartphone', 'desktop', 'tablet', 'other'];
const data_order = [
    { label: 'site_stats_tab.total', key: 'total' },
    { label: 'categories.performance', key: 'performance' },
    { label: 'categories.functional', key: 'functional' },
    { label: 'categories.advertising', key: 'advertising' },
];

const ColumnChart = ({ data, isLoading }) => {
    const { t } = useTranslation();
    const { lang } = useLang();

    const chart = useMemo(() => {
        return {
            series: devices.map((device) => {
                return {
                    name: t(`common:devices.${device}`),
                    data: data_order.map((item) => {
                        return _get(data, `${device}.${item.key}`);
                    }),
                };
            }),
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%',
                    fontFamily: 'Noto Sans, sans-serif',
                    toolbar: {
                        show: false,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                colors: devices.map((device) => styles[`device-${device}`]),
                xaxis: {
                    categories: data_order.map((i) => t(`common:${i.label}`)),
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    position: 'right',
                    offsetX: 0,
                    offsetY: 50,
                },
            },
        };
    }, [isLoading, lang]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <Chart
            {...chart}
            type="bar"
            height={320}
            className={cx('custom-shadow')}
        />
    );
};

ColumnChart.propTypes = {
    data: PropTypes.shape({}),
    isLoading: PropTypes.bool,
};

export default ColumnChart;
