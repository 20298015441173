import adminRoutes from './admin';
import dashboardRoutes from './dash';
import guestRoutes from './guest';
import publicRoutes from './public';

export default {
    admin: adminRoutes,
    dash: dashboardRoutes,
    guest: guestRoutes,
    public: publicRoutes,
};
