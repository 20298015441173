/* global Promise */
import { useCallback, useEffect, useRef, useState } from 'react';

import API from 'helpers/api';
import useMaybeSetState from 'hooks/useMaybeSetState';

export const STATUS_IDLE = 0;
export const STATUS_LOADING = 1;
export const STATUS_SUCCESS = 2;
export const STATUS_ERROR = -1;

const useExecuteRequest = () => {
    const [state, setState] = useState({
        data: null,
        error: null,
        status: STATUS_IDLE,
    });
    const ongoingRequest = useRef(null);
    const maybeSetState = useMaybeSetState();

    // cancel ongoing request when unmounting
    useEffect(() => {
        return () => {
            if (ongoingRequest.current) {
                ongoingRequest.current.abort();
            }
        };
    }, []);

    const executeRequest = useCallback((request) => {
        return new Promise((resolve, reject) => {
            setState({ status: STATUS_LOADING, error: null, data: null });

            if (ongoingRequest.current) {
                ongoingRequest.current.abort();
            }
            ongoingRequest.current = request;
            ongoingRequest.current //execute request
                .then((res) => res.json()) // parse response json
                .then((res) => {
                    maybeSetState(() => {
                        setState({ data: res, status: STATUS_SUCCESS });
                    });
                    resolve(res);
                })
                .catch((err) => {
                    if (err.code && err.code === 'ABORTED') {
                        return;
                    }

                    // catch errors
                    maybeSetState(() => {
                        setState({
                            data: null,
                            error: API.parseError(err),
                            status: STATUS_ERROR,
                        });
                    });
                    reject(API.parseError(err));
                });
        });
    }, []);

    return [
        executeRequest,
        state.data,
        state.error,
        {
            isLoading: state.status === STATUS_LOADING,
            isSuccess: state.status === STATUS_SUCCESS,
            isError: state.status !== STATUS_LOADING && !!state.error,
        },
    ];
};

export default useExecuteRequest;
