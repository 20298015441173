import Site from 'models/Site';
import SiteBasicSettingsTab from 'partials/sites/SiteBasicSettingsTab';
import SiteConsentsListTab from 'partials/sites/SiteConsentsListTab';
import SiteCookiesTab from 'partials/sites/SiteCookiesTab';
import SiteCustomContentTab from 'partials/sites/SiteCustomContentTab';
import SiteCustomStylingTab from 'partials/sites/SiteCustomStylingTab';
import SiteScanArchiveTab from 'partials/sites/SiteScanArchiveTab';
import SiteStatsTab from 'partials/sites/SiteStatsTab';
import SiteSubscriptionManagementTab from 'partials/sites/SiteSubscriptionManagementTab';
import SiteThirdPartyScriptsTab from 'partials/sites/SiteThirdPartyScriptsTab';
import SiteYourEmbedScriptTab from 'partials/sites/SiteYourEmbedScriptTab';
import User from 'helpers/user';
import { url } from 'core/Router';

export const BASIC_SETTINGS_TAB = 'basic-settings';
export const STYLING_TAB = 'styling';
export const SCRIPTS_TAB = 'scripts';
export const CUSTOM_CONTENT_TAB = 'content';
export const EMBED_TAB = 'embed-script';
export const COOKIES_TAB = 'cookies';
export const STATS_TAB = 'statistics';
export const CONSENTS_TAB = 'consents';
export const SCAN_ARCHIVE_TAB = 'scan-archive';
export const SUBSCRIPTION_MANAGEMENT_TAB = 'subscription-management';

const getTabsArr = (user, site) => {
    const urlNS = user.is_admin ? 'admin' : 'dash';
    const path = url(`${urlNS}.sites.edit`, { site });
    return [
        {
            url: `${path}/${BASIC_SETTINGS_TAB}`,
            label: 'common:edit_site_view.tabs.basic_settings',
            icon: 'md/ic_settings',
            key: BASIC_SETTINGS_TAB,
            component: SiteBasicSettingsTab,
        },
        {
            url: `${path}/${STYLING_TAB}`,
            label: 'common:edit_site_view.tabs.styling',
            icon: 'md/ic_brush',
            key: STYLING_TAB,
            component: SiteCustomStylingTab,
        },
        {
            url: `${path}/${SCRIPTS_TAB}`,
            label: 'common:edit_site_view.tabs.scripts',
            icon: 'fa/light/code',
            key: SCRIPTS_TAB,
            component: SiteThirdPartyScriptsTab,
        },
        {
            url: `${path}/${CUSTOM_CONTENT_TAB}`,
            label: 'common:edit_site_view.tabs.content',
            icon: 'md/ic_translate',
            key: CUSTOM_CONTENT_TAB,
            component: SiteCustomContentTab,
        },
        {
            url: `${path}/${EMBED_TAB}`,
            label: 'common:edit_site_view.tabs.embed_script',
            icon: 'md/ic_add_box',
            key: EMBED_TAB,
            component: SiteYourEmbedScriptTab,
        },
        {
            url: `${path}/${COOKIES_TAB}`,
            label: 'common:edit_site_view.tabs.cookies',
            icon: 'fa/solid/cookie',
            key: COOKIES_TAB,
            component: SiteCookiesTab,
        },
        {
            url: `${path}/${SCAN_ARCHIVE_TAB}`,
            label: 'common:edit_site_view.tabs.scan_archive',
            icon: 'fa/regular/folderOpen',
            key: SCAN_ARCHIVE_TAB,
            component: SiteScanArchiveTab,
        },
        {
            url: `${path}/${STATS_TAB}`,
            label: 'common:edit_site_view.tabs.statistics',
            icon: 'subway/step',
            key: STATS_TAB,
            component: SiteStatsTab,
        },
        {
            url: `${path}/${CONSENTS_TAB}`,
            label: 'common:edit_site_view.tabs.consents',
            icon: 'md/ic_history',
            key: CONSENTS_TAB,
            component: SiteConsentsListTab,
        },
        user.is_admin && {
            url: `${path}/${SUBSCRIPTION_MANAGEMENT_TAB}`,
            label: 'common:edit_site_view.tabs.subscription_management',
            icon: 'md/ic_autorenew',
            key: SUBSCRIPTION_MANAGEMENT_TAB,
            component: SiteSubscriptionManagementTab,
        },
    ].filter((t) => !!t);
};

const getSiteTabs = (site, user, t) => {
    if (!site instanceof Site) {
        return [];
    }

    if (!user instanceof User) {
        return [];
    }

    const enabledSiteTabs = {
        [BASIC_SETTINGS_TAB]: true,
        [STYLING_TAB]: true,
        [SCRIPTS_TAB]: true,
        [CUSTOM_CONTENT_TAB]: true,
        [EMBED_TAB]: !!site.base_url, //shows only when site has URL as it throws error and white screen when it doesn't
        [COOKIES_TAB]: site.is_cookie_scan_completed && !!site.base_url, // show cookies tab when scan is completed and site has a url
        [SCAN_ARCHIVE_TAB]: true,
        [STATS_TAB]: true,
        [CONSENTS_TAB]: true,
        [SUBSCRIPTION_MANAGEMENT_TAB]: true,
    };

    return getTabsArr(user, site.uuid).map((tab) => {
        let label = t(tab.label);

        if (
            tab.key === 'cookies' &&
            !site.is_cookie_scan_completed &&
            !!site.base_url
        ) {
            const scan = t('common:edit_site_view.tabs.cookie_scan_scheduled');
            label = `${label} ${scan}`;
        }
        return {
            ...tab,
            label,
            disabled: !enabledSiteTabs[tab.key],
        };
    });
};

export default getSiteTabs;
