import ClientApiAccessTab from 'partials/clients/ClientApiAccessTab';
import ClientBasicSettingsTab from 'partials/clients/ClientBasicSettingsTab';
import ClientPolicy from 'policies/ClientPolicy';
import ClientWhitelabelTab from 'partials/clients/ClientWhitelabelTab';
import { FEATURE_CLIENT_API } from 'constants/features';
import PasswordTab from './PasswordTab';
import PersonalDataTab from './PersonalDataTab';
import _get from 'lodash/get';
import { url } from 'core/Router';

export const PERSONAL_DATA_TAB = 'personal-data';
export const SECURITY_TAB = 'security';
export const MY_CLIENT_TAB = 'my-organization';
export const WHITELABEL_TAB = 'whitelabel';
export const API_ACCESS_TAB = 'api-access';

export const getTabs = (user, translate, enabledFeatures) => {
    const urlNS = user.is_admin ? 'admin' : 'dash';
    const canManageClient = ClientPolicy.authorize('update', user, user.client);
    const isClientApiFeatureEnabled = !!enabledFeatures[FEATURE_CLIENT_API];
    const getTabUrl = (tab) => {
        return url(`${urlNS}.profile.tab`, { tab });
    };

    return [
        {
            url: getTabUrl(PERSONAL_DATA_TAB),
            label: translate('common:profile.tabs.personal_data'),
            icon: 'md/ic_settings',
            key: PERSONAL_DATA_TAB,
            component: PersonalDataTab,
        },
        {
            url: getTabUrl(SECURITY_TAB),
            label: translate('common:profile.tabs.security'),
            icon: 'md/ic_lock',
            key: SECURITY_TAB,
            component: PasswordTab,
        },
        canManageClient && {
            url: getTabUrl(MY_CLIENT_TAB),
            label: translate('common:profile.tabs.my_client'),
            icon: 'md/ic_business_center',
            key: MY_CLIENT_TAB,
            component: ClientBasicSettingsTab,
        },
        canManageClient && {
            url: getTabUrl(WHITELABEL_TAB),
            label: translate('common:edit_client_view.tabs.whitelabel'),
            icon: 'fa/regular/tag',
            key: WHITELABEL_TAB,
            component: ClientWhitelabelTab,
        },
        isClientApiFeatureEnabled &&
            canManageClient && {
                url: getTabUrl(API_ACCESS_TAB),
                label: translate('common:edit_client_view.tabs.api_access'),
                icon: 'fa/regular/wrench',
                key: API_ACCESS_TAB,
                component: ClientApiAccessTab,
            },
    ].filter((i) => !!i);
};
