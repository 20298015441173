import { useEffect, useMemo } from 'react';

import useApiNamespace from 'hooks/useApiNamespace';
import useListing from 'hooks/useListing';

let cache = {};

export const clearCache = () => {
    cache = {};
};

const usePlans = (params = {}, execute = true) => {
    const namespace = useApiNamespace();
    const cachedRes = useMemo(() => {
        const key = JSON.stringify(params);

        return cache[key] || null;
    }, [JSON.stringify(params)]);

    const [res, fetchMeta, ...other] = useListing(
        `${namespace}/subscription-plans`,
        params,
        execute && !cachedRes
    );

    useEffect(() => {
        if (fetchMeta.isSuccess) {
            const cacheKey = JSON.stringify(params);
            cache[cacheKey] = res;
        }
    }, [fetchMeta.isSuccess]);

    const returnRes = cachedRes || res;

    return [
        {
            ...returnRes,
            data: returnRes.data.sort((a, b) => a.price - b.price),
        },
        fetchMeta,
        ...other,
    ];
};

export default usePlans;
