import { BILLING_DETAILS_TAB } from 'views/common/account/Billing/tabs';
import { BASIC_SETTINGS_TAB as CLIENT_BASIC_SETTINGS } from 'views/common/clients/EditClientView/tabs';
import { PERSONAL_DATA_TAB as PROFILE_PERSONAL_DATA_TAB } from 'views/common/account/Profile/tabs';
import { BASIC_SETTINGS_TAB as SITE_BASIC_SETTINGS } from 'views/common/sites/EditSiteView/tabs';
import url from '../url';
import view from '../view';

export default [
    {
        path: url('dash'),
        view: view('client/HomeView'),
    },
    // SITES
    {
        path: url('dash.sites'),
        view: view('client/sites/SitesListView'),
    },
    {
        path: url('dash.sites.create'),
        view: view('client/sites/CreateSiteView'),
    },
    {
        path: url('dash.sites.edit', { site: ':site' }),
        redirect: url('dash.sites.edit.slug', {
            site: ':site',
            slug: SITE_BASIC_SETTINGS,
        }),
    },
    {
        path: url('dash.sites.edit.slug', { site: ':site', slug: ':slug' }),
        view: view('common/sites/EditSiteView'),
    },
    {
        path: url('dash.sites.view', { site: ':site' }),
        redirect: url('dash.sites.edit.slug', {
            site: ':site',
            slug: SITE_BASIC_SETTINGS,
        }),
    },
    // SITE GROUPS
    {
        path: url('dash.siteGroups'),
        view: view('common/site-groups/GroupsListView'),
    },
    {
        path: url('dash.siteGroups.create'),
        view: view('common/site-groups/CreateGroupView'),
    },
    {
        path: url('dash.siteGroups.edit', { group: ':group' }),
        view: view('common/site-groups/EditGroupView'),
    },
    {
        path: url('dash.siteGroups.view', { group: ':group' }),
        redirect: url('dash.siteGroups.edit', { group: ':group' }),
    },
    // CUSTOM SCRIPTS
    {
        path: url('dash.customScripts'),
        view: view('client/custom-scripts/CustomScriptsListView'),
    },
    {
        path: url('dash.customScripts.create'),
        view: view('client/custom-scripts/CreateCustomScriptView'),
    },
    {
        path: url('dash.customScripts.edit', {
            script: ':script',
        }),
        view: view('client/custom-scripts/EditCustomScriptView'),
    },
    // CLIENTS
    {
        path: url('dash.clients'),
        view: view('client/clients/ClientsListView'),
    },
    {
        path: url('dash.clients.create'),
        view: view('client/clients/CreateClientView'),
    },
    {
        path: url('dash.clients.edit', { client: ':client' }),
        redirect: url('dash.clients.edit.tab', {
            client: ':client',
            tab: CLIENT_BASIC_SETTINGS,
        }),
    },
    {
        path: url('dash.clients.edit.tab', { client: ':client', tab: ':tab' }),
        view: view('common/clients/EditClientView'),
    },
    // PROFILE SETTINGS
    {
        path: url('dash.profile'),
        redirect: url('dash.profile.tab', {
            tab: PROFILE_PERSONAL_DATA_TAB,
        }),
    },
    {
        path: url('dash.profile.tab', { tab: ':tab' }),
        view: view('common/account/Profile'),
    },
    // BILLING
    {
        path: url('dash.billing'),
        redirect: url('dash.billing.tab', {
            tab: BILLING_DETAILS_TAB,
        }),
    },
    {
        path: url('dash.billing.tab', { tab: ':tab' }),
        view: view('common/account/Billing'),
    },
    // USERS
    {
        path: url('dash.users'),
        view: view('common/users/UsersListView'),
    },
    {
        path: url('dash.users.create'),
        view: view('client/users/CreateUserView'),
    },
    {
        path: url('dash.users.edit', { user: ':user' }),
        view: view('client/users/EditUserView'),
    },
];
