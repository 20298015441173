import '@reach/tooltip/styles.css';

import PropTypes from 'prop-types';
import { Tooltip as ReachTooltip } from '@reach/tooltip';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './Tooltip.scss';

const cx = classNames(styles);

// Center the tooltip, but collisions will win
const positionBottom = (triggerRect, tooltipRect) => {
    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const left = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width - 2;
    return {
        left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
        top: triggerRect.bottom + 8 + window.scrollY,
    };
};

// Center the tooltip, but collisions will win
const positionRight = (triggerRect, tooltipRect) => {
    const left = triggerRect.right + 8;
    const maxLeft = window.innerWidth - tooltipRect.width - 2;
    const triggerCenter = triggerRect.top + triggerRect.height / 2;
    const top = triggerCenter - tooltipRect.height / 2;
    const maxTop = window.innerHeight - tooltipRect.height - 2;
    return {
        left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
        top: Math.min(Math.max(2, top), maxTop) + window.scrollY,
    };
};

const positions = {
    bottom: positionBottom,
    right: positionRight,
};

// Component inspired by https://reach.tech/tooltip#demos
const Tooltip = ({
    children,
    label,
    'aria-label': ariaLabel,
    disabled,
    position = 'right',
    color = 'primary',
    ...props
}) => {
    return (
        <ReachTooltip
            {...props}
            label={label}
            aria-label={ariaLabel}
            className={cx('tooltip-popup', color)}
            position={positions[position]}
        >
            <span>{children}</span>
        </ReachTooltip>
    );
};

Tooltip.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    'aria-label': PropTypes.string,
    disabled: PropTypes.bool,
    position: PropTypes.oneOf(['bottom', 'right']),
    color: PropTypes.oneOf(['primary', 'white']),
};

Tooltip.defaultProps = {};

export default Tooltip;
