import { ALL_STEPS, CONFIRM_CODE, SHOW_QR, SHOW_RECOVERY_CODES } from './steps';

import useTranslate from 'hooks/useTranslate';

const STEP_LABELS = {
    [SHOW_QR]: 'show_qr_code_card',
    [CONFIRM_CODE]: 'confirm_code_card',
    [SHOW_RECOVERY_CODES]: 'show_recovery_codes_card',
};

const useModalSteps = () => {
    const [trans] = useTranslate('common:profile.manage_twofa');
    return ALL_STEPS.map((step) => {
        return {
            value: step,
            label: trans(STEP_LABELS[step] || ''),
        };
    });
};

export default useModalSteps;
