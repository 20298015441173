import React, { useMemo } from 'react';

import API from 'helpers/api';
import Button from 'components/Button';
import ChoosePlanModal from 'components/ChoosePlanModal';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import getPlanLabel from 'helpers/getPlanLabel';
import styles from './ChoosePlanButton.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import { useCallback } from 'react';
import { useEffect } from 'react';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import usePlan from 'hooks/usePlan';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const ChoosePlanButton = ({
    disabled,
    label,
    onChosen,
    initialPlanUuid,
    siteUuid,
    clientUuid,
    trialEndsAt,
    ...props
}) => {
    const { t } = useTranslation();
    const notify = useNotifications();
    const [request, res, error, submitState] = useExecuteRequest();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlanUuid, setSelectedPlanUuid] = useState(
        initialPlanUuid || null
    );
    const [selectedPlan, selectedPlanMeta] = usePlan(selectedPlanUuid);
    const [tempPlan, setTempPlan] = useState(null);

    const handlePlanChosen = useCallback((plan) => {
        const url = `/common/sites/${siteUuid}/change-plan`;
        setTempPlan(plan);
        request(API.post(url, { plan_uuid: plan.uuid }));
        setIsModalOpen(false);
    });

    // show notifications when submit state changes
    useEffect(() => {
        if (submitState.isSuccess) {
            setSelectedPlanUuid(tempPlan ? tempPlan.uuid : null);
            notify.success(t('common:common.saved'));

            if (typeof onChosen === 'function') {
                onChosen(tempPlan);
            }
        }
        if (submitState.isError) {
            setTempPlan(null);
            notify.error(error.message);
        }
    }, [submitState.isSuccess, submitState.isError]);

    const renderedLabel = useMemo(() => {
        if (label) {
            return label;
        }

        return getPlanLabel(selectedPlan, t);
    }, [label, selectedPlan]);

    return (
        <>
            <Button
                type="button"
                disabled={
                    disabled ||
                    (!!selectedPlanUuid && !selectedPlanMeta.isSuccess) ||
                    submitState.isLoading
                }
                loading={selectedPlanMeta.isLoading || submitState.isLoading}
                onClick={() => setIsModalOpen(true)}
            >
                {renderedLabel}
            </Button>
            {isModalOpen && (
                <ChoosePlanModal
                    {...props}
                    title={t('components:choose_site_plan_field.title')}
                    onClose={() => setIsModalOpen(false)}
                    isModalOpen
                    onPlanChosen={handlePlanChosen}
                    preselectedPlan={selectedPlan ? selectedPlan.uuid : null}
                    clientUuid={clientUuid}
                    siteUuid={siteUuid}
                    enableTrial
                    trialEndsAt={trialEndsAt}
                />
            )}
        </>
    );
};

ChoosePlanButton.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.node,
    onChosen: PropTypes.func,
    initialPlanUuid: PropTypes.string,
    siteUuid: PropTypes.string,
    clientUuid: PropTypes.string,
    trialEndsAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChoosePlanButton.defaultProps = {};

export default ChoosePlanButton;
