import Model from 'models/Model';
import _get from 'lodash/get';

class Client extends Model {
    static fields = {
        uuid: { type: 'string', default: '' },
        name: { type: 'string', default: '' },
        email: { type: 'string', default: '' },
        vat_id: { type: 'string', default: '' },
        street: { type: 'string', default: '' },
        street_number: { type: 'string', default: '' },
        apartment: { type: 'string', default: '' },
        postcode: { type: 'string', default: '' },
        city: { type: 'string', default: '' },
        country_code: { type: 'string', default: '' },
        sites: { type: 'array', default: [] },
        site_groups: { type: 'array', default: [] },
        subscriptions: { type: 'array', default: [] },
        users: { type: 'array', default: [] },
        admin_user_uuid: { type: 'string', default: '' },
        owner_uuid: { type: 'string', default: '' },
        manager_uuid: { type: 'string', default: '' },
        manager: { type: 'object', default: null },
        managing_user: { type: 'object', default: {} },
        trial_ends_at: { type: 'number', default: null },
        is_reseller: { type: 'boolean', default: false },
        allow_wildcard_urls: { type: 'boolean', default: false },
        is_self_managed: { type: 'boolean', default: false },
        whitelabel_admin_enabled: { type: 'boolean', default: false },
        whitelabel_banner_enabled: { type: 'boolean', default: false },
        whitelabel_login_enabled: { type: 'boolean', default: false },
        whitelabel_register_enabled: { type: 'boolean', default: false },
        whitelabel_blog_enabled: { type: 'boolean', default: false },
        has_filled_payment_details: { type: 'boolean', default: false },
        whitelabel_logo: { type: 'string', default: null },
        whitelabel_banner_logo: { type: 'string', default: null },
        whitelabel_logo_small: { type: 'string', default: null },
        whitelabel_logo_auth: { type: 'string', default: null },
        whitelabel_logo_favicon: { type: 'string', default: null },
        whitelabel_banner_link: { type: 'string', default: null },
        whitelabel_banner_cdn: { type: 'string', default: null },
        whitelabel_admin_langs: { type: 'array', default: [] },
        whitelabel_domain: { type: 'string', default: null },
        whitelabel_admin_assets_url: { type: 'string', default: null },
        whitelabel_banner_primary_color: { type: 'string', default: null },
        whitelabel_admin_primary_color: { type: 'string', default: null },
        whitelabel_support_url: { type: 'string', default: null },
        whitelabel_terms_url: { type: 'string', default: null },
        whitelabel_admin_banner_key: { type: 'string', default: null },
        api_key: { type: 'string', default: null },
        vat_id_validation_status: { type: 'number', default: 0 },
        dev_urls_number: { type: 'number', default: 1 },
        allowed_plans: { type: 'array', default: [] },
        api_customer: { type: 'object', default: {} },
        client_type: { type: 'string', default: '' },
    };
}

export default Client;
