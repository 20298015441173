const preloader = document.getElementById('main-preloader');

export const hidePreloader = () => {
    if (!preloader) {
        return;
    }

    preloader.classList.remove('show');
};

export const showPreloader = () => {
    if (!preloader) {
        return;
    }

    preloader.classList.add('show');
};
