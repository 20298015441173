import { CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import INPUTS from 'components/Form/inputs';
import PropTypes from 'prop-types';
import React from 'react';
import _noop from 'lodash/noop';
import _throttle from 'lodash/throttle';
import classNames from 'helpers/classNames';

const cx = classNames();

class FormField extends React.PureComponent {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.any,
        type: PropTypes.string,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        group: PropTypes.object,
        errors: PropTypes.array,
        className: PropTypes.string,
        label: PropTypes.node,
        helpText: PropTypes.node,
        renderInput: PropTypes.func,
        disabledInput: PropTypes.node,
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        id: null,
        type: 'TextInput',
        name: null,
        onChange: _noop,
        disabled: false,
        required: false,
        group: {},
        errors: [],
        className: '',
        label: null,
        helpText: '',
        renderInput: undefined,
        disabledInput: undefined,
        readOnly: false,
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const { disabled, name, onChange } = this.props;
        if (disabled) {
            return;
        }

        const value = this.parseInputValue(e);
        onChange(name, value);
    }

    parseInputValue(e) {
        switch (this.props.type) {
            case 'number':
                return parseFloat(e.target.value);
            case 'Date':
            case 'Select':
            case 'ImagePicker':
            case 'ChoiceGroup':
            case 'LanguageDropdown':
            case 'ChooseSiteSubscriptionPlan':
            case 'QuillTextEditor':
            case 'DropdownInput':
            case 'SingleDropdownInput':
            case 'Repeater':
            case 'SelectClient':
            case 'IconPicker':
            case 'FilePicker':
                return e;
            case 'ColorPicker':
                const css = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`;
                return css;
            case 'Checkbox':
            case 'Toggle':
                return !this.props.value;
            default:
                return e.target.value;
        }
    }

    renderInput(inputProps) {
        const { renderInput, disabledInput } = this.props;
        const { type, labelContent, errorFeedback, ...props } = inputProps;
        if (renderInput) {
            return renderInput(props);
        }

        if (inputProps.disabled && typeof disabledInput !== 'undefined') {
            return <div>{disabledInput}</div>;
        }

        const InputComponent = INPUTS[type] || Input;

        if (type === 'Checkbox') {
            props.label = labelContent;
        }
        return (
            <InputComponent
                {...props}
                type={
                    [Input, CustomInput].includes(InputComponent)
                        ? type
                        : undefined
                } // pass input type to allow textarea for example
            />
        );
    }

    renderErrorFeedback({ isInvalid, errors }) {
        if (!isInvalid) {
            return;
        }
        return (
            <FormFeedback className={cx('d-block')} valid={false}>
                {errors.map((error, i) => (
                    <p key={i}>{error}</p>
                ))}
            </FormFeedback>
        );
    }

    renderLabelContent() {
        const { label, required } = this.props;
        return (
            <>
                {label}
                {required && (
                    <sup className={cx('text-danger', 'font-weight-bold')}>
                        *
                    </sup>
                )}
            </>
        );
    }

    renderHelpText() {
        const { helpText } = this.props;
        if (!helpText) {
            return null;
        }

        const texts = Array.isArray(helpText) ? helpText : [helpText];

        return texts.map((text, i) => (
            <small
                key={i}
                className={cx('form-text', 'font-italic', 'text-muted', 'mb-2')}
            >
                {text}
            </small>
        ));
    }

    render() {
        const {
            className,
            group,
            id,
            name,
            required,
            disabled,
            errors,
            label,
            helpText,
            type,
            renderInput,
            disabledInput,
            ...props
        } = this.props;
        const isInvalid = !!errors && errors.length > 0;

        const labelContent = this.renderLabelContent();
        const errorFeedback = this.renderErrorFeedback({ isInvalid, errors });
        return (
            <FormGroup className={className} {...group}>
                {type !== 'Checkbox' && !!label && (
                    <Label for={id || name}>{labelContent}</Label>
                )}
                {this.renderHelpText()}
                {this.renderInput({
                    ...props,
                    type,
                    disabled,
                    id,
                    name,
                    labelContent,
                    required,
                    errorFeedback,
                    invalid: isInvalid,
                    onChange: this.handleChange,
                })}
                {errorFeedback}
            </FormGroup>
        );
    }
}

export default FormField;
