import Button from 'components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _noop from 'lodash/noop';

const Link = ({
    to,
    external,
    color,
    button,
    disabled,
    outline,
    icon,
    children,
    ...props
}) => {
    const inside = button ? (
        <Button
            disabled={disabled}
            outline={outline}
            icon={icon}
            color={color}
            onClick={_noop}
            {...props}
        >
            {children}
        </Button>
    ) : (
        children
    );

    if (disabled) {
        return inside;
    }

    if (external) {
        return (
            <a {...props} href={to} target="_blank" rel="noopener noreferrer">
                {inside}
            </a>
        );
    }
    return (
        <RouterLink {...props} to={to}>
            {inside}
        </RouterLink>
    );
};

Link.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.node,
    external: PropTypes.bool,
    outline: PropTypes.bool,
    icon: PropTypes.string,
    button: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.oneOf(Button.colors),
};

export default Link;
