import { BASIC_SETTINGS_TAB as CLIENT_BASIC_SETTINGS } from 'views/common/clients/EditClientView/tabs';
import { PERSONAL_DATA_TAB as PROFILE_PERSONAL_DATA_TAB } from 'views/common/account/Profile/tabs';
import { BASIC_SETTINGS_TAB as SITE_BASIC_SETTINGS } from 'views/common/sites/EditSiteView/tabs';
import url from '../url';
import view from '../view';

export default [
    {
        path: url('admin'),
        view: view('admin/HomeView'),
    },
    // THIRD PARTY SCRIPTS
    {
        path: url('admin.thirdPartyScripts'),
        view: view('admin/third-party-scripts/ThirdPartyScriptsListView'),
    },
    {
        path: url('admin.thirdPartyScripts.create'),
        view: view('admin/third-party-scripts/CreateThirdPartyScriptView'),
    },
    {
        path: url('admin.thirdPartyScripts.edit', {
            script: ':script',
        }),
        view: view('admin/third-party-scripts/EditThirdPartyScriptView'),
    },
    // CLIENTS
    {
        path: url('admin.clients'),
        view: view('admin/clients/ClientsListView'),
    },
    {
        path: url('admin.clients.invite'),
        view: view('admin/clients/InviteResellerView'),
    },
    {
        path: url('admin.clients.create'),
        view: view('admin/clients/CreateClientView'),
    },
    {
        path: url('admin.clients.edit', { client: ':client' }),
        redirect: url('admin.clients.edit.tab', {
            client: ':client',
            tab: CLIENT_BASIC_SETTINGS,
        }),
    },
    {
        path: url('admin.clients.edit.tab', { client: ':client', tab: ':tab' }),
        view: view('common/clients/EditClientView'),
    },
    // SITES
    {
        path: url('admin.sites'),
        view: view('admin/sites/SitesListView'),
    },
    {
        path: url('admin.sites.create'),
        view: view('admin/sites/CreateSiteView'),
    },
    {
        path: url('admin.sites.edit', { site: ':site' }),
        redirect: url('admin.sites.edit.slug', {
            site: ':site',
            slug: SITE_BASIC_SETTINGS,
        }),
    },
    {
        path: url('admin.sites.edit.slug', { site: ':site', slug: ':slug' }),
        view: view('common/sites/EditSiteView'),
    },
    {
        path: url('admin.sites.view', { site: ':site' }),
        redirect: url('admin.sites.edit.slug', {
            site: ':site',
            slug: SITE_BASIC_SETTINGS,
        }),
    },
    // SITE GROUPS
    {
        path: url('admin.siteGroups'),
        view: view('common/site-groups/GroupsListView'),
    },
    {
        path: url('admin.siteGroups.create'),
        view: view('common/site-groups/CreateGroupView'),
    },
    {
        path: url('admin.siteGroups.edit', { group: ':group' }),
        view: view('common/site-groups/EditGroupView'),
    },
    {
        path: url('admin.siteGroups.view', { group: ':group' }),
        redirect: url('admin.siteGroups.edit', { group: ':group' }),
    },
    // COOKIES
    {
        path: url('admin.cookies'),
        view: view('admin/cookies/CookiesListView'),
    },
    // PROFILE SETTINGS
    {
        path: url('admin.profile'),
        redirect: url('admin.profile.tab', {
            tab: PROFILE_PERSONAL_DATA_TAB,
        }),
    },
    {
        path: url('admin.profile.tab', { tab: ':tab' }),
        view: view('common/account/Profile'),
    },
    // USERS
    {
        path: url('admin.users'),
        view: view('common/users/UsersListView'),
    },
    {
        path: url('admin.users.edit', { user: ':user' }),
        view: view('admin/users/EditUserView'),
    },
    {
        path: url('admin.users.create'),
        view: view('admin/users/CreateUserView'),
    },
    {
        path: url('admin.infoBarMessages.create'),
        view: view('admin/info-bar-messages/CreateInfoBarMessageView'),
    },
    {
        path: url('admin.infoBarMessages.edit', { message: ':message' }),
        view: view('admin/info-bar-messages/EditInfoBarMessageView'),
    },
    {
        path: url('admin.infoBarMessages'),
        view: view('admin/info-bar-messages/InfoBarMessageListView'),
    },
    // ROLES AND PERMISSIONS
    {
        path: url('admin.roles'),
        view: view('admin/roles/RolesAndPermissionsView'),
    },
];
