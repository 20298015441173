import Button from 'components/Button/Button';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './ConfirmModal.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const ConfirmModal = ({
    isOpen,
    isConfirming,
    onCancel,
    onConfirm,
    children,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <Modal
            {...props}
            isOpen={isOpen}
            onClose={onCancel}
            disabled={isConfirming}
        >
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button
                    outline
                    disabled={isConfirming}
                    label={t('common:confirm_modal.cancel')}
                    onClick={onCancel}
                />
                <Button
                    loading={isConfirming}
                    disabled={isConfirming}
                    label={t('common:confirm_modal.confirm')}
                    onClick={onConfirm}
                />
            </Modal.Footer>
        </Modal>
    );
};
ConfirmModal.propTypes = {
    isOpen: PropTypes.bool,
    isConfirming: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    children: PropTypes.node,
};

ConfirmModal.defaultProps = {};

export default ConfirmModal;
