import { Icon as BaseIcon } from '@cookiefirst/react-icons-kit';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './Icon.scss';
import useIcon from './useIcon';

// To see all the possible icons, visit this link:
// https://cookiefirst.gitlab.io/react-icons-kit

const cx = classNames(styles);

const Icon = ({ icon, color, className, ...props }) => {
    const iconData = useIcon(icon);

    if (!iconData) {
        return null;
    }
    return (
        <BaseIcon
            {...props}
            className={cx(className, 'icon', { [`text-${color}`]: color })}
            icon={iconData}
        />
    );
};

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
};

Icon.defaultProps = {};

export default Icon;
