const map = {
    sr: 'sr-cyrl',
    zh: 'zh-hans',
    'zh-cn': 'zh-hans',
};

export default (locale) => {
    const code = locale.toLowerCase();

    return map[code] || code;
};
