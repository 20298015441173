import { useCallback, useEffect, useRef } from 'react';

import _noop from 'lodash/noop';

const useMaybeSetState = () => {
    const isMountedRef = useRef(true);
    // set mounted ref to false on unmount
    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    const maybeSetState = useCallback((setState = _noop) => {
        if (isMountedRef.current) {
            setState();
        }
    });

    return maybeSetState;
};

export default useMaybeSetState;
