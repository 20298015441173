import React, { createContext } from 'react';

import _noop from 'lodash/noop';

export const defaultFormContext = {
    handleChange: _noop,
    hasChanged: false,
    data: {},
    initialValues: {},
    error: null,
};

export default createContext(defaultFormContext);
