import { useEffect, useRef } from 'react';

import API from 'helpers/api';
import Portal from 'components/Portal';
import React from 'react';
import classNames from 'helpers/classNames';
import useAppState from 'hooks/useAppState';
import { useTranslation } from 'react-i18next';

const cx = classNames();

const PING_INTERVAL = 1 * 60 * 1000;

const SessionRefresher = () => {
    const interval = useRef(null);
    const { t } = useTranslation();
    const { csrfToken, user, updateAppState, assetsVersion } = useAppState();
    const isLoggedIn = !!user;

    useEffect(() => {
        clearInterval(interval.current);
        interval.current = setInterval(async () => {
            try {
                const res = await API.get('/ping');
                const data = res.json();
                if (data.csrfToken && data.csrfToken !== csrfToken) {
                    updateAppState('csrfToken', data.csrfToken);
                }
                if (isLoggedIn !== data.isLoggedIn) {
                    clearInterval(interval.current);
                    alert(t('core:session_refresher.text'));
                    window.location.reload();
                }

                if (
                    data.assetsVersion &&
                    data.assetsVersion !== assetsVersion
                ) {
                    updateAppState('assetsVersion', data.assetsVersion);
                }
            } catch (e) {
                return;
            }
        }, PING_INTERVAL);
        return () => clearInterval(interval.current);
    }, [csrfToken, updateAppState, isLoggedIn, assetsVersion]);

    return (
        <Portal target={document.head}>
            <meta name="csrf_token" content={csrfToken} />
        </Portal>
    );
};

export default SessionRefresher;
