import { Col, Row } from 'reactstrap';

import Client from 'models/Client';
import ClientInvoicesListing from '../ClientInvoicesListing';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './ClientInvoicesTab.scss';

const cx = classNames(styles);

const ClientInvoicesTab = ({ client = {} }) => {
    return (
        <Row>
            <Col lg="6">
                <ClientInvoicesListing client={client} />
            </Col>
        </Row>
    );
};

ClientInvoicesTab.propTypes = {
    client: PropTypes.instanceOf(Client),
};

ClientInvoicesTab.defaultProps = {};

export default ClientInvoicesTab;
