import _get from 'lodash/get';
import { generatePath } from 'react-router-dom';

const urlMap = {
    auth: {
        login: 'login',
        logout: 'logout',
        register: 'register',
        resetPassword: 'password/reset/:token?',
        sendResetEmail: 'password/email',
    },
    admin: {
        root: 'admin',
        clients: {
            root: 'admin/clients',
            create: 'admin/clients/create',
            edit: {
                root: 'admin/clients/:client',
                tab: 'admin/clients/:client/:tab',
            },
            invite: 'admin/clients/invite-reseller',
        },
        thirdPartyScripts: {
            root: 'admin/third-party-scripts',
            create: 'admin/third-party-scripts/create',
            edit: 'admin/third-party-scripts/:script',
        },
        customScripts: {
            root: 'admin/custom-scripts',
            create: 'admin/custom-scripts/create',
            edit: 'admin/custom-scripts/:script',
        },
        sites: {
            root: 'admin/sites',
            client: 'admin/sites?clients[]=:client',
            create: 'admin/sites/create',
            edit: {
                root: 'admin/sites/:site/edit',
                slug: 'admin/sites/:site/edit/:slug',
            },
            view: 'admin/sites/:site',
            stats: 'admin/sites/:site/stats',
            consents: 'admin/sites/:site/consents',
        },
        siteGroups: {
            root: 'admin/site-groups',
            create: 'admin/site-groups/create',
            view: 'admin/site-groups/:group',
            edit: 'admin/site-groups/:group/edit',
        },
        cookies: {
            root: 'admin/cookies',
        },
        stats: {
            root: 'admin/stats/:site?',
        },
        users: {
            root: 'admin/users',
            client: 'admin/users?clients[]=:client',
            create: 'admin/users/create',
            edit: 'admin/users/:user/edit',
        },
        profile: {
            root: 'admin/profile',
            tab: 'admin/profile/:tab',
            billing: 'admin/billing',
        },
        billing: {
            root: 'admin/billing',
            tab: 'admin/billing/:tab',
        },
        infoBarMessages: {
            root: 'admin/info-bar-messages',
            create: 'admin/info-bar-messages/create',
            edit: 'admin/info-bar-messages/:message/edit',
        },
        roles: {
            root: 'admin/roles',
        },
    },
    dash: {
        root: 'dashboard',
        clients: {
            root: 'dashboard/clients',
            create: 'dashboard/clients/create',
            edit: {
                root: 'dashboard/clients/:client',
                tab: 'dashboard/clients/:client/:tab',
            },
        },
        sites: {
            root: 'dashboard/sites',
            client: 'dashboard/sites?clients[]=:client',
            create: 'dashboard/sites/create',
            edit: {
                root: 'dashboard/sites/:site/edit',
                slug: 'dashboard/sites/:site/edit/:slug',
            },
            view: 'dashboard/sites/:site',
            stats: 'dashboard/sites/:site/stats',
            consents: 'dashboard/sites/:site/consents',
        },
        siteGroups: {
            root: 'dashboard/site-groups',
            create: 'dashboard/site-groups/create',
            view: 'dashboard/site-groups/:group',
            edit: 'dashboard/site-groups/:group/edit',
        },
        customScripts: {
            root: 'dashboard/custom-scripts',
            create: 'dashboard/custom-scripts/create',
            edit: 'dashboard/custom-scripts/:script',
        },
        users: {
            root: 'dashboard/users',
            client: 'dashboard/users?clients[]=:client',
            create: 'dashboard/users/create',
            edit: 'dashboard/users/:user/edit',
        },
        profile: {
            root: 'dashboard/profile',
            tab: 'dashboard/profile/:tab',
            billing: 'dashboard/billing',
        },
        billing: {
            root: 'dashboard/billing',
            tab: 'dashboard/billing/:tab',
        },
        stats: {
            root: 'stats/:site?',
        },
    },
};

const getUrl = (key = '') => {
    let url = _get(urlMap, key);
    if (typeof url === 'object' && url.root) {
        url = url.root;
    }
    return url ? `/${url}` : undefined;
};

const getUrlByKey = (key, params = {}) => generatePath(getUrl(key), params);

export default getUrlByKey;
