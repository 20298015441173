import { useEffect } from 'react';
import useExecuteRequest from 'hooks/useExecuteRequest';

const useRequest = (request, shouldExecute = true) => {
    const [executeRequest, data, error, meta] = useExecuteRequest();
    const execute = () => executeRequest(request);
    // fetch data on mount or when request data changes
    useEffect(() => {
        if (shouldExecute) {
            execute(request);
        }
    }, [
        JSON.stringify({
            method: request.method,
            url: request.url,
            query: request._query,
            headers: request._header,
        }),
        shouldExecute,
    ]);

    return [data, error, meta, execute];
};

export default useRequest;
