import React, { useEffect, useState } from 'react';

import InstanceContext from 'context/instance';
import PropTypes from 'prop-types';

const defaultInstanceSettings = {
    is_whitelabel: false,
    banner_code_url: '',
    banner_static_files_url: '',
    whitelabel_banner_enabled: false,
    logo: '',
    logo_small: '',
    logo_auth: '',
    allow_login: true,
    allow_register: true,
};

const InstanceSettingsProvider = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [instanceSettings, setInstanceSettings] = useState(
        defaultInstanceSettings
    );

    useEffect(() => {
        const config = window.__INSTANCE_SETTINGS__;
        if (!config) {
            return;
        }

        const newInstanceSettings = {};
        for (const key in defaultInstanceSettings) {
            if (typeof config[key] !== 'undefined') {
                newInstanceSettings[key] = config[key];
            }
        }

        setInstanceSettings(newInstanceSettings);
        setIsInitialized(true);
    }, []);

    if (!isInitialized) {
        return null;
    }

    return (
        <InstanceContext.Provider value={instanceSettings}>
            {children}
        </InstanceContext.Provider>
    );
};

InstanceSettingsProvider.propTypes = {
    children: PropTypes.node,
};

InstanceSettingsProvider.defaultProps = {
    children: null,
};

export default InstanceSettingsProvider;
