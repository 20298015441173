import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './InvoiceListItem.scss';

const cx = classNames(styles);

const InvoiceListItem = ({ invoice, ...props }) => {
    return (
        <>
            <tr>
                <td>
                    <Link to={invoice.downloadLink} external>
                        {invoice.number}
                    </Link>
                </td>
                <td>{invoice.date}</td>
                <td>Description</td>
                <td>EUR {invoice.amount}</td>
            </tr>
        </>
    );
};

InvoiceListItem.propTypes = {
    invoice: PropTypes.shape({
        amount: PropTypes.string,
        number: PropTypes.string,
        date: PropTypes.string,
        downloadLink: PropTypes.string,
    }),
};

InvoiceListItem.defaultProps = {
    invoice: {
        amount: '',
        number: '',
        date: '',
        downloadLink: '',
    },
};

export default InvoiceListItem;
