import useAppState from 'hooks/useAppState';
import { useMemo } from 'react';

const usePolicy = (PolicyClass, permission, model) => {
    const { user } = useAppState();
    const result = useMemo(() => {
        return PolicyClass.authorize(permission, user, model);
    }, [PolicyClass, permission, model, user]);

    return result;
};

export default usePolicy;
