export default [
    'all_plus',
    'tailored_solution',
    'script_limit',
    'widget_type_choice',
    'autoscan',
    'lang_limit',
    'hide_outside_eu',
    'widget_style_edit',
    'white_label_banner',
    'consent_statistics',
    'white_label_panel',
    'consent_reports',
    'consent_audit_trail',
];
