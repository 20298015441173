import { useHistory, useLocation } from 'react-router-dom';

import _isEqual from 'lodash/isEqual';
import qs from 'qs';

const useUrlQuery = () => {
    const location = useLocation();
    const history = useHistory();
    const query = qs.parse(location.search.replace('?', '')) || {};
    const updateQuery = (data = {}) => {
        if (typeof data !== 'object' || _isEqual(data, query)) {
            return;
        }

        const newQuery = qs.stringify(data, { arrayFormat: 'brackets' });
        history.push({ search: newQuery });
    };

    return [query, updateQuery];
};

export default useUrlQuery;
