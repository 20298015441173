import { useEffect, useState } from 'react';

const useFlag = (country = null) => {
    const [flagUrl, setFlagUrl] = useState('');

    useEffect(() => {
        if (!country) {
            setFlagUrl('');
            return;
        }
        const lowerCountry = country.toLowerCase();
        // force import with file-loader, not react-svg-loader
        import(`!file-loader!flag-icons/flags/1x1/${lowerCountry}.svg`)
            .then((loaded) => setFlagUrl(loaded.default))
            .catch((e) => {
                console.error(e);
                setFlagUrl('');
            });
    }, [country]);

    return flagUrl;
};

export default useFlag;
