import { createContext } from 'react';

export const defaultAppState = {
    user: null,
    stripeKey: '',
    csrfToken: null,
    availableLanguages: [],
    adminLanguages: [],
    allAdminLanguages: [],
    externalLinks: {
        support: '',
        terms_of_use: '',
    },
    cookieCategories: [],
    infoBarMessage: null,
    isSecondaryMenuVisible: false,
    isSidebarExpanded: true,
    consentExpiration: {
        options: [12],
        default: 12,
    },
    enabledFeatures: {},
    assetsVersion: null,
    initialAssetsVersion: null,
    vatCountries: [],
};

export default createContext(defaultAppState);
