import InvoiceListTab from './components/InvoiceListTab';
import UpdateBillingDetailsTab from './components/UpdateBillingDetailsTab';
import UpdatePaymentDetailsTab from './components/UpdatePaymentDetailsTab';
import { url } from 'core/Router';

export const BILLING_DETAILS_TAB = 'billing-details';
export const PAYMENT_DETAILS_TAB = 'payment-details';
export const INVOICES_TAB = 'invoices';

export const getTabs = (user, translate) => {
    const getTabUrl = (tab) => {
        const urlNS = user.is_admin ? 'admin' : 'dash';
        return url(`${urlNS}.billing.tab`, { tab });
    };

    const tabs = [
        {
            url: getTabUrl(BILLING_DETAILS_TAB),
            label: translate('common:profile.billing.tabs.billing_details'),
            icon: 'fa/regular/addressCard',
            key: BILLING_DETAILS_TAB,
            component: UpdateBillingDetailsTab,
        },
        {
            url: getTabUrl(PAYMENT_DETAILS_TAB),
            label: translate('common:profile.billing.tabs.payment_details'),
            icon: 'fa/regular/creditCard',
            key: PAYMENT_DETAILS_TAB,
            component: UpdatePaymentDetailsTab,
        },
        {
            url: getTabUrl(INVOICES_TAB),
            label: translate('common:profile.billing.tabs.invoices'),
            icon: 'fa/regular/fileInvoice',
            key: INVOICES_TAB,
            component: InvoiceListTab,
        },
    ];

    return tabs;
};
