import { Col, Row } from 'reactstrap';
import { IbanElement, injectStripe } from 'react-stripe-elements';
import React, { useCallback, useEffect, useState } from 'react';

import API from 'helpers/api';
import Button from 'components/Button/Button';
import Form from 'components/Form/Form';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './UpdateSepaForm.scss';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const UpdateSepaForm = ({ stripe, cardHolder, reload, ...props }) => {
    const { user } = useAppState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const notify = useNotifications();
    const [request, res, err, submitState] = useExecuteRequest();
    const { t } = useTranslation();

    const handleSubmit = useCallback(
        (data) => {
            if (isSubmitting) {
                return;
            }

            setIsSubmitting(true);
            stripe
                .createSource({
                    type: 'sepa_debit',
                    currency: 'eur',
                    owner: {
                        name: data.cardHolder,
                        email: user.client.email,
                    },
                    mandate: {
                        notification_method: 'email',
                    },
                })
                .then((response) => {
                    return request(
                        API.put(
                            `/client/clients/${user.client.uuid}/update-sepa`,
                            { source: response.source.id }
                        )
                    );
                })
                .finally(() => setIsSubmitting(false));
        },
        [request]
    );

    useEffect(() => {
        if (submitState.isSuccess) {
            notify.success(t('components:update_sepa_form.saved'));
            reload();
        }
        if (submitState.isError) {
            notify.error(err.message);
        }
    }, [submitState.isSuccess, submitState.isError, notify]);

    return (
        <Form
            isSubmitting={isSubmitting || submitState.isLoading}
            initialValues={{ cardHolder }}
            onSubmit={handleSubmit}
        >
            <Row>
                <Col xs="12" lg="6">
                    <Form.Field
                        name="cardHolder"
                        label={t(
                            'components:update_sepa_form.account_owner_field_label'
                        )}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="12" lg="6">
                    <div className={cx('form-group')}>
                        <label>
                            {t('components:update_sepa_form.iban_field_label')}
                        </label>
                        <IbanElement
                            supportedCountries={['SEPA']}
                            className={cx('form-control', 'StripeElement')}
                            style={{
                                base: {
                                    fontFamily: 'Noto Sans, sans-serif',
                                    fontSize: '1rem',
                                    fontSmoothing: 'antialiased',
                                },
                            }}
                        />
                    </div>
                    <p>{t('components:update_sepa_form.mandate_info')}</p>
                </Col>
            </Row>
            <Button>
                {t('components:update_sepa_form.update_bank_details')}
            </Button>
        </Form>
    );
};

UpdateSepaForm.propTypes = {
    stripe: PropTypes.object,
    cardHolder: PropTypes.string,
    reload: PropTypes.func,
};

UpdateSepaForm.defaultProps = {
    stripe: {},
    cardHolder: '',
    reload: () => null,
};

export default injectStripe(UpdateSepaForm);
