import Button from 'components/Button';
import { ButtonGroup } from 'reactstrap';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './TabsNav.scss';

const cx = classNames(styles);

const TabsNav = ({
    className,
    tabs,
    activeTab,
    color,
    onTabSelected,
    align,
}) => {
    const handleDropdownChange = (value) => {
        const currValue = Array.isArray(value) ? value[0] : value;
        handleTabChange(currValue);
    };

    const handleTabChange = (tabIndex) => {
        const index = tabs[tabIndex] ? tabIndex : 0;
        onTabSelected(index);
    };

    return (
        <div className={cx(className)}>
            <div className={cx('d-md-none')}>
                <Dropdown
                    className={cx('w-100')}
                    toggleClassName={cx('w-100')}
                    onChoose={handleDropdownChange}
                    outline
                    color={color}
                    value={activeTab}
                    options={tabs.map((tab, index) => ({
                        ...tab,
                        value: index,
                    }))}
                    renderLabel={(tab) => (
                        <>
                            {!!tab.icon && (
                                <Icon className={cx('mr-2')} icon={tab.icon} />
                            )}
                            {tab.label}
                        </>
                    )}
                />
            </div>
            <ButtonGroup
                className={cx(
                    'd-none',
                    'd-md-flex',
                    `justify-content-${align}`,
                    'align-items-stretch'
                )}
            >
                {tabs.map((tab, index) => (
                    <Button
                        disabled={!!tab.disabled}
                        key={index}
                        color={color}
                        outline={index !== activeTab}
                        onClick={() => handleTabChange(index)}
                        icon={tab.icon}
                        className={cx('tabBtn', {
                            isCurrent: index === activeTab,
                        })}
                    >
                        {tab.label}
                    </Button>
                ))}
            </ButtonGroup>
        </div>
    );
};

TabsNav.propTypes = {
    className: PropTypes.string,
    align: PropTypes.oneOf(['start', 'center', 'end']),
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            label: PropTypes.node,
            disabled: PropTypes.bool,
        })
    ),
    color: PropTypes.string,
    activeTab: PropTypes.number,
    onTabSelected: PropTypes.func,
};

TabsNav.defaultProps = {
    align: 'center',
    color: undefined,
};

export default TabsNav;
