import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'reactstrap';
import ShowRecoveryCodesStep from '../ShowRecoveryCodesStep';
import classNames from 'helpers/classNames';

const cx = classNames();

const RecoveryCodesModal = ({ isOpen, onClose, qrCode }) => {
    return (
        <Modal
            size="lg"
            contentClassName={cx('content')}
            isOpen={isOpen}
            className={cx('confirm-modal')}
            onClose={onClose}
        >
            <Modal.Body className={cx('text-center')}>
                <Row className={cx('justify-content-center')}>
                    <ShowRecoveryCodesStep
                        codes={qrCode.codes}
                        onSubmit={() => onClose()}
                    />
                </Row>
            </Modal.Body>
        </Modal>
    );
};

RecoveryCodesModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    qrCode: PropTypes.array,
};

export default RecoveryCodesModal;
