import context from 'context/instance';
import { useContext } from 'react';

export default () => {
    const ctx = useContext(context);
    if (!ctx) {
        throw new Error('Context used outside provider');
    }

    return ctx;
};
