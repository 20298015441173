import Button from 'components/Button';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './ShowQrStep.scss';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);

const ShowQrStep = ({ qrCode, onSubmit }) => {
    const [trans] = useTranslate('common:profile.manage_twofa');
    return (
        <Col className={cx('text-center')}>
            <p>{trans('scan_qr')}</p>
            <div>
                <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        qrCode
                    )}`}
                    alt="qr-code"
                />
            </div>
            <Button onClick={onSubmit} style={{ marginTop: 10 }}>
                {trans('continue_label')}
            </Button>
        </Col>
    );
};

ShowQrStep.propTypes = {
    qrCode: PropTypes.string,
    onSubmit: PropTypes.func,
};

export default ShowQrStep;
