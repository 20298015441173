import { Col, Row } from 'reactstrap';
import { Elements, StripeProvider } from 'react-stripe-elements';

import PropTypes from 'prop-types';
import React from 'react';
import UpdateSepaForm from 'components/UpdateSepaForm';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'helpers/classNames';
import styles from './UpdateSepaDetailsTab.scss';
import useAppState from 'hooks/useAppState';
import { useClientPolicy } from 'policies/ClientPolicy';

const cx = classNames(styles);

const UpdateSepaDetailsTab = ({ reload }) => {
    const { stripeKey, user } = useAppState();
    const canUpdate = useClientPolicy('updateBilling', user.client);
    return (
        <>
            <Row>
                <Col xs="12">
                    <StripeProvider stripe={window.Stripe(stripeKey)}>
                        <Elements>
                            <UpdateSepaForm
                                disabled={!canUpdate}
                                cardHolder={''}
                                reload={reload}
                            />
                        </Elements>
                    </StripeProvider>
                </Col>
            </Row>
        </>
    );
};

UpdateSepaDetailsTab.propTypes = {
    reload: PropTypes.func,
};

UpdateSepaDetailsTab.defaultProps = {
    reload: () => null,
};

export default UpdateSepaDetailsTab;
