const isBrowserSupported = () => {
    if (
        !window.CSS ||
        typeof CSS.supports !== 'function' ||
        !CSS.supports('color', 'var(--primary)')
    ) {
        return false;
    }

    return true;
};

export default isBrowserSupported;
