import { useCallback, useEffect, useMemo } from 'react';

import API from 'helpers/api';
import _get from 'lodash/get';
import useExecuteRequest from './useExecuteRequest';
import useNotifications from './useNotifications';

const useListing = (
    endpoint = null,
    params = {},
    execute = true,
    showNotifications = true
) => {
    const [request, res, err, fetchMeta] = useExecuteRequest();
    const notify = useNotifications();

    // call API for data on initial render and when request params change
    useEffect(() => {
        if (execute) {
            // setResponse({ ...response, data: [] });
            request(API.get(endpoint, params));
        }
    }, [JSON.stringify(params), execute]);

    // notify user about any errors
    useEffect(() => {
        if (fetchMeta.isError) {
            console.log(err);
            if (showNotifications) {
                notify.error(err.message);
            }
        }
    }, [fetchMeta.isError, err]);

    const reload = useCallback(() => request(API.get(endpoint, params)));

    const response = useMemo(() => {
        const response = res || {};
        const firstPage =
            response.first_page || _get(response, 'meta.first_page') || 1;
        const lastPage =
            response.last_page || _get(response, 'meta.last_page') || 1;
        return {
            current_page: parseInt(firstPage),
            data: Array.isArray(response.data) ? [...response.data] : [],
            last_page: parseInt(lastPage),
        };
    }, [fetchMeta.isSuccess]);

    return [response, fetchMeta, err, reload];
};

export default useListing;
