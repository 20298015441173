import api from 'helpers/api';
import useApiNamespace from './useApiNamespace';
import { useEffect } from 'react';
import useRequest from './useRequest';

const cache = {};
const usePlan = (planUuid) => {
    const cachedPlan = cache[planUuid];
    const apiNS = useApiNamespace();
    const [plan, error, meta] = useRequest(
        api.get(`/${apiNS}/subscription-plans/${planUuid}`),
        !!planUuid && !cachedPlan
    );

    useEffect(() => {
        if (meta.isSuccess) {
            cache[planUuid] = plan;
        }
    }, [meta.isSuccess]);

    if (cachedPlan) {
        return [
            cachedPlan,
            { isLoading: false, isSuccess: true, isError: false },
            null,
        ];
    }

    return [plan, meta, error];
};

export default usePlan;
