import { Spinner as BsSpinner } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './Spinner.scss';

const cx = classNames(styles);

const Spinner = ({ className, inline, color, size, ...props }) => {
    if (inline) {
        return (
            <BsSpinner
                {...props}
                className={cx(className, 'spinner')}
                variant={color}
                size={size}
            />
        );
    }

    return (
        <div className={cx('text-center')}>
            <BsSpinner
                {...props}
                className={cx(className, 'spinner')}
                color={color}
                size={size}
            />
        </div>
    );
};

Spinner.propTypes = {
    inline: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
};

Spinner.defaultProps = {
    inline: false,
    color: 'primary',
    size: '',
    className: '',
};

export default Spinner;
