import User from 'helpers/user';

class BasePolicy {
    /**
     * Check if user can view the model listing
     * @param {User} user
     * @returns boolean
     */
    static viewAny(user) {
        return false;
    }

    /**
     * Check if user can view the details view of model
     * @param {User} user
     * @param {*} model
     * @returns boolean
     */
    static view(user, model) {
        return false;
    }

    /**
     * Check if user can create new instances of model
     * @param {User} user
     * @returns boolean
     */
    static create(user) {
        return false;
    }

    /**
     * Check if user can update an instance of model
     * @param {User} user
     * @param {*} model
     * @returns boolean
     */
    static update(user, model) {
        return false;
    }

    /**
     * Check if user can delete an instance of model
     * @param {User} user
     * @param {*} model
     * @returns boolean
     */
    static delete(user, model) {
        return false;
    }

    /**
     * Check if user passes a given policy check (the checkFunction)
     * @param {string} checkFunction
     * @param {User|null} user
     * @param  {...any} args
     * @returns boolean
     */
    static authorize(checkFunction, user, ...args) {
        if (!user || !(user instanceof User)) {
            return false;
        }
        if (user.is_admin) {
            return true;
        }

        const callableCheckFunction = this[checkFunction];
        if (typeof callableCheckFunction !== 'function') {
            return false;
        }

        const checkResult = callableCheckFunction(user, ...args);
        if (typeof checkResult === 'boolean') {
            return checkResult;
        }

        return false;
    }

    /**
     * Check if user "owns" a given model, thus can have "own" permission to it
     *
     * @param {User} user
     * @param {*} model
     * @returns {boolean}
     */
    static userOwnsModel(user, model) {
        if (!model || !user) {
            return false;
        }

        // model belongs to user's client
        if (user.client_uuid === model.client_uuid) {
            return true;
        }

        // user without client can't own any models
        if (!user.client) {
            return false;
        }

        // if model isn't attached to any client, no user owns it
        if (!model.client) {
            return false;
        }

        // user is a reseller user and model belongs to a subclient
        if (
            user.is_reseller &&
            model.client.manager_uuid === user.client_uuid
        ) {
            return true;
        }

        return false;
    }
}

export default BasePolicy;
