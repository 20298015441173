import { Col, Row } from 'reactstrap';

import Form from 'components/Form';
import LangFlag from 'components/LangFlag';
import PropTypes from 'prop-types';
import React from 'react';
import ScriptLogo from 'components/ScriptLogo';
import classNames from 'helpers/classNames';
import styles from './WhitelabelAdminAreaSettings.scss';
import useAppState from 'hooks/useAppState';
import useFormState from 'hooks/useFormState';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);

const WhitelabelAdminAreaSettings = () => {
    const [trans] = useTranslate(
        'common:edit_client_view.fields.whitelabel.admin'
    );
    const { data } = useFormState();
    const { allAdminLanguages, availableLanguages } = useAppState();

    if (!data.whitelabel_admin_enabled) {
        return null;
    }
    return (
        <Row>
            <Col xs="12">
                <h2 className={cx('mt-3')}>{trans('section_title')}</h2>
            </Col>
            <Col md="6">
                <Form.Field
                    label={trans('domain')}
                    helpText={trans('domain_help')}
                    type="url"
                    name="whitelabel_domain"
                />
            </Col>
            <Col md="6">
                <Form.Field
                    label={trans('admin_assets_url')}
                    helpText={trans('admin_assets_url_help')}
                    type="url"
                    name="whitelabel_admin_assets_url"
                />
            </Col>

            <Col xs="12" />

            <Col md="6">
                <Form.Field
                    label={trans('terms_url')}
                    helpText={trans('terms_url_help')}
                    type="url"
                    name="whitelabel_terms_url"
                />
            </Col>
            <Col md="6">
                <Form.Field
                    label={trans('support_url')}
                    helpText={trans('support_url_help')}
                    type="url"
                    name="whitelabel_support_url"
                />
            </Col>
            <Col md="6">
                <Form.Field
                    label={trans('banner_key')}
                    helpText={trans('banner_key_help')}
                    type="text"
                    minLength={36}
                    maxLength={36}
                    name="whitelabel_admin_banner_key"
                />
            </Col>

            <Col xs="12" />

            <Col md="6">
                <Form.Field
                    label={trans('primary_color')}
                    helpText={trans('primary_color_help')}
                    allowTransparency={false}
                    type="ColorPicker"
                    name="whitelabel_admin_primary_color"
                />
            </Col>

            <Col xs="12">{/* spacer to always move logos to a new row */}</Col>

            <Col md="4">
                <Form.Field
                    label={trans('logo')}
                    helpText={trans('logo_help')}
                    renderPreview={ScriptLogo}
                    previewClassName={cx('border', 'rounded')}
                    type="ImagePicker"
                    name="whitelabel_logo"
                />
            </Col>
            <Col md="4">
                <Form.Field
                    label={trans('logo_small')}
                    helpText={trans('logo_small_help')}
                    renderPreview={ScriptLogo}
                    previewClassName={cx('border', 'rounded')}
                    type="ImagePicker"
                    name="whitelabel_logo_small"
                />
            </Col>
            <Col xs="12" />
            <Col md="4">
                <Form.Field
                    label={trans('logo_auth')}
                    helpText={trans('logo_auth_help')}
                    renderPreview={ScriptLogo}
                    previewClassName={cx('border', 'rounded')}
                    type="ImagePicker"
                    name="whitelabel_logo_auth"
                />
            </Col>
            <Col md="4">
                <Form.Field
                    label={trans('logo_favicon')}
                    helpText={trans('logo_favicon_help')}
                    renderPreview={ScriptLogo}
                    previewClassName={cx('border', 'rounded')}
                    type="ImagePicker"
                    name="whitelabel_logo_favicon"
                />
            </Col>
            <Col xs="12" />
            <Col md="4">
                <Form.Field
                    label={trans('allowed_langs')}
                    helpText={trans('allowed_langs_help')}
                    type="ChoiceGroup"
                    multiple
                    options={availableLanguages
                        .filter((l) => allAdminLanguages.includes(l.value))
                        .map((lang) => ({
                            value: lang.value,
                            label: (
                                <>
                                    <LangFlag
                                        className={cx('mr-2')}
                                        lang={lang.value}
                                    />
                                    {lang.label}
                                </>
                            ),
                        }))}
                    name="whitelabel_admin_langs"
                />
            </Col>
            <Col md="4">
                <Form.Label>{trans('features')}</Form.Label>
                <Form.Field
                    inlineLabel={trans('login_view')}
                    type="Toggle"
                    name="whitelabel_login_enabled"
                />
                <Form.Field
                    inlineLabel={trans('register_view')}
                    type="Toggle"
                    name="whitelabel_register_enabled"
                />
            </Col>
        </Row>
    );
};

WhitelabelAdminAreaSettings.propTypes = {};

WhitelabelAdminAreaSettings.defaultProps = {};

export default WhitelabelAdminAreaSettings;
