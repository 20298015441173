import Button from 'components/Button';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from 'hooks/useTranslate';

const ShowRecoveryCodesStep = ({ codes, onSubmit }) => {
    const [trans] = useTranslate('common:profile.manage_twofa');
    return (
        <Col xs={6}>
            <p>{trans('recovery_codes')}</p>
            <ol>
                {codes.map((code) => {
                    return <li key={code}>{code}</li>;
                })}
            </ol>
            <Button onClick={onSubmit} style={{ marginTop: 10 }}>
                {trans('submit_label')}
            </Button>
        </Col>
    );
};

ShowRecoveryCodesStep.propTypes = {
    codes: PropTypes.array,
    onSubmit: PropTypes.func,
};

export default ShowRecoveryCodesStep;
