import { useEffect, useState } from 'react';

import fetchIcon from 'helpers/fetchIcon';
import useMaybeSetState from 'hooks/useMaybeSetState';

const useIcon = (icon) => {
    const [iconData, setIconData] = useState(null);
    const maybeSetState = useMaybeSetState();
    useEffect(() => {
        fetchIcon(icon)
            .then((data) => maybeSetState(() => setIconData(data)))
            .catch((e) => console.error(e));
    }, [icon]);

    return iconData;
};

export default useIcon;
