import React, { createContext } from 'react';

import _noop from 'lodash/noop';

export const defaultNotificationsContext = {
    success: _noop,
    info: _noop,
    error: _noop,
    warning: _noop,
};

export default createContext(defaultNotificationsContext);
