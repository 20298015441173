import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ErrorFeedback from './components/ErrorFeedback';
import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';

class DevErrorBoundary extends React.PureComponent {
    state = {
        error: null,
    };
    static propTypes = {
        children: PropTypes.node,
        FallbackComponent: PropTypes.node,
        onError: PropTypes.func,
    };

    componentDidCatch(e) {
        console.log(e);
        this.setState({ error: e });
        this.props.onError(e);
    }

    render() {
        if (this.state.error) {
            return this.props.FallbackComponent;
        }

        return this.props.children;
    }
}

let BSErrorBoundary = DevErrorBoundary;

if (process.env.BUGSNAG_API_KEY_FRONTEND) {
    Bugsnag.start({
        collectUserIp: false,
        apiKey: process.env.BUGSNAG_API_KEY_FRONTEND,
        plugins: [new BugsnagPluginReact()],
    });

    BSErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

const ErrorBoundary = ({ message = undefined, children }) => {
    return (
        <BSErrorBoundary
            // ignore errors in dev
            onError={(e) => {
                if (process.env.NODE_ENV === 'production') {
                    return true;
                }

                console.log(e);
                return false;
            }}
            FallbackComponent={<ErrorFeedback message={message} />}
        >
            {children}
        </BSErrorBoundary>
    );
};

ErrorBoundary.propTypes = {
    children: PropTypes.node,
    message: ErrorFeedback.propTypes.message,
};

export default ErrorBoundary;
