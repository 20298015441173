import AnimatedList from 'components/AnimatedList/AnimatedList';
import AuditTrailItem from '../AuditTrailItem';
import Button from 'components/Button';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './AuditTrail.scss';
import useConsentExport from 'hooks/useConsentExport';
import useListing from 'hooks/useListing';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const AuditTrail = ({ siteUuid, consent, onGoBack }) => {
    const handleExport = useConsentExport();
    const { t } = useTranslation();

    const [consents, { isSuccess, isLoading }] = useListing(
        `common/sites/${siteUuid}/consents/${consent?.visitor_id}`,
        {},
        !!consent
    );

    return (
        <div
            className={cx('audit-trail', {
                isActive: !!consent,
            })}
        >
            <div
                className={cx(
                    'header',
                    'd-flex',
                    'justify-content-between',
                    'flex-wrap',
                    'flex-lg-nowrap',
                    'align-items-center'
                )}
            >
                <div className={cx('d-flex', 'align-items-center')}>
                    <button
                        onClick={onGoBack}
                        className={cx('buttonPrev')}
                        icon="md/ic_keyboard_arrow_left"
                    >
                        <Icon icon="md/ic_keyboard_arrow_left" />
                    </button>

                    <span className={cx('title')}>{consent?.visitor_id}</span>
                </div>
                {!isLoading && isSuccess && (
                    <Button
                        className={cx('rounded-0', 'downloadBtn')}
                        icon="md/ic_cloud_download"
                        onClick={() => {
                            if (!isSuccess) {
                                return;
                            }
                            handleExport(siteUuid, {
                                search: consent.visitor_id,
                            });
                        }}
                        label={t('common:consents_list_view.download_csv')}
                    />
                )}
            </div>

            <div className={cx('list')}>
                {isLoading && (
                    <div className={cx('py-3')}>
                        <Spinner />
                    </div>
                )}
                {!isLoading && isSuccess && (
                    <AnimatedList
                        list={consents.data}
                        itemComponent={AuditTrailItem}
                        itemSpacing={0}
                        itemContainerProps={{
                            className: cx('audit-trail-item'),
                        }}
                    />
                )}
            </div>
        </div>
    );
};

AuditTrail.propTypes = {
    siteUuid: PropTypes.string,
    consent: PropTypes.object,
    onGoBack: PropTypes.func,
};

export default AuditTrail;
