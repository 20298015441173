import useAppState from './useAppState';

const useApiNamespace = () => {
    const { user } = useAppState();
    if (user && user.uuid) {
        return user.is_admin ? 'admin' : 'client';
    }

    //for guest namespace in translations
    return 'guest';
};

export default useApiNamespace;
