import { CardElement, injectStripe } from 'react-stripe-elements';
import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect, useState } from 'react';

import API from 'helpers/api';
import Form from 'components/Form/Form';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './UpdateCardForm.scss';
import useAppState from 'hooks/useAppState';
import { useClientPolicy } from 'policies/ClientPolicy';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const UpdateCardForm = ({ stripe, cardHolder, reload, ...props }) => {
    const { user } = useAppState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const canUpdate = useClientPolicy('updateBilling', user.client);
    const notify = useNotifications();
    const [request, , err, submitState] = useExecuteRequest();
    const { t } = useTranslation();

    const handleSubmit = useCallback(
        (data) => {
            if (isSubmitting) {
                return;
            }

            setIsSubmitting(true);
            stripe
                .createToken({
                    type: 'card',
                    name: data.cardHolder,
                })
                .then((response) => {
                    return request(
                        API.put(
                            `/client/clients/${user.client.uuid}/update-card`,
                            { token: response.token.id }
                        )
                    );
                })
                .finally(() => setIsSubmitting(false));
        },
        [request, isSubmitting]
    );

    useEffect(() => {
        if (submitState.isSuccess) {
            notify.success(t('components:update_card_form.saved'));
            reload();
        }
        if (submitState.isError) {
            notify.error(err.message);
        }
    }, [submitState.isSuccess, submitState.isError, notify]);

    return (
        <Form
            isSubmitting={isSubmitting || submitState.isLoading}
            initialValues={{ cardHolder }}
            onSubmit={handleSubmit}
        >
            <Row>
                <Col xs="12" lg="6">
                    <Form.Field
                        disabled={!canUpdate}
                        name="cardHolder"
                        label={t(
                            'components:update_card_form.card_holder_field_label'
                        )}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="12" lg="6">
                    <div className={cx('form-group')}>
                        <label>
                            {t('components:update_card_form.card_field_label')}
                        </label>
                        <CardElement
                            disabled={!canUpdate}
                            className={cx('form-control', 'StripeElement')}
                            style={{
                                base: {
                                    fontFamily: 'Noto Sans, sans-serif',
                                    fontSize: '1rem',
                                    fontSmoothing: 'antialiased',
                                },
                            }}
                        />
                    </div>
                </Col>
            </Row>
            {!!canUpdate && (
                <Form.Submit
                    label={t('components:update_card_form.update_card_details')}
                />
            )}
        </Form>
    );
};

UpdateCardForm.propTypes = {
    stripe: PropTypes.object,
    cardHolder: PropTypes.string,
    reload: PropTypes.func,
};

UpdateCardForm.defaultProps = {
    stripe: {},
    cardHolder: '',
    reload: () => null,
};

export default injectStripe(UpdateCardForm);
