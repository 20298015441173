import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './ErrorFeedback.scss';

const cx = classNames(styles);
const renderMessage = (error) => (
    <div style={{ maxWidth: 500, padding: 15, margin: '0 auto' }}>
        <p style={{ marginBottom: 15, textAlign: 'center' }}>
            Oops. Something went wrong.
        </p>
        <p style={{ textAlign: 'center' }}>
            <button
                type="button"
                style={{
                    boxShadow: 'nonw',
                    border: '1px solid #333',
                    borderRadius: '20px',
                    background: 'none',
                }}
                onClick={() => window.location.reload()}
            >
                Reload
            </button>
        </p>
        {process.env.NODE_ENV === 'development' && (
            <div style={{ margin: '0 auto' }}>
                {error?.message}
                <pre>{error?.stack}</pre>
            </div>
        )}
    </div>
);

const ErrorFeedback = ({ message = renderMessage, error }) => {
    return <>{typeof message === 'function' ? message(error) : message}</>;
};

ErrorFeedback.propTypes = {
    message: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    error: PropTypes.instanceOf(Error),
};

ErrorFeedback.defaultProps = {};

export default ErrorFeedback;
