import { useCallback, useEffect, useMemo, useState } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import useMaybeSetState from 'hooks/useMaybeSetState';

const parseErrorObject = (error) => {
    if (!error || typeof error !== 'object') {
        return null;
    }

    const replaceRegex = new RegExp('->', 'gi');
    const newError = new Error(error.message);
    newError.validation = {};

    for (const key in error.validation || {}) {
        const newKey = key.replace(replaceRegex, '.');

        if (!Array.isArray(error.validation[key])) {
            newError.validation[newKey] = [];
            continue;
        }

        const newValue = error.validation[key].map((i) =>
            i.replace(replaceRegex, '.')
        );
        newError.validation[newKey] = newValue;
    }

    return newError;
};

const useForm = (initialValues = {}, error = null) => {
    const maybeSetState = useMaybeSetState();
    const [formError, setFormError] = useState(parseErrorObject(error));
    const [formData, setFormData] = useState(
        initialValues ? _cloneDeep(initialValues) : {}
    );
    const handleChange = useCallback(
        (field, value) => {
            maybeSetState(() =>
                setFormData((prevFormData) => {
                    if (!field) {
                        return _cloneDeep(value);
                    }

                    const newFormData = _cloneDeep(prevFormData);
                    _set(newFormData, field, value);
                    return newFormData;
                })
            );
            maybeSetState(() => setFormError(null));
        },
        [setFormData, maybeSetState, setFormError]
    );

    useEffect(() => {
        if (_isEqual(initialValues, formData)) {
            return;
        }

        maybeSetState(() => setFormData(initialValues));
    }, [JSON.stringify(initialValues)]);

    useEffect(() => {
        const errObj = parseErrorObject(error);
        if (errObj === formError) {
            return;
        }

        maybeSetState(() => setFormError(errObj));
    }, [error]);

    const hasChanged = useMemo(
        () => !_isEqual(initialValues, formData),
        [JSON.stringify(initialValues), JSON.stringify(formData)]
    );

    if (hasChanged && process.env.NODE_ENV === 'development') {
        const formChanges = _reduce(
            formData,
            (result, value, key) => {
                if (!_isEqual(value, initialValues[key])) {
                    result[key] = {
                        initial: initialValues[key],
                        current: value,
                    };
                }

                return result;
            },
            {}
        );
    }

    return [formData, handleChange, hasChanged, formError];
};

export default useForm;
