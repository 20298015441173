import _trim from 'lodash/trim';

export const SPLIT_REGEX = new RegExp('(\r\n|\n)');
export const trimTextArray = (arr) => arr.map(_trim).filter((i) => !!i);
export const splitText = (text = '') => text.split(SPLIT_REGEX);

/**
 * splitMultilineText converts a multiline text into an array of strings
 * by splitting it on newline character and trimming each component
 * @param {string} text
 * @returns {string[]} array of paragraphs
 */
const splitMultilineText = (text = '') => {
    return trimTextArray(splitText(text || ''));
};

export default splitMultilineText;
