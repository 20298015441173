import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './FormHelpText.scss';

const cx = classNames(styles);

const FormHelpText = ({ children, className, ...props }) => (
    <small
        {...props}
        className={cx(
            className,
            'helpText',
            'form-text',
            'text-muted',
            'font-italic',
            'mb-2'
        )}
    >
        {children}
    </small>
);

FormHelpText.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

FormHelpText.defaultProps = {};

export default FormHelpText;
