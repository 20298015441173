import { Badge } from 'reactstrap';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './AcceptedBadge.scss';

const cx = classNames(styles);

const AcceptedBadge = ({ accepted, label }) => {
    return (
        <Badge className={cx('badge')} color={accepted ? 'primary' : 'danger'}>
            <Icon
                icon={accepted ? 'md/ic_done' : 'md/ic_clear'}
                className={cx('mr-2')}
            />
            {label}
        </Badge>
    );
};

AcceptedBadge.propTypes = {
    accepted: PropTypes.bool,
    label: PropTypes.node,
};

export default AcceptedBadge;
