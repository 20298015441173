import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect } from 'react';

import Client from 'models/Client';
import { FEATURE_WHITELABEL } from 'constants/features';
import Form from 'components/Form/Form';
import PropTypes from 'prop-types';
import WhitelabelAdminAreaSettings from './components/WhitelabelAdminAreaSettings';
import WhitelabelBannerSettings from './components/WhitelabelBannerSettings';
import api from 'helpers/api';
import classNames from 'helpers/classNames';
import prepareSaveData from './prepareSaveData';
import styles from './ClientWhitelabelTab.scss';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);

const ClientWhitelabelTab = ({ client, reload }) => {
    const { user, enabledFeatures } = useAppState();
    const [trans] = useTranslate('common:edit_client_view.fields');
    const [t] = useTranslate();
    const notify = useNotifications();
    const isWhitelabelEnabled = !!enabledFeatures[FEATURE_WHITELABEL];

    const [request, res, err, saveStatus] = useExecuteRequest();
    useEffect(() => {
        if (saveStatus.isError) {
            notify.error(
                <>
                    <p>{t('common:edit_client_view.failed_to_update')}</p>
                    <p>{err.message}</p>
                    {Object.values(err.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </>
            );
        }

        if (saveStatus.isSuccess) {
            notify.success(t('common:edit_client_view.client_updated'));
            setTimeout(() => {
                reload();
            }, [500]);
        }
    }, [res, saveStatus.isSuccess, saveStatus.isError]);

    const handleSubmit = useCallback(
        (form) => {
            const url = `/common/clients/${client.uuid}/whitelabel`;
            request(api.request('PUT', url, prepareSaveData(form, client)));
        },
        [request, client]
    );

    return (
        <Form
            isSubmitting={saveStatus.isLoading}
            initialValues={{ ...client }}
            error={err}
            onSubmit={handleSubmit}
        >
            {/* Admin-only whitelabel settings */}
            {isWhitelabelEnabled && user.is_admin && client.is_self_managed && (
                <Row>
                    <Col md="6">
                        <Form.Field
                            label={trans('whitelabel.enable_banner')}
                            helpText={trans('whitelabel.enable_banner_help')}
                            type="Toggle"
                            name="whitelabel_banner_enabled"
                        />
                    </Col>
                    <Col md="6">
                        <Form.Field
                            label={trans('whitelabel.enable_admin')}
                            helpText={trans('whitelabel.enable_admin_help')}
                            type="Toggle"
                            name="whitelabel_admin_enabled"
                        />
                    </Col>
                </Row>
            )}

            {/* Settings dependent on the admin's selection above */}
            <WhitelabelBannerSettings />
            {isWhitelabelEnabled && <WhitelabelAdminAreaSettings />}
            <Form.Submit icon="md/ic_save" label={t('common:buttons.save')} />
        </Form>
    );
};

ClientWhitelabelTab.propTypes = {
    client: PropTypes.instanceOf(Client),
    reload: PropTypes.func,
};

ClientWhitelabelTab.defaultProps = {};

export default ClientWhitelabelTab;
