export const VAT_VALIDATION_STATUS_INVALID = 0;
export const VAT_VALIDATION_STATUS_PENDING = 1;
export const VAT_VALIDATION_STATUS_VALID = 2;

export const VAT_VALIDATION_STATUSES = {
    [VAT_VALIDATION_STATUS_INVALID]: {
        icon: 'fa/regular/exclamationTriangle',
        color: 'danger',
        text: 'invalid',
    },
    [VAT_VALIDATION_STATUS_PENDING]: {
        icon: 'fa/regular/hourglassHalf',
        color: 'warning',
        text: 'pending',
    },
    [VAT_VALIDATION_STATUS_VALID]: {
        icon: 'fa/regular/shieldCheck',
        color: 'success',
        text: 'valid',
    },
};
