import { ButtonGroup, TabContent, TabPane } from 'reactstrap';
import React, { Suspense, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import TabsNav from 'components/TabsNav';
import classNames from 'helpers/classNames';
import styles from './Tabs.scss';

const cx = classNames(styles);

const Tabs = ({
    alignButtons,
    initialTab,
    tabs,
    onTabChange,
    useHashNav,
    ...props
}) => {
    const initialIndex = tabs[initialTab] ? initialTab : 0;
    const history = useHistory();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(initialIndex);

    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
        if (useHashNav && location.hash !== `#tab-${newTab}`) {
            history.push({ hash: `tab-${newTab}` });
        }
        if (typeof onTabChange === 'function') {
            onTabChange(newTab);
        }
    };

    useEffect(() => {
        if (!useHashNav) {
            return;
        }

        const tab = parseInt(location.hash.replace('#tab-', ''));
        if (!isNaN(tab) && tab !== activeTab && tab >= 0 && tab < tabs.length) {
            setActiveTab(tab);
        }
    }, [location.hash]);

    return (
        <>
            {tabs.length > 1 && (
                <TabsNav
                    className={cx('mb-3')}
                    tabs={tabs}
                    activeTab={activeTab}
                    onTabSelected={handleTabChange}
                />
            )}
            <TabContent activeTab={activeTab}>
                {tabs.map((tab, index) => {
                    if (!!tab.disabled) {
                        return null;
                    }

                    const TabComponent = tab.component;
                    return (
                        <TabPane key={index} tabId={index}>
                            <Suspense fallback={<Spinner />}>
                                {index === activeTab && (
                                    <TabComponent tabIndex={index} {...props} />
                                )}
                            </Suspense>
                        </TabPane>
                    );
                })}
            </TabContent>
        </>
    );
};

Tabs.propTypes = {
    alignButtons: PropTypes.oneOf(['start', 'center', 'end']),
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        })
    ),
    initialTab: PropTypes.number,
    onTabChange: PropTypes.func,
    useHashNav: PropTypes.bool,
};

Tabs.defaultProps = {
    tabs: [],
    initialTab: 0,
    alignButtons: 'start',
};

export default Tabs;
