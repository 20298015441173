import useFormState from 'hooks/useFormState';
import { useMemo } from 'react';

/**
 * Get form errors for a given key using the closes form's context state
 * @param {string} key
 * @returns {string[]} array with form errors for given key
 */
const useFormErrors = (key = '') => {
    const { error } = useFormState();
    const validationErrors = useMemo(() => {
        const errorsObj = (error || {}).validation || {};

        // rename name[0] to name.0, because that's how laravel returns it
        const laravelName = key.replace('[', '.').replace(']', '');

        return errorsObj[laravelName] || [];
    }, [JSON.stringify(error), name]);

    if (!key) {
        return [];
    }

    return validationErrors;
};

export default useFormErrors;
