import AppStateProvider from 'core/AppStateProvider';
import AssetsVersionNotifier from 'core/AssetsVersionNotifier';
import DeveloperTools from 'core/DeveloperTools';
import ErrorBoundary from 'core/ErrorBoundary';
import InstanceSettingsProvider from 'core/InstanceSettingsProvider';
import NotificationsProvider from 'core/NotificationsProvider';
import React from 'react';
import Router from 'core/Router';
import SessionRefresher from 'core/SessionRefresher';
import TranslationsProvider from 'core/TranslationsProvider';
import { Util } from 'reactstrap';
import bsCSSModule from 'styles/bootstrap';

const App = () => {
    Util.setGlobalCssModule(bsCSSModule);
    return (
        <ErrorBoundary>
            <InstanceSettingsProvider>
                <AppStateProvider>
                    <TranslationsProvider>
                        <NotificationsProvider>
                            <Router />
                            <SessionRefresher />
                            <AssetsVersionNotifier />
                            {process.env.NODE_ENV !== 'production' && (
                                <DeveloperTools />
                            )}
                        </NotificationsProvider>
                    </TranslationsProvider>
                </AppStateProvider>
            </InstanceSettingsProvider>
        </ErrorBoundary>
    );
};

export default App;
