import { Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import API from 'helpers/api';
import Button from 'components/Button';
import Form from 'components/Form';
import Link from 'components/Link';
import RecoveryCodesModal from './components/RecoveryCodesModal';
import SetCheckQrModal from './components/SetCheckQrModal';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './ManageTwoFaAuthForm.scss';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);
const SUPPORT_URL =
    'https://support.cookiefirst.com/hc/en-us/articles/5251282264977';

const ManageTwoFaAuthForm = () => {
    const [trans] = useTranslate('common:profile.manage_twofa');
    const { user } = useAppState();
    const notify = useNotifications();

    const [enableTwoFa, resEnableTwoFa, enableTwoFaErr, enableTwoFaState] =
        useExecuteRequest();
    const [showQr, resShowQr, errShowQr, showQrState] = useExecuteRequest();
    const [disableTwoFa, , disableTwoFaErr, disableTwoFaState] =
        useExecuteRequest();
    const [resetCodes, , resetCodesErr, resetCodesState] = useExecuteRequest();
    const [showQrModal, setShowQrModal] = useState(false);
    const [isEnabledOtp, setIsEnabledOtp] = useState(user.is_twofa_enabled);
    const [showRecoveryCodesModal, setShowRecoveryCodesModal] = useState(false);
    const [onlyRecoveryCode, setOnlyRecoveryCode] = useState(false);

    const handleShowQr = () => {
        showQr(API.get(`/common/users/${user.uuid}/twofa/qr-code`));
    };

    const handleResetCodes = () => {
        resetCodes(
            API.post(`/common/users/${user.uuid}/twofa/reset-recovery-codes`)
        );
    };

    const handleEnableTwoFa = (force = false) => {
        enableTwoFa(
            API.post(`/common/users/${user.uuid}/twofa/enable-fortify`),
            { force }
        );
    };

    const handleDisableTwoFa = () => {
        disableTwoFa(
            API.post(`/common/users/${user.uuid}/twofa/disable-fortify`)
        );
    };

    const handleClose = () => {
        setShowQrModal(false);
        setShowRecoveryCodesModal(false);
    };

    useEffect(() => {
        if (enableTwoFaState.isSuccess) {
            setIsEnabledOtp(true);
            if (resEnableTwoFa === true || !user.twofa_activated) {
                handleShowQr();
            }
            notify.success(trans('enabled_notification'));
        }
        if (enableTwoFaState.isError) {
            notify.error(enableTwoFaErr.message);
        }
    }, [
        enableTwoFaState.isSuccess,
        enableTwoFaState.isError,
        resEnableTwoFa,
        setIsEnabledOtp,
    ]);

    useEffect(() => {
        if (disableTwoFaState.isSuccess) {
            setIsEnabledOtp(false);
            notify.success(trans('disabled_notification'));
        }
        if (disableTwoFaState.isError) {
            notify.error(disableTwoFaErr.message);
        }
    }, [
        disableTwoFaState.isSuccess,
        disableTwoFaState.isError,
        setIsEnabledOtp,
    ]);

    useEffect(() => {
        if (showQrState.isSuccess) {
            if (!onlyRecoveryCode) {
                setShowQrModal(true);
            }
            if (onlyRecoveryCode) {
                setShowRecoveryCodesModal(true);
                setOnlyRecoveryCode(false);
            }
        }
        if (showQrState.isError) {
            setShowQrModal(false);
            setShowRecoveryCodesModal(false);
            setOnlyRecoveryCode(false);
            notify.error(errShowQr.message);
        }
    }, [showQrState.isSuccess, showQrState.isError, resetCodesState.isSuccess]);

    useEffect(() => {
        if (resetCodesState.isSuccess) {
            setOnlyRecoveryCode(true);
            handleShowQr();
            notify.success(trans('reset_codes_notification'));
        }
        if (resetCodesState.isError) {
            notify.error(resetCodesErr.message);
        }
    }, [resetCodesState.isSuccess, resetCodesState.isError]);

    const isTwoFaToggleLoading =
        enableTwoFaState.isLoading || disableTwoFaState.isLoading;

    return (
        <>
            <h2 className={cx('h5')}>
                {trans('title')}
                <Link to={SUPPORT_URL} external>
                    {trans('read_more')}
                </Link>
            </h2>

            <div
                className={cx(
                    'p-3',
                    'rounded',
                    'border',
                    'mb-4',
                    'position-relative'
                )}
            >
                <Form
                    initialValues={{
                        is_twofa_enabled: user.is_twofa_enabled,
                    }}
                >
                    <Form.Field
                        type="Toggle"
                        name="is_twofa_enabled"
                        disabled={isTwoFaToggleLoading}
                        inlineLabel={trans('manage_totp_two_fa')}
                        className={cx('mb-0')}
                        onChange={(f, value) => {
                            value ? handleEnableTwoFa() : handleDisableTwoFa();
                        }}
                    />
                    {isTwoFaToggleLoading && (
                        <div
                            className={cx(
                                'toggleSpinnerContainer',
                                'd-flex',
                                'align-items-center',
                                'justify-content-center'
                            )}
                        >
                            <Spinner inline color="primary" />
                        </div>
                    )}
                </Form>
            </div>

            {!!isEnabledOtp && (
                <>
                    <div className={cx('p-3', 'rounded', 'border', 'mb-4')}>
                        <Row>
                            <Col xs="12" md="auto">
                                <Button
                                    disabled={!isEnabledOtp}
                                    onClick={() => handleEnableTwoFa(true)}
                                    loading={enableTwoFaState.isLoading}
                                    label={trans('reset_label')}
                                />
                            </Col>
                            <Col xs="12" md>
                                <p>{trans('reset_qr')}</p>
                            </Col>
                        </Row>
                    </div>

                    <div className={cx('p-3', 'rounded', 'border', 'mb-4')}>
                        <Row>
                            <Col xs="12" md="auto">
                                <Button
                                    disabled={!isEnabledOtp}
                                    onClick={handleShowQr}
                                    loading={showQrState.isLoading}
                                    label={trans('show_label')}
                                />
                            </Col>
                            <Col xs="12" md>
                                <p>{trans('show_qr')}</p>
                            </Col>
                        </Row>
                    </div>

                    <div className={cx('p-3', 'rounded', 'border', 'mb-4')}>
                        <Row>
                            <Col xs="12" md="auto">
                                <Button
                                    disabled={!isEnabledOtp}
                                    loading={resetCodesState.isLoading}
                                    onClick={handleResetCodes}
                                    label={trans('reset_label')}
                                />
                            </Col>
                            <Col xs="12" md>
                                <p>{trans('reset_recovery_codes')}</p>
                            </Col>
                        </Row>
                    </div>
                </>
            )}

            {showQrModal && (
                <SetCheckQrModal
                    onClose={handleClose}
                    qrCode={resShowQr}
                    isOpen={showQrModal}
                    userUuid={user.uuid}
                />
            )}

            {showRecoveryCodesModal && (
                <RecoveryCodesModal
                    onClose={handleClose}
                    isOpen={showRecoveryCodesModal}
                    qrCode={resShowQr}
                />
            )}
        </>
    );
};

export default ManageTwoFaAuthForm;
