import { Col } from 'reactstrap';
import Form from 'components/Form';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from 'hooks/useTranslate';

const ConfirmCodeStep = ({ handleCheckQr, isChecking }) => {
    const [trans] = useTranslate('common:profile.manage_twofa');
    return (
        <Col xs={6}>
            <p>{trans('check_qr')}</p>
            <Form initialValues={{ code: '' }} onSubmit={handleCheckQr}>
                <Form.Row>
                    <Col sm="8">
                        <Form.Field
                            required
                            name="code"
                            type="string"
                            disabled={isChecking}
                            placeholder={trans('enter_token_placeholder')}
                        />
                    </Col>
                    <Col sm="4">
                        <Form.Submit
                            loading={isChecking}
                            label={trans('continue_label')}
                        />
                    </Col>
                </Form.Row>
            </Form>
        </Col>
    );
};

ConfirmCodeStep.propTypes = {
    handleCheckQr: PropTypes.func,
    isChecking: PropTypes.bool,
};

export default ConfirmCodeStep;
