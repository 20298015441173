import React, { useEffect, useState } from 'react';

import Button from 'components/Button';
import ConfirmModal from 'components/ConfirmModal';
import CopyToClipboard from 'components/CopyToClipboard';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import api from 'helpers/api';
import classNames from 'helpers/classNames';
import styles from './RegenerateApiKeyButton.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const RegenerateApiKeyButton = ({
    clientUuid = '',
    disabled = false,
    ...props
}) => {
    const { t } = useTranslation();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const notify = useNotifications();
    const [generate, res, reqErr, reqState] = useExecuteRequest();

    const handleGenerate = () => {
        if (disabled || reqState.isLoading) {
            return;
        }

        let url = null;
        if (clientUuid) {
            url = `/common/clients/${clientUuid}/generate-api-key`;
        }

        if (url) {
            generate(api.post(url));
        }
    };

    const handleOpenConfirm = () => {
        if (disabled) {
            return;
        }

        setShowConfirmModal(true);
    };

    useEffect(() => {
        if (reqState.isSuccess) {
            setShowConfirmModal(false);
            setShowSuccessModal(true);
            notify.success(t('common:generate_api_key.success'));
        }
        if (reqState.isError) {
            notify.error(reqErr.message);
        }
    }, [reqState.isSuccess, reqState.isError]);

    return (
        <>
            <Button
                {...props}
                disabled={disabled}
                icon="md/ic_autorenew"
                label={t('common:generate_api_key.generate')}
                onClick={handleOpenConfirm}
            />
            <ConfirmModal
                isOpen={showConfirmModal}
                isConfirming={reqState.isLoading}
                onCancel={() => setShowConfirmModal(false)}
                onConfirm={handleGenerate}
            >
                {t('common:generate_api_key.are_you_sure')}
            </ConfirmModal>
            {reqState.isSuccess && (
                <Modal
                    isOpen={showSuccessModal}
                    title={t('common:generate_api_key.new_key_title')}
                    onClose={() => setShowSuccessModal(false)}
                >
                    <Modal.Body>
                        <p>{t('common:generate_api_key.new_key')}</p>
                        <CopyToClipboard inline text={res.key || ''} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            label={t('common:confirm_modal.confirm')}
                            onClick={() => setShowSuccessModal(false)}
                        />
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

RegenerateApiKeyButton.propTypes = {
    disabled: PropTypes.bool,
    clientUuid: PropTypes.string,
};

export default RegenerateApiKeyButton;
