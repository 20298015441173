import React, { useCallback, useEffect, useMemo, useState } from 'react';
import appStateContext, { defaultAppState } from 'context/appState';
import { hidePreloader, showPreloader } from 'helpers/mainPreloader';

import API from 'helpers/api';
import Bugsnag from '@bugsnag/js';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import User from 'helpers/user';
import _get from 'lodash/get';
import _set from 'lodash/set';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useRequest from 'hooks/useRequest';

const AppStateProvider = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [initData] = useRequest(API.get('/init'));
    const [appState, setAppState] = useState(defaultAppState);

    const [fetchUser, res, err, loadUserMeta] = useExecuteRequest();
    const reloadUser = useCallback(
        () => fetchUser(API.get('/common/current-user')),
        [fetchUser]
    );

    const updateAppState = useCallback((key, value) => {
        if (!Object.keys(appState).includes(key)) {
            return;
        }
        const newState = { ...appState };
        _set(newState, key, value);
        setAppState(newState);
    });

    const handleUnload = useCallback(() => {
        showPreloader();
    }, []);

    useEffect(() => {
        if (loadUserMeta.isSuccess) {
            updateAppState('user', new User(res || {}));
        }
    }, [loadUserMeta.isSuccess]);

    //
    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);
        const config = initData;
        if (!config) {
            return;
        }

        const newAppState = {};
        for (const key in defaultAppState) {
            if (key === 'user' && config[key] !== null) {
                newAppState[key] = new User(config.user || {});
                continue;
            }

            newAppState[key] = config[key] || defaultAppState[key];
        }

        setAppState(newAppState);
        hidePreloader();
        setIsInitialized(true);

        const { user } = newAppState;
        if (user && process.env.BUGSNAG_API_KEY_FRONTEND) {
            Bugsnag.setUser(user.uuid, user.email, user.full_name);
        }
        return () => window.removeEventListener('beforeunload', handleUnload);
    }, [initData]);

    if (!isInitialized) {
        return null;
    }

    return (
        <appStateContext.Provider
            value={{ ...appState, updateAppState, reloadUser }}
        >
            {loadUserMeta.isLoading ? <Preloader show /> : children}
        </appStateContext.Provider>
    );
};

AppStateProvider.propTypes = {
    children: PropTypes.node,
};

export default AppStateProvider;
