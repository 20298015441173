import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { Col, Row } from 'reactstrap';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    de,
    enUS,
    es,
    fr,
    it,
    ja,
    pl,
    pt,
} from 'react-date-range/src/locale/index';

import Button from 'components/Button';
import Form from 'components/Form';
import LockedFeatureNotice from 'components/LockedFeatureNotice';
import PropTypes from 'prop-types';
import Site from 'models/Site';
import SiteStatsCharts from './SiteStatsCharts';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import dayjs from 'helpers/dayjs';
import styles from './SiteStatsTab.scss';
import { useClickAway } from 'react-use';
import useConsentExport from 'hooks/useConsentExport';
import useLang from 'hooks/useLang';
import { useSitePolicy } from 'policies/SitePolicy';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);
const LANG_MAP = {
    de: de,
    es: es,
    fr: fr,
    it: it,
    ja: ja,
    pl: pl,
    pt: pt,
    en: enUS,
};

const RANGES = [
    ...createStaticRanges([
        {
            label: 'common:site_stats_tab.range_today',
            range: () => ({
                startDate: dayjs().subtract(1, 'days').toDate(),
                endDate: dayjs().toDate(),
            }),
        },
        {
            label: 'common:site_stats_tab.range_last_seven_day',
            range: () => ({
                startDate: dayjs().subtract(7, 'days').toDate(),
                endDate: dayjs().toDate(),
            }),
        },
        {
            label: 'common:site_stats_tab.range_last_fourteen_day',
            range: () => ({
                startDate: dayjs().subtract(14, 'days').toDate(),
                endDate: dayjs().toDate(),
            }),
        },
        {
            label: 'common:site_stats_tab.range_last_thirty_day',
            range: () => ({
                startDate: dayjs().subtract(30, 'days').toDate(),
                endDate: dayjs().toDate(),
            }),
        },
        {
            label: 'common:site_stats_tab.range_this_month',
            range: () => ({
                startDate: dayjs().startOf('month').toDate(),
                endDate: dayjs().endOf('month').toDate(),
            }),
        },
    ]),
];

const DATE_FORMAT = 'DD-MM-YYYY';

const SiteStatsTab = ({ site, reload }) => {
    const { lang } = useLang();
    const [query, updateQuery] = useUrlQuery();
    const { t } = useTranslation();
    const canChangePlan = useSitePolicy('changePlan', site);
    const handleExport = useConsentExport();
    const [showDatePicker, setShowPicker] = useState(false);
    const [selectedRange, setSelectedRange] = useState({
        startDate:
            query.from && dayjs(query.from, DATE_FORMAT).isValid()
                ? dayjs(query.from, DATE_FORMAT).toDate()
                : dayjs().subtract(30, 'days').toDate(),
        endDate:
            query.to && dayjs(query.to, DATE_FORMAT).isValid()
                ? dayjs(query.to, DATE_FORMAT).toDate()
                : dayjs().toDate(),
        key: 'selection',
    });
    const [dates, setDates] = useState(selectedRange);
    const wrapperRef = useRef(null);
    useClickAway(wrapperRef, () => setShowPicker(false));

    const isFeatureLocked = useMemo(
        () => !site.getPlanFeature('consent_statistics'),
        []
    );

    const STATIC_RANGES = useMemo(
        () => RANGES.map((i) => ({ ...i, label: t(i.label) })),
        [lang]
    );

    const handleRangeSelected = (item) => {
        setSelectedRange({ ...selectedRange, ...item.selection });
    };

    // update url query when range is changed
    useEffect(() => {
        updateQuery({
            from: dayjs(dates.startDate).format(DATE_FORMAT),
            to: dayjs(dates.endDate).format(DATE_FORMAT),
        });
    }, [dates.startDate, dates.endDate]);

    const applySelectedRangeToCharts = () => setDates(selectedRange);

    return (
        <div className={cx('position-relative')}>
            <Row form>
                <Col xs="12" xl="6">
                    <Row>
                        <Col>{t('common:site_stats_tab.start_date')}</Col>
                        <Col>{t('common:site_stats_tab.end_date')}</Col>
                    </Row>
                    <div
                        className={cx('picker-wrap')}
                        ref={wrapperRef}
                        onClick={() => setShowPicker(true)}
                    >
                        <DateRangePicker
                            className={cx('d-lg-block', {
                                'custom-hide': !showDatePicker,
                            })}
                            onChange={(item) => handleRangeSelected(item)}
                            minDate={dayjs
                                .utc(site.created_at, 'YYYY-MM-DD HH:mm:ss')
                                .toDate()}
                            maxDate={dayjs().toDate()}
                            ranges={[selectedRange]}
                            inputRanges={[]}
                            staticRanges={STATIC_RANGES}
                            dateDisplayFormat="dd/MM/yyyy"
                            locale={LANG_MAP[lang] || enUS}
                        />
                    </div>
                </Col>
                <Col xs="6" xl="4">
                    <Form.Label className={cx('d-lg-block', 'd-none')}>
                        &nbsp;
                    </Form.Label>
                    <Button
                        className={cx('pl-5', 'pr-5')}
                        onClick={() => applySelectedRangeToCharts()}
                        label={t('common:site_stats_tab.apply')}
                    />
                </Col>
                <Col xs="6" xl="2" className={cx('text-right')}>
                    <Form.Label className={cx('d-lg-block', 'd-none')}>
                        &nbsp;
                    </Form.Label>
                    <Button
                        disabled={!site.uuid}
                        onClick={() =>
                            handleExport(site.uuid, {
                                from: selectedRange.startDate,
                                to: selectedRange.endDate,
                            })
                        }
                        icon="md/ic_cloud_download"
                        label={t('common:consents_list_view.download_csv')}
                    />
                </Col>
            </Row>
            {isFeatureLocked && (
                <LockedFeatureNotice
                    disabled={!canChangePlan}
                    absolute
                    requiredPlan="basic"
                    siteUuid={site.uuid}
                    currentPlanUuid={_get(site, 'plan.uuid')}
                    clientUuid={_get(site, 'client.uuid')}
                    trialEndsAt={_get(site, 'client.trial_ends_at')}
                    onPlanChanged={reload}
                />
            )}
            {!isFeatureLocked && (
                <div className={cx('mt-3')}>
                    <SiteStatsCharts
                        startDate={dates.startDate}
                        endDate={dates.endDate}
                        siteUUID={site.uuid}
                    />
                </div>
            )}
        </div>
    );
};

SiteStatsTab.propTypes = {
    site: PropTypes.instanceOf(Site),
    reload: PropTypes.func,
};

export default SiteStatsTab;
