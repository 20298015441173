import _isObject from 'lodash/isObject';

const fieldTypeMatches = (value, field) => {
    const valueType = typeof value;
    if (valueType === 'undefined') {
        return false;
    }

    switch (field.type) {
        case 'array': {
            return Array.isArray(value);
        }

        case 'random': {
            return !!value;
        }

        case 'boolean': {
            return [null, true, false].includes(value);
        }

        case 'number': {
            if (isNaN(value)) {
                return false;
            }

            return valueType === field.type;
        }
    }

    return valueType === field.type;
};

class Model {
    constructor(data = {}) {
        if (!_isObject(data)) {
            data = {};
        }

        for (const key of Object.keys(this.constructor.fields)) {
            const field = this.constructor.fields[key];
            const def = field.default;
            this[key] = typeof def !== 'undefined' ? def : null;
            let value = data[key];

            if (field.type === 'boolean') {
                if (value === 'true' || value === true) {
                    value = true;
                }
                if (value === 'false' || value === false) {
                    value = false;
                }
            }

            if (!fieldTypeMatches(value, field)) {
                if (field.type === 'random') {
                    this[key] = Math.random();
                }
                continue;
            }

            this[key] = this.parseValue(key, value);
        }
    }

    parseValue(key, value) {
        return value;
    }
}

export default Model;
