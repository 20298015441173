import { NOTIFICATION_DURATION } from '../NotificationToast/NotificationToast';
import NotificationToast from '../NotificationToast';
import Portal from 'components/Portal';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './NotificationsContaier.scss';

const cx = classNames(styles);

class NotificationsContaier extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };

    static defaultProps = {
        children: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
        };

        this.addNotification = this.addNotification.bind(this);
        this.handleDismissNotification =
            this.handleDismissNotification.bind(this);
        this.addSuccessNotification = this.addSuccessNotification.bind(this);
        this.addInfoNotification = this.addInfoNotification.bind(this);
        this.addErrorNotification = this.addErrorNotification.bind(this);
        this.addWarningNotification = this.addWarningNotification.bind(this);
    }

    clearNotificationTimeouts = {};

    componentWillUnmount() {
        Object.values(this.clearNotificationTimeouts).map((t) =>
            clearTimeout(t)
        );
    }

    addNotification({ type, title, content, timeout, ...props } = {}) {
        const notification = {
            id: Math.random(),
            type: type || 'info',
            title: title,
            show: true,
            content: content || '...',
            timeout: timeout || NOTIFICATION_DURATION,
            props: props || {},
        };
        if (!title) {
            switch (notification.type) {
                case 'info':
                    notification.title = 'Info';
                    break;
                case 'danger':
                    notification.title = 'Error';
                    break;
                case 'success':
                    notification.title = 'Success';
                    break;
                case 'warning':
                    notification.title = 'Warning';
                    break;
            }
        }

        this.setState(({ notifications }) => ({
            notifications: [notification, ...notifications],
        }));
    }

    handleDismissNotification(id) {
        this.setState(
            ({ notifications }) => {
                // First mark notification as dismissed
                // This triggers the notification hide animation
                const newNotifications = notifications.map((i) => {
                    if (i.id !== id) {
                        return i;
                    }

                    return {
                        ...i,
                        show: false,
                    };
                });

                return { notifications: newNotifications };
            },
            () => {
                // After notification is marked as dismissed and hide animation completed,
                // Remove the notification from state
                this.clearNotificationTimeouts[id] = setTimeout(() => {
                    this.setState(({ notifications }) => ({
                        notifications: notifications.filter((i) => i.id !== id),
                    }));
                }, 600);
            }
        );
    }

    addSuccessNotification(content = '', props = {}) {
        this.addNotification({
            type: 'success',
            content,
            ...props,
        });
    }

    addInfoNotification(content = '', props = {}) {
        this.addNotification({
            type: 'info',
            content,
            ...props,
        });
    }

    addErrorNotification(content = '', props = {}) {
        this.addNotification({
            type: 'danger',
            content,
            ...props,
        });
    }

    addWarningNotification(content = '', props = {}) {
        this.addNotification({
            type: 'warning',
            content,
            ...props,
        });
    }

    render() {
        const { notifications } = this.state;
        return (
            <Portal>
                <div className={cx('notifications')}>
                    {notifications.map((item) => (
                        <NotificationToast
                            {...item.props}
                            key={item.id}
                            onDismiss={this.handleDismissNotification}
                            notification={item}
                        />
                    ))}
                </div>
            </Portal>
        );
    }
}

export default NotificationsContaier;
