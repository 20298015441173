import React, { useMemo } from 'react';

import PlanFeature from './components/PlanFeature';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import featuresOrder from './featuresOrder';
import styles from './PlanChoice.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const PlanChoice = ({ checked, plan, trialEndDate, ...props }) => {
    const { t } = useTranslation();
    const displayPrice = useMemo(() => {
        return new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
        }).format(plan.price);
    }, [plan.price]);

    return (
        <div
            className={cx('plan-choice', plan.config_name, { checked })}
            onClick={(e) => {
                const plans = ['basic', 'free', 'plus'];
                const id = e.target.getAttribute('id');
                if (!plans.includes(id)) {
                    e.preventDefault();
                }
            }}
        >
            <span className={cx('header', `header-${plan.config_name}`)}>
                <span>{plan.name}</span>
                <span>
                    {displayPrice}
                    <span className={cx('text-lowercase', 'ml-1')}>
                        {t(
                            `components:choose_plan.billing_period.${plan.billing_period}`
                        )}
                    </span>
                </span>
            </span>
            <div className={cx('plan-choice__content')}>
                <ul className={cx('features')}>
                    {featuresOrder.map((name) => {
                        const value = plan.features[name];
                        if (typeof value === 'undefined') {
                            return null;
                        }

                        return (
                            <PlanFeature key={name} name={name} value={value} />
                        );
                    })}
                </ul>
                {trialEndDate && plan.config_name !== 'free' && (
                    <p className={cx('trial-notice')}>
                        <span>{t('components:plan_choice.trial_notice')}</span>{' '}
                        {trialEndDate}
                    </p>
                )}
                <div
                    id={plan.config_name}
                    role="button"
                    className={cx('btn', plan.config_name, { bold: checked })}
                >
                    {plan.config_name === 'free' &&
                        t('components:plan_choice.select_plan')}
                    {plan.config_name !== 'free' &&
                        checked &&
                        t('components:plan_choice.selected')}
                    {plan.config_name !== 'free' &&
                        !checked &&
                        !trialEndDate &&
                        t('components:plan_choice.select_plan')}
                    {plan.config_name !== 'free' &&
                        !checked &&
                        trialEndDate &&
                        t('components:plan_choice.free_trial')}
                </div>
            </div>
        </div>
    );
};

PlanChoice.propTypes = {
    plan: PropTypes.object,
    checked: PropTypes.bool,
    trialEndDate: PropTypes.string,
};

PlanChoice.defaultProps = {
    plan: {},
    checked: false,
    trialEndDate: null,
};

export default PlanChoice;
