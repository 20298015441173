import { Col, Row } from 'reactstrap';

import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from '../SiteStatsTab.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const columns = [
    { key: 'total', label: 'site_stats_tab.total' },
    { key: 'denies', label: 'site_stats_tab.denies' },
    { key: 'accepted_performance', label: 'categories.performance' },
    { key: 'accepted_functional', label: 'categories.functional' },
    { key: 'accepted_advertising', label: 'categories.advertising' },
    { key: 'withdrawals', label: 'site_stats_tab.withdrawals' },
];

const CategoryCountChart = ({ data, isLoading }) => {
    const { t } = useTranslation();
    if (isLoading) {
        return <Spinner />;
    }

    return (
        <Row form className={cx('p-0', 'categories-wrap')}>
            {columns.map((column) => (
                <Col key={column.key} xs="6" sm="4" xl className={cx('mb-3')}>
                    <div className={cx('category-cell', 'py-3')}>
                        <h2 className={cx('mb-2')}>{data[column.key] || 0}</h2>
                        <p className={cx('mb-0')}>
                            {t(`common:${column.label}`)}
                        </p>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

CategoryCountChart.propTypes = {
    data: PropTypes.shape({}),
    isLoading: PropTypes.bool,
};

export default CategoryCountChart;
