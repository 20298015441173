/* eslint-disable react/prop-types */

import { Form as BsForm, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import CsrfField from './components/CsrfField';
import FormContext from 'context/form';
import FormError from './components/FormError';
import FormField from './components/FormField';
import FormHelpText from './components/FormHelpText';
import FormSubmit from './components/FormSubmit';
import MethodField from './components/MethodField';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import _noop from 'lodash/noop';
import api from 'helpers/api';
import classNames from 'helpers/classNames';
import { useEffect } from 'react';
import useForm from 'hooks/useForm';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames();

const Form = ({
    method = 'GET',
    onSubmit,
    uncontrolled,
    isSubmitting,
    spinner = <Spinner />,
    initialValues,
    error,
    children,
    className = '',
    ...props
}) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const [validationError, setValidationError] = useState(null);
    const notify = useNotifications();

    const [data, handleChange, hasChanged, err] = useForm(
        initialValues || {},
        validationError || error
    );

    const handleHTMLValidationError = (form) => {
        const invalidFields = form.querySelectorAll(':invalid');

        if (!invalidFields.length) {
            return;
        }

        // Collect info about all wrong fields
        const errorMessages = Array.from(invalidFields).reduce(
            (aggregate, field) => {
                return {
                    ...aggregate,
                    [field.name]: [field.validationMessage],
                };
            },
            {}
        );

        // Prepare info about error, with right structure to imitate server errors
        const newError = api.parseError({
            status: 422,
            message: t('validation:invalid_given_data'),
            response: {
                body: {
                    errors: errorMessages,
                },
            },
        });

        // Update state with validation error
        setValidationError(newError);

        // Show error notification
        notify.error(newError.message);
    };

    const handleSubmit = useCallback(
        (e) => {
            // Clear info about validation errors
            setValidationError(null);

            // if there is no submit callback, allow form to submit like normal
            if (typeof onSubmit !== 'function') {
                // Check if html validation is correct
                if (!e.target.checkValidity()) {
                    e.preventDefault();
                    e.stopPropagation(); // avoid nested forms submitting parent form

                    // Check for more details about validation error and show info about it.
                    handleHTMLValidationError(e.target);
                }

                return;
            }

            e.preventDefault();
            e.stopPropagation(); // avoid nested forms submitting parent form

            if (!e.target.checkValidity()) {
                // Check for more details about validation error and show info about it.
                handleHTMLValidationError(e.target);
                return;
            }

            e.persist();
            onSubmit(data, e);
        },
        [onSubmit, data]
    );

    const contextObject = useMemo(() => {
        return {
            data,
            initialValues,
            handleChange,
            hasChanged,
            error: err,
            uncontrolled,
        };
    }, [
        JSON.stringify(data),
        JSON.stringify(initialValues),
        handleChange,
        hasChanged,
        JSON.stringify(err),
        uncontrolled,
    ]);

    return (
        <FormContext.Provider value={contextObject}>
            {isSubmitting && spinner}
            <BsForm
                {...props}
                innerRef={formRef}
                // don't use { 'd-none' : isSubmitting } because then it's impossible to pass d-none from parent
                className={cx(className, isSubmitting ? 'd-none' : '')}
                onSubmit={handleSubmit}
                method={method.toUpperCase() === 'GET' ? 'GET' : 'POST'}
                noValidate
            >
                {['PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase()) && (
                    <MethodField method={method} />
                )}
                {method.toUpperCase() !== 'GET' && <CsrfField />}
                {typeof children === 'function'
                    ? children(contextObject)
                    : children}
            </BsForm>
        </FormContext.Provider>
    );
};

Form.propTypes = {
    method: PropTypes.oneOf(['GET', 'POST', 'PUT', 'DELETE']),
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    uncontrolled: PropTypes.bool,
    initialValues: PropTypes.object,
    className: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Error)]),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

Form.CustomInput = ({ name, id, ...props }) => (
    <CustomInput {...props} id={id || name} />
);

Form.CsrfField = CsrfField;

Form.MethodField = MethodField;

Form.Label = ({ name, htmlFor, ...props }) => (
    <Label {...props} for={htmlFor} />
);

Form.Group = FormGroup;

Form.Row = (props) => <Row {...props} form />;

Form.Error = FormError;

Form.Field = FormField;

Form.HelpText = FormHelpText;

Form.Submit = FormSubmit;

export default Form;
