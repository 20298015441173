import '@textabledev/langs-flags-list/lang-flags.css';

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import flagsSprite from '@textabledev/langs-flags-list/lang-flags.png';
import prepareLocale from './prepareLocale';
import styles from './LangFlag.scss';

const cx = classNames({ ...styles });

const LangFlag = ({ lang, className, ...props }) => (
    <span className={cx(className, 'flag-container')}>
        <span
            className={cx('lang-icon', `lang-icon-${prepareLocale(lang)}`)}
            style={{ backgroundImage: `url(${flagsSprite})` }}
        />
    </span>
);

LangFlag.propTypes = {
    lang: PropTypes.string,
    className: PropTypes.string,
};

LangFlag.defaultProps = {
    lang: '',
    className: '',
};

export default LangFlag;
