import React, { Suspense, lazy } from 'react';

import ErrorBoundary from 'core/ErrorBoundary';

export default (promise, fallback = () => null) => {
    const Component = lazy(promise);
    const Fallback = fallback;
    return (props) => (
        <ErrorBoundary message="Failed to render part of UI.">
            <Suspense fallback={<Fallback />}>
                <Component {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};
