import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import classNames from 'helpers/classNames';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames();

const ChangeSettingsForm = () => {
    const { t } = useTranslation();
    const { user, reloadUser } = useAppState();
    const notify = useNotifications();
    const [request, res, err, submitState] = useExecuteRequest();
    const handleSubmit = useCallback(
        (data) => {
            request(
                API.put(`/common/users/${user.uuid}/update-settings`, {
                    ...data,
                    preferred_language: Array.isArray(data.preferred_language)
                        ? data.preferred_language[0]
                        : data.preferred_language,
                })
            );
        },
        [request]
    );

    useEffect(() => {
        if (submitState.isSuccess) {
            notify.success(t('common:common.saved'));
            setTimeout(() => {
                reloadUser();
            }, [500]);
        }
        if (submitState.isError) {
            notify.error(err.message);
        }
    }, [submitState.isSuccess, submitState.isError, notify]);

    return (
        <>
            <Form
                initialValues={{
                    full_name: user.full_name,
                    display_name: user.display_name,
                    email: user.email,
                    preferred_language: user.preferred_language,
                }}
                error={err}
                onSubmit={handleSubmit}
            >
                <Form.Field
                    name="full_name"
                    label={t('common:profile.personal_data_form.full_name')}
                    required
                />
                <Form.Field
                    name="display_name"
                    label={t('common:profile.personal_data_form.display_name')}
                    required
                />
                <Form.Field
                    name="email"
                    label={t('common:profile.personal_data_form.email')}
                    required
                />
                <Form.Field
                    label={t(
                        'common:profile.personal_data_form.preferred_language'
                    )}
                    name="preferred_language"
                    required
                    adminOnly
                    type="LanguageDropdown"
                />

                <Form.Submit
                    icon="md/ic_save"
                    loading={submitState.isLoading}
                    label={t('common:buttons.save')}
                />
            </Form>
        </>
    );
};

export default ChangeSettingsForm;
