import Model from 'models/Model';
import _get from 'lodash/get';

class Site extends Model {
    static fields = {
        uuid: { type: 'string', default: '' },
        name: { type: 'string', default: '' },
        base_url: { type: 'string', default: '' },
        dev_urls: { type: 'array', default: [] },
        privacy_policy_url: { type: 'string', default: '' },
        created_at: { type: 'string', default: '' },
        additional_link: { type: 'string', default: '' },
        show_privacy_url_in_banner: { type: 'boolean', default: 'false' },
        is_cookie_scan_completed: { type: 'boolean', default: 'false' },
        widget_languages: { type: 'array', default: [] },
        plan_features: { type: 'object', default: {} },
        widget_styles: { type: 'object', default: {} },
        used_cookie_categories: { type: 'array', default: [] },
        api_key: { type: 'string', default: '' },
        plan_uuid: { type: 'string', default: '' },
        plan: {
            type: 'object',
            default: { config_name: 'free', features: {} },
        },
        texts: { type: 'object', default: {} },
        client_uuid: { type: 'string', default: '' },
        client: { type: 'object', default: {} },
        status: { type: 'number', default: 0 },
        scripts: { type: 'array', default: [] },
        subscription: { type: 'object', default: '' },
        subscription_uuid: { type: 'string', default: '' },
        subscription: { type: 'object', default: null },
        stripe_plan: { type: 'string', default: '' },
        stripe_subscription_status: { type: 'string', default: '' },
        banner_floating_settings_button_enabled: {
            type: 'boolean',
            default: false,
        },
        banner_floating_settings_button_icon: { type: 'string', default: '' },
        banner_accept_all_enabled: { type: 'boolean', default: false },
        banner_categories_enabled: { type: 'boolean', default: false },
        banner_continue_button_type: { type: 'string', default: 'disabled' },
        tabs_on_settings_panel: { type: 'array', default: [] },
        show_language_switcher: { type: 'boolean', default: false },
        hide_banner_outside_eu: { type: 'boolean', default: false },
        consent_expires_after: { type: 'number', default: 12 },
        performance_enabled_default: { type: 'boolean', default: false },
        functional_enabled_default: { type: 'boolean', default: false },
        advertising_enabled_default: { type: 'boolean', default: false },
        performance_executed_default: { type: 'boolean', default: false },
        functional_executed_default: { type: 'boolean', default: false },
        advertising_executed_default: { type: 'boolean', default: false },
        site_group_uuid: { type: 'string', default: '' },
        group: { type: 'object', default: {} },
        reconsent_after: { type: 'number', default: null },
        reconsent_check: { type: 'string', default: null },
        reconsent_categories: { type: 'array', default: [] },
        consent_policy: { type: 'number', default: 1 },
        autoblock_known: { type: 'boolean', default: false },
        enabled_widget_branding: { type: 'array', default: [] },
        google_consent_mode_enabled: { type: 'boolean', defult: false },
        datalayer_variable: { type: 'string', default: '' },
        stealth_mode: { type: 'boolean', default: false },
        banner_debug: { type: 'boolean', default: false },
        inherit_site_lang: { type: 'boolean', default: false },
        banner_non_eu_countries: { type: 'array', default: [] },
    };

    getPlanFeature(name = null) {
        if (this.plan_features && Object.keys(this.plan_features).length > 0) {
            return _get(this, `plan_features.${name}`, null);
        }

        if (this.plan && Object.keys(this.plan).length > 0) {
            return _get(this, `plan.features.${name}`, null);
        }

        return null;
    }

    getPayingClient() {
        if (!this.client) {
            return null;
        }

        if (this.client.is_self_managed) {
            return this.client;
        }

        return this.client.manager || null;
    }

    getDisplayName(translate) {
        if (this.name) {
            return this.name;
        }
        if (this.base_url) {
            return this.base_url;
        }
        return translate('common:site_list_item.site_not_configured');
    }
}

export default Site;
