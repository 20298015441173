/* global Uint8Array, Promise */

export const convertImageFileToBase64 = (image) => {
    return new Promise((resolve, reject) => {
        if (!(image instanceof File)) {
            reject('image is not a File');
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const getImageData = (file) => {
    return new Promise((resolve, reject) => {
        convertImageFileToBase64(file).then((src) => {
            const img = new Image();
            img.onload = () => {
                resolve({
                    width: img.naturalWidth,
                    height: img.naturalHeight,
                    src: src,
                });
            };
            img.onerror = (error) => reject(error);
            img.src = src;
        });
    });
};

export const getCroppedBase64 = (src, cropData) => {
    const crop = {
        ...cropData,
        width: cropData.width || 100, // make sure we don't use empty crop
        height: cropData.height || 100, // make sure we don't use empty crop
    };
    return new Promise((resolve, reject) => {
        if (crop.unit !== '%') {
            reject(new Error('Crop object is not using % units.'));
            return;
        }

        const image = new Image();
        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = (crop.width * image.naturalWidth) / 100;
            canvas.height = (crop.height * image.naturalHeight) / 100;
            const ctx = canvas.getContext('2d');

            // draw a white background
            // ctx.fillStyle = 'white';
            // ctx.fillRect(0, 0, canvas.width, canvas.height);

            // draw the image
            ctx.drawImage(
                image,
                (crop.x * image.naturalWidth) / 100,
                (crop.y * image.naturalHeight) / 100,
                canvas.width,
                canvas.height,
                0,
                0,
                canvas.width,
                canvas.height
            );

            // As Base64 string
            const base64Image = canvas.toDataURL('image/png');
            resolve(base64Image);
        };
        image.onerror = (err) => reject(err);
        image.src = src;
    });
};

export const convertImageBase64ToBlob = (base64) => {
    const mimePattern = 'data:(image/jpeg|image/png);base64,';
    const match = base64.match(new RegExp(mimePattern));
    if (!match || match.length < 2) {
        return null;
    }

    const mime = match[1];
    const base64ImageData = base64.replace(match[0], '');
    const sliceSize = 1024;
    const byteChars = atob(base64ImageData);
    const byteArrays = [];

    for (
        let offset = 0, len = byteChars.length;
        offset < len;
        offset += sliceSize
    ) {
        const slice = byteChars.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
};
