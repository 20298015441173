/* global Promise */

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import dayjs from 'helpers/dayjs';
import detectBrowserLang from 'helpers/detectBrowserLang';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const loadLangs =
    process.env.NODE_ENV === 'production' || !!process.env.FRONTEND_LANGS;

const detector = new LanguageDetector();

// add detector to use user's language chosen when registering
detector.addDetector({
    name: 'preferred-lang',
    lookup: ({ userLang }) => userLang || null,
});

// add detector to use browser's language
// use our own detector for this instead of the built-in one
// because we don't use double lang codes like en-gb
// and default detector can't handle it
detector.addDetector({
    name: 'browser-lang',
    lookup: ({ availableLanguages }) => {
        const detected = detectBrowserLang(availableLanguages || []);
        return detected || null;
    },
});

const initTranslations = ({ userLang, availableLanguages, ns }) => {
    console.log('Initializing translations...');
    if (!loadLangs) {
        console.log('Using translation keys instead of translated text');
    }

    // don't remember language choice if user is logged out
    const rememberLang = !!userLang;
    const timestamp = dayjs().unix();
    return new Promise((resolve, reject) => {
        if (loadLangs) {
            // load translation using xhr -> see storage/app/public/app-translations
            // learn more: https://github.com/i18next/i18next-xhr-backend
            i18n.use(Backend);
        }
        // detect user's language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        i18n.use(detector)
            // pass the i18n instance to react-i18next.
            .use(initReactI18next)
            // init i18next
            // for all options read: https://www.i18next.com/overview/configuration-options
            .init(
                {
                    fallbackLng: 'en',
                    supportedLngs: availableLanguages,
                    debug: false,
                    ns: [
                        'core',
                        'components',
                        'common',
                        ns,
                        'widgetTexts',
                        'validation',
                    ],
                    defaultNS: 'core',
                    load: 'languageOnly',
                    cleanCode: true,
                    fallbackNS: 'core',
                    interpolation: {
                        escapeValue: false, // not needed for react as it escapes by default
                    },
                    backend: loadLangs
                        ? {
                              //   loadPath: `/api/translations/{{lng}}/admin/{{ns}}?t=${timestamp}`,
                              loadPath: ([reqLng], [reqNS]) => {
                                  if (
                                      reqNS === 'widgetTexts' ||
                                      reqNS === 'validation'
                                  ) {
                                      return `/api/translations/${reqLng}/${reqNS}?t=${timestamp}`;
                                  }

                                  return `/api/translations/${reqLng}/admin/${reqNS}?t=${timestamp}`;
                              },
                              crossDomain: false,
                          }
                        : undefined,
                    // settings for language detection
                    detection: {
                        order: [
                            'querystring',
                            'localStorage',
                            'cookie',
                            'preferred-lang',
                            'browser-lang',
                        ],
                        caches: rememberLang ? ['cookie', 'localStorage'] : [],
                        lookupCookie: 'app-language',
                        lookupLocalStorage: 'app-language',
                        userLang,
                        availableLanguages,
                    },
                    parseMissingKeyHandler: (key) => {
                        return loadLangs ? key : key.split('.').pop();
                    },
                },
                // callback after initialized
                (err) => {
                    if (err) {
                        console.log(err);
                        const msg = 'Error: failed to load translations.';
                        reject(new Error(msg));
                        return;
                    }

                    resolve(i18n);
                }
            );
    });
};

export default initTranslations;
