/* global module __webpack_public_path__ */
// import polyfills for IE and dynamic import
// see https://babeljs.io/docs/en/next/babel-plugin-syntax-dynamic-import#working-with-webpack-and-babel-preset-env
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'core-js/modules/es.number.is-finite';
// import global app styles
import 'styles/app.global.scss';

import App from 'core/App';
import React from 'react';
import ReactDOM from 'react-dom';
import _trim from 'lodash/trim';
import isBrowserSupported from 'helpers/isBrowserSupported';

const root = document.getElementById('root');
const notSupportedContainer = document.getElementById('not-supported');
const preloader = document.getElementById('main-preloader');
const instanceData = window.__INSTANCE_SETTINGS__;
let hasInitializedPublicPath = false;
if (!instanceData || typeof instanceData !== 'object') {
    throw new Error('Missing instance settings');
}

// handle not supported browser
if (instanceData.is_whitelabel && !isBrowserSupported()) {
    const title = notSupportedContainer.getAttribute('data-title');
    const message = notSupportedContainer.getAttribute('data-message');
    notSupportedContainer.innerHTML = `<div><h2>${title}</h2><p>${message}</p></div>`;

    root.style.display = 'none';
    preloader.style.display = 'none';
    notSupportedContainer.style.display = 'block';
} else {
    notSupportedContainer.style.display = 'none';
    root.style.display = 'block';
    ReactDOM.render(<App />, root);
}

if (!hasInitializedPublicPath) {
    // Dynamic public path to be able to easily change CDN config
    const asset_url = _trim(instanceData.admin_assets_url, '/');
    const public_path = _trim(__webpack_public_path__, '/');
    __webpack_public_path__ = `${asset_url}/${public_path}/`;
    hasInitializedPublicPath = true;
}

if (module.hot) {
    module.hot.accept();
}
