import _noop from 'lodash/noop';
import { createContext } from 'react';

export const defaultLangContext = {
    lang: 'en',
    changeLanguage: _noop,
    reloadTranslations: _noop,
};

export default createContext(defaultLangContext);
