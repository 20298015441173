import ClientApiAccessTab from 'partials/clients/ClientApiAccessTab';
import ClientBasicSettingsTab from 'partials/clients/ClientBasicSettingsTab';
import ClientInvoicesTab from 'partials/clients/ClientInvoicesTab';
import ClientWhitelabelTab from 'partials/clients/ClientWhitelabelTab';
import { FEATURE_CLIENT_API } from 'constants/features';
import { url } from 'core/Router';

export const BASIC_SETTINGS_TAB = 'basic-settings';
export const WHITELABEL_TAB = 'whitelabel';
export const API_ACCESS_TAB = 'api-access';
export const INVOICES_TAB = 'invoices';

export const getTabs = (user, client, translate, enabledFeatures = {}) => {
    const urlNS = user.is_admin ? 'admin' : 'dash';
    const isSelfManaged = client.is_self_managed;
    const isClientApiFeatureEnabled = !!enabledFeatures[FEATURE_CLIENT_API];
    const getTabUrl = (tab) => {
        return url(`${urlNS}.clients.edit.tab`, { client: client.uuid, tab });
    };

    return [
        {
            url: getTabUrl(BASIC_SETTINGS_TAB),
            label: translate('common:edit_client_view.tabs.basic_settings'),
            icon: 'md/ic_settings',
            key: BASIC_SETTINGS_TAB,
            component: ClientBasicSettingsTab,
        },
        {
            url: getTabUrl(WHITELABEL_TAB),
            label: translate('common:edit_client_view.tabs.whitelabel'),
            icon: 'fa/regular/tag',
            key: WHITELABEL_TAB,
            component: ClientWhitelabelTab,
        },
        isClientApiFeatureEnabled &&
            isSelfManaged && {
                url: getTabUrl(API_ACCESS_TAB),
                label: translate('common:edit_client_view.tabs.api_access'),
                icon: 'fa/regular/wrench',
                key: API_ACCESS_TAB,
                component: ClientApiAccessTab,
            },
        user.is_admin && {
            url: getTabUrl(INVOICES_TAB),
            label: translate('common:client_invoice_listing.heading'),
            icon: 'fa/regular/fileInvoiceDollar',
            key: INVOICES_TAB,
            component: ClientInvoicesTab,
        },
    ].filter((t) => !!t);
};
