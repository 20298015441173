import { CustomInput } from 'reactstrap';
import asyncInput from './asyncInput';

const available_inputs = [
    'LanguageDropdown',
    'Checkbox',
    'ChoiceGroup',
    'ChooseSiteSubscriptionPlan',
    'ColorPicker',
    'Date',
    'DropdownInput',
    'SingleDropdownInput',
    'ImagePicker',
    'IconPicker',
    'Radio',
    'Select',
    'Toggle',
    'QuillTextEditor',
    'Repeater',
    'SelectClient',
    'TextInput',
    'FilePicker',
];

const INPUTS = {};
for (const name of available_inputs) {
    INPUTS[name] = asyncInput(() =>
        import(/* webpackChunkName: "inputs/[request]" */ `./${name}`)
    );
}

export default INPUTS;
