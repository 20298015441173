import React, { useCallback, useMemo } from 'react';

import FormField from './FormField';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import useFormErrors from 'hooks/useFormErrors';
import useFormState from 'hooks/useFormState';

const FormFieldContainer = ({
    name,
    type,
    onChange,
    defaultValue,
    errors,
    ...props
}) => {
    const { data, handleChange, uncontrolled } = useFormState();
    const validationErrors = useFormErrors(name);

    const handleValueChange = useCallback(
        (name, value) => {
            handleChange(name, value);
            onChange(name, value);
        },
        [handleChange, onChange]
    );

    const inputValue = uncontrolled
        ? undefined
        : _get(data, name, defaultValue);
    return (
        <FormField
            {...props}
            name={name}
            type={type}
            onChange={uncontrolled ? undefined : handleValueChange}
            value={inputValue}
            defaultValue={
                typeof inputValue === 'undefined' ? defaultValue : undefined
            }
            errors={errors || validationErrors}
        />
    );
};

FormFieldContainer.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    defaultValue: PropTypes.any,
    errors: PropTypes.array,
};

FormFieldContainer.defaultProps = {
    name: undefined,
    onChange: _noop,
    type: undefined,
    errors: undefined,
    defaultValue: undefined,
};

export default FormFieldContainer;
