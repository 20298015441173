export const CLIENT_TYPE_BUSINESS = 'business';
export const CLIENT_TYPE_PRIVATE = 'private';
export const CLIENT_TYPE_NON_VAT_BUSINESS = 'non_vat_business';
export const CLIENT_TYPE_NOT_CHOSEN = 'not_chosen';

export const CLIENT_TYPE_OPTIONS = (t) => [
    {
        label: t('common:client_profile.client_type.business'),
        value: CLIENT_TYPE_BUSINESS,
    },
    {
        label: t('common:client_profile.client_type.private'),
        value: CLIENT_TYPE_PRIVATE,
    },
    {
        label: t('common:client_profile.client_type.non_vat_organisation'),
        value: CLIENT_TYPE_NON_VAT_BUSINESS,
    },
];

export const CLIENT_TYPE_ADMIN_OPTIONS = (t) => {
    const baseTypes = CLIENT_TYPE_OPTIONS(t);

    return [
        ...baseTypes,
        {
            label: t('common:client_profile.client_type.not_chosen'),
            value: CLIENT_TYPE_NOT_CHOSEN,
        },
    ];
};
