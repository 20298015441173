import { Col, Row } from 'reactstrap';

import API from 'helpers/api';
import AreaChart from './charts/AreaChart';
import CategoryCountChart from './charts/CategoryCountChart';
import ColumnChart from './charts/ColumnChart';
import PieChart from './charts/PieChart';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import dayjs from 'dayjs';
import styles from './SiteStatsTab.scss';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const StatsCharts = ({ siteUUID, startDate, endDate }) => {
    const { t } = useTranslation();
    const [data, err, reqState] = useRequest(
        API.get(`/common/consent-statistics/${siteUUID}`, {
            start_date: dayjs(startDate).format('YYYY-MM-DD'),
            end_date: dayjs(endDate).format('YYYY-MM-DD'),
        })
    );

    const stats = data || {};
    const isLoading = !data || reqState.isLoading;

    return (
        <Row className={cx('align-itemst-stretch')}>
            <Col xs="12" className={cx('col', 'mt-3', 'mb-6')}>
                <CategoryCountChart
                    data={stats.categoryCount || {}}
                    isLoading={isLoading}
                />
            </Col>
            <Col xs="12" xl="6" className={cx('col')}>
                <h5 className={cx('h5')}>
                    {t('common:site_stats_tab.opts_title')}
                </h5>
                <p>{t('common:site_stats_tab.opts_chart_description')}</p>
                <div className={cx('chartContainer')}>
                    <AreaChart data={stats.opts || []} isLoading={isLoading} />
                </div>
            </Col>
            <Col xs="12" xl="6" className={cx('col')}>
                <h5 className={cx('h5')}>
                    {t('common:site_stats_tab.consents_per_device')}
                </h5>
                <p>
                    {t('common:site_stats_tab.device_counts_chart_description')}
                </p>
                <div className={cx('chartContainer')}>
                    <PieChart
                        data={stats.deviceCount || []}
                        isLoading={isLoading}
                    />
                </div>
            </Col>
            <Col xs="12" xl="6" className={cx('col', 'mt-3')}>
                <h5>
                    {t(
                        'common:site_stats_tab.statistics_rate_per_device_category_title'
                    )}
                </h5>
                <p>
                    {t(
                        'common:site_stats_tab.statistics_rate_per_device_category_text'
                    )}
                </p>
                <div className={cx('chartContainer')}>
                    <ColumnChart
                        data={stats.deviceCategoryCount || {}}
                        isLoading={isLoading}
                    />
                </div>
            </Col>
        </Row>
    );
};

StatsCharts.propTypes = {
    siteUUID: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
};

export default StatsCharts;
