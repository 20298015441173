import {
    CLIENT_TYPE_BUSINESS,
    CLIENT_TYPE_OPTIONS,
} from 'constants/clientTypes';
import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect } from 'react';
import {
    VAT_VALIDATION_STATUSES,
    VAT_VALIDATION_STATUS_INVALID,
} from 'constants/vat-validation-statuses';

import API from 'helpers/api';
import Form from 'components/Form';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import User from 'helpers/user';
import classNames from 'helpers/classNames';
import { countries } from 'constants/geoData';
import styles from './UpdateBillingDetailsTab.scss';
import useAppState from 'hooks/useAppState';
import { useClientPolicy } from 'policies/ClientPolicy';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import useTranslate from 'hooks/useTranslate';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const UpdateBillingDetailsTab = () => {
    const { t } = useTranslation();
    const { user, reloadUser, vatCountries } = useAppState();
    const canUpdate = useClientPolicy('update', user.client);
    const [trans] = useTranslate('common:edit_client_view.fields');
    const notify = useNotifications();
    const [request, res, err, submitState] = useExecuteRequest();
    const handleSubmit = useCallback(
        (data) => {
            if (!canUpdate) {
                return;
            }

            request(API.put(`/client/clients/${user.client.uuid}`, data));
        },
        [request]
    );

    useEffect(() => {
        if (submitState.isSuccess) {
            notify.success(t('common:common.saved'));
            setTimeout(() => {
                reloadUser();
            }, 500);
        }
        if (submitState.isError) {
            notify.error(err.message);
        }
    }, [submitState.isSuccess, submitState.isError, notify]);
    return (
        <>
            <Row>
                <Col xs="12" lg="10">
                    <Form
                        initialValues={new User(user).client}
                        error={err}
                        onSubmit={handleSubmit}
                    >
                        {({ data: formData }) => {
                            const vatStatus =
                                VAT_VALIDATION_STATUSES[
                                    formData.vat_id_validation_status
                                ] ||
                                VAT_VALIDATION_STATUSES[
                                    VAT_VALIDATION_STATUS_INVALID
                                ];
                            return (
                                <>
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <Form.Field
                                                disabled={!canUpdate}
                                                name="name"
                                                label={t(
                                                    'common:profile.billing.name_on_invoice'
                                                )}
                                                required
                                            />
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <Form.Field
                                                disabled={!canUpdate}
                                                name="email"
                                                label={t(
                                                    'common:profile.billing.email'
                                                )}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <Form.Field
                                                disabled={!canUpdate}
                                                name="street"
                                                label={trans('street')}
                                                required
                                            />
                                        </Col>

                                        <Col xs="12" lg="6">
                                            <Row>
                                                <Col xs="12" lg="6">
                                                    <Form.Field
                                                        disabled={!canUpdate}
                                                        name="street_number"
                                                        label={trans(
                                                            'street_number'
                                                        )}
                                                        required
                                                    />
                                                </Col>
                                                <Col xs="12" lg="6">
                                                    <Form.Field
                                                        disabled={!canUpdate}
                                                        name="apartment"
                                                        label={trans(
                                                            'apartment'
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <Form.Field
                                                disabled={!canUpdate}
                                                name="postcode"
                                                label={trans('postcode')}
                                                required
                                            />
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <Form.Field
                                                disabled={!canUpdate}
                                                name="city"
                                                label={trans('city')}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <Form.Field
                                                disabled={!canUpdate}
                                                type="Select"
                                                name="country_code"
                                                label={trans('country_code')}
                                                showDefaultOption
                                                required
                                                options={countries}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="6">
                                            <Form.Field
                                                disabled={!canUpdate}
                                                type="SingleDropdownInput"
                                                required
                                                helpText={t(
                                                    'common:client_profile.client_type.help'
                                                )}
                                                label={t(
                                                    'common:client_profile.client_type.title'
                                                )}
                                                options={CLIENT_TYPE_OPTIONS(t)}
                                                name="client_type"
                                            />
                                        </Col>
                                        {formData.client_type ===
                                            CLIENT_TYPE_BUSINESS &&
                                            vatCountries.includes(
                                                formData.country_code
                                            ) && (
                                                <Col xs="12" md="6">
                                                    <Form.Field
                                                        disabled={!canUpdate}
                                                        name="vat_id"
                                                        required
                                                        helpText={t(
                                                            'common:edit_client_view.fields.vat_id_help'
                                                        )}
                                                        label={
                                                            <>
                                                                {t(
                                                                    'common:edit_client_view.fields.vat_id'
                                                                )}
                                                                <Tooltip
                                                                    className={cx(
                                                                        'vat-id-tooltip'
                                                                    )}
                                                                    content={t(
                                                                        `common:edit_client_view.fields.vat_id_status.${vatStatus.text}`
                                                                    )}
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        color={
                                                                            vatStatus.color
                                                                        }
                                                                        className={cx(
                                                                            'ml-1'
                                                                        )}
                                                                        icon={
                                                                            vatStatus.icon
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    />
                                                </Col>
                                            )}
                                    </Row>
                                    {!!canUpdate && (
                                        <Form.Submit
                                            icon="md/ic_save"
                                            loading={submitState.isLoading}
                                            label={t('common:buttons.save')}
                                        />
                                    )}
                                </>
                            );
                        }}
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default UpdateBillingDetailsTab;
