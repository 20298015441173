import React, { useMemo } from 'react';

import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import styles from '../SiteStatsTab.scss';
import useLang from 'hooks/useLang';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const PieChart = ({ data, isLoading }) => {
    const { t } = useTranslation();
    const { lang } = useLang();

    const chartData = useMemo(() => {
        const results = data || [];
        const sum = results.reduce((sum, current) => {
            return sum + (current.value || 0);
        }, 0);
        const chart = {
            series: results.map((i) => {
                // convert values to percentage as the chart accepts values between 0 and 100
                const percent = (i.value / sum) * 100;
                return isNaN(percent) ? 0 : percent;
            }),
            options: {
                chart: {
                    width: 420,
                    type: 'radialBar',
                    fontFamily: 'Noto Sans, sans-serif',
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: '30%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: false,
                            },
                        },
                    },
                },
                colors: results.map(
                    (i) => styles[`device-${i.device}`] || '#000'
                ),
                labels: results.map((i) => t(`common:devices.${i.device}`)),
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '16px',
                    position: 'left',
                    offsetX: 0,
                    offsetY: 15,
                    labels: {
                        useSeriesColors: true,
                    },
                    markers: {
                        size: 0,
                    },
                    formatter: function (seriesName, opts) {
                        const value = _get(
                            results,
                            `[${opts.seriesIndex}].value`,
                            0
                        );
                        let percent = ((value / sum) * 100).toFixed(2);
                        if (isNaN(percent)) {
                            percent = 0;
                        }

                        return `${seriesName}: ${value} (${percent}%)`;
                    },
                    itemMargin: {
                        vertical: 3,
                    },
                },
                responsive: [
                    {
                        breakpoint: 550,
                        options: {
                            legend: {
                                floating: false,
                                position: 'bottom',
                                fontSize: '12px',
                                offsetY: 0,
                            },
                        },
                    },
                ],
            },
        };

        return chart;
    }, [isLoading, lang]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <Chart
            {...chartData}
            type="radialBar"
            height={355}
            className={cx('custom-shadow')}
        />
    );
};

PieChart.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default PieChart;
