import React, { useEffect } from 'react';

import AnimatedList from 'components/AnimatedList';
import ConsentListItem from '../ConsentListItem';
import NotFound from 'views/other/NotFound';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './ConsentsList.scss';
import useListing from 'hooks/useListing';
import useNotifications from 'hooks/useNotifications';

const cx = classNames(styles);

const ConsentsList = ({ siteUuid, search, onConsentItemClick }) => {
    const notify = useNotifications();
    const [consents, fetchMeta, err] = useListing(
        `/common/sites/${siteUuid}/consents`,
        { search, per_page: 50 },
        true,
        false
    );

    useEffect(() => {
        if (fetchMeta.isError && !!err.validation && err.validation.search) {
            notify.error(`${err.message} (${err.validation.search})`);
        }
    }, [fetchMeta.isError, notify]);

    if (fetchMeta.isLoading) {
        return (
            <div className={cx('py-3')}>
                <Spinner />
            </div>
        );
    }

    if (err && err.status && err.status >= 400 && err.status <= 500) {
        return (
            <div className={cx('p-3')}>
                <NotFound />
            </div>
        );
    }

    return (
        <div>
            <AnimatedList
                list={consents.data}
                itemKey="uuid"
                itemComponent={ConsentListItem}
                itemSpacing={0}
                itemContainerProps={{
                    onClick: onConsentItemClick,
                    className: cx('item'),
                }}
            />
        </div>
    );
};

ConsentsList.propTypes = {
    siteUuid: PropTypes.string,
    search: PropTypes.string,
    onConsentItemClick: PropTypes.func,
};

ConsentsList.defaultProps = {};

export default ConsentsList;
