import ChoosePlanButton from 'components/ChoosePlanButton';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './LockedFeatureNotice.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const LockedFeatureNotice = ({
    className,
    noBackdrop = false,
    title,
    absolute,
    requiredPlan,
    currentPlanUuid,
    clientUuid,
    siteUuid,
    trialEndsAt,
    onPlanChanged,
    disabled = false,
}) => {
    const { t } = useTranslation();
    return (
        <>
            {!noBackdrop && !!absolute && <div className={cx('backdrop')} />}
            <div className={cx(className, 'notice', { absolute })}>
                <h6 className={cx('title')}>
                    {title || t('common:locked_feature.upgrade_title')}
                </h6>
                <p className={cx('info')}>
                    {t(`common:locked_feature.upgrade_${requiredPlan}_notice`)}
                </p>

                <ChoosePlanButton
                    label={t('common:locked_feature.upgrade_btn')}
                    initialPlanUuid={currentPlanUuid}
                    clientUuid={clientUuid}
                    siteUuid={siteUuid}
                    trialEndsAt={trialEndsAt}
                    disabled={!!disabled}
                    onChosen={onPlanChanged}
                />
            </div>
        </>
    );
};

LockedFeatureNotice.propTypes = {
    noBackdrop: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.node,
    absolute: PropTypes.bool,
    requiredPlan: PropTypes.oneOf(['basic', 'plus']),
    currentPlanUuid: PropTypes.string,
    clientUuid: PropTypes.string,
    siteUuid: PropTypes.string,
    trialEndsAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onPlanChanged: PropTypes.func,
    disabled: PropTypes.bool,
};

LockedFeatureNotice.defaultProps = {};

export default LockedFeatureNotice;
