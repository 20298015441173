import React, { useMemo } from 'react';

import { FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import styles from './FormError.scss';
import useFormErrors from 'hooks/useFormErrors';

const cx = classNames(styles);

const FormError = ({ name, className, ...props }) => {
    const errors = useFormErrors(name);
    if (!errors) {
        return null;
    }
    return (
        <FormFeedback
            {...props}
            className={cx('d-block', className)}
            valid={false}
        >
            {errors.map((error, i) => (
                <p key={i}>{error}</p>
            ))}
        </FormFeedback>
    );
};

FormError.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
};

FormError.defaultProps = {
    name: '',
};

export default FormError;
