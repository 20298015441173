import { convertImageBase64ToBlob } from 'helpers/images';

const prepareSaveData = (form, original) => {
    const newLogo = convertImageBase64ToBlob(form.whitelabel_logo || '');
    const newLogoSmall = convertImageBase64ToBlob(
        form.whitelabel_logo_small || ''
    );
    const newLogoAuth = convertImageBase64ToBlob(
        form.whitelabel_logo_auth || ''
    );
    const newBannerLogo = convertImageBase64ToBlob(
        form.whitelabel_banner_logo || ''
    );
    const newFaviconLogo = convertImageBase64ToBlob(
        form.whitelabel_logo_favicon || ''
    );

    return {
        ...form,
        whitelabel_banner_logo: form.whitelabel_banner_logo
            ? original.whitelabel_banner_logo
            : null,
        new_whitelabel_banner_logo: newBannerLogo || undefined,
        whitelabel_logo: form.whitelabel_logo ? original.whitelabel_logo : null,
        new_whitelabel_logo: newLogo || undefined,
        whitelabel_logo_small: form.whitelabel_logo_small
            ? original.whitelabel_logo_small
            : null,
        new_whitelabel_logo_small: newLogoSmall || undefined,
        whitelabel_logo_auth: form.whitelabel_logo_auth
            ? original.whitelabel_logo_auth
            : null,
        new_whitelabel_logo_auth: newLogoAuth || undefined,
        whitelabel_logo_favicon: form.whitelabel_logo_favicon
            ? original.whitelabel_logo_favicon
            : null,
        new_whitelabel_logo_favicon: newFaviconLogo || undefined,
    };
};

export default prepareSaveData;
