const fetchIcon = async (iconPath) => {
    if (iconPath.indexOf('/') < 0) {
        throw new Error(
            'icon prop must contain set, e.g. <Icon icon="md/ic_done"'
        );
    }
    const iconPathParts = iconPath.split('/');
    const set = iconPathParts[0];

    if (process.env.NODE_ENV === 'production') {
        let iconImport = () => {};
        const iconPathNoSet = iconPath.replace(`${set}/`, '');
        switch (set) {
            case 'fa':
                iconImport = import(
                    /* webpackChunkName: "icons/fa/[request]" */ `@cookiefirst/react-icons-kit/fa/${iconPathNoSet}`
                );
                break;
            case 'md':
                iconImport = import(
                    /* webpackChunkName: "icons/md/[request]" */ `@cookiefirst/react-icons-kit/md/${iconPathNoSet}`
                );
                break;
            case 'feather':
                iconImport = import(
                    /* webpackChunkName: "icons/feather/[request]" */ `@cookiefirst/react-icons-kit/feather/${iconPathNoSet}`
                );
                break;
            case 'subway':
                iconImport = import(
                    /* webpackChunkName: "icons/subway/[request]" */ `@cookiefirst/react-icons-kit/subway/${iconPathNoSet}`
                );
                break;
            case 'icomoon':
                iconImport = import(
                    /* webpackChunkName: "icons/icomoon/[request]" */ `@cookiefirst/react-icons-kit/icomoon/${iconPathNoSet}`
                );
                break;
            default:
                break;
        }
        const iconData = await iconImport;
        if (!iconData.default) {
            return { children: [] };
        }
        return iconData.default;
    }
};

export default fetchIcon;
