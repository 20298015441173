import React from 'react';
import Spinner from 'components/Spinner';
import createAsyncComponent from 'helpers/createAsyncComponent';

const Fallback = () => (
    <div>
        <Spinner className="asyncInputSpinner" inline />
    </div>
);

export default (input) => createAsyncComponent(input, Fallback);
