import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import dayjs from 'helpers/dayjs';
import styles from './FormattedDate.scss';
import useLang from 'hooks/useLang';

const cx = classNames(styles);

const FormattedDate = ({ from, format = 'LLL', utc = false }) => {
    const { lang } = useLang();
    const formattedDate = useMemo(() => {
        if (utc) {
            return dayjs(from).utc().locale(lang).format(format);
        }

        return dayjs(from).locale(lang).format(format);
    }, [from, format, lang, utc]);

    return formattedDate;
};

FormattedDate.propTypes = {
    from: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    format: PropTypes.string, // see https://day.js.org/docs/en/display/format
    utc: PropTypes.bool,
};

export default FormattedDate;
