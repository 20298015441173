import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './AnimatedList.scss';

const cx = classNames(styles);

const AnimatedList = ({
    list = [],
    itemComponent = () => null,
    itemKey = null,
    itemDelay = 0.2,
    itemSpacing = 3,
    itemContainerProps = {},
    getItemProps = (item) => ({}),
    ...props
}) => {
    if (!Array.isArray(list)) {
        return null;
    }

    return list.map((item, index) => {
        const containerProps = { ...(itemContainerProps || {}) };

        // pass item as first argument to any event callback passed to container
        for (const key of Object.keys(containerProps)) {
            if (
                key.indexOf('on') === 0 &&
                typeof containerProps[key] === 'function'
            ) {
                const originalCallback = itemContainerProps[key];
                containerProps[key] = (...args) => {
                    originalCallback(item, ...args);
                };
            }
        }

        const ItemComponent = itemComponent;
        const itemProps = { ...props, ...getItemProps(item), item };
        return (
            <div
                {...containerProps}
                key={itemKey ? item[itemKey] : index}
                className={cx(containerProps.className || '', 'item', {
                    [`mb-${itemSpacing}`]: index !== list.length - 1,
                })}
                style={{ animationDelay: `${index * itemDelay}s` }}
            >
                <ItemComponent {...itemProps} />
            </div>
        );
    });
};

AnimatedList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.object),
    itemComponent: PropTypes.func,
    itemKey: PropTypes.string,
    itemDelay: PropTypes.number,
    itemSpacing: PropTypes.number,
    itemContainerProps: PropTypes.object,
    getItemProps: PropTypes.func,
};

AnimatedList.defaultProps = {};

export default AnimatedList;
