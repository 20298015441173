import {
    Modal as BsModal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

import Button from 'components/Button';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './Modal.scss';

const cx = classNames(styles);
/* eslint-disable react/prop-types  */
const TitleTag = ({ className, ...props }) => <React.Fragment {...props} />;
/* eslint-enable react/prop-types */

const Modal = ({ isOpen, disabled, onClose, title, children, ...props }) => (
    <BsModal
        {...props}
        contentClassName={cx('content')}
        isOpen={isOpen}
        toggle={!disabled ? onClose : undefined}
        backdrop={disabled ? 'static' : undefined}
    >
        <ModalHeader
            className={cx('header')}
            tag={TitleTag}
            close={
                !disabled ? (
                    <Button
                        className={cx('close')}
                        onClick={onClose}
                        color="link"
                    >
                        <Icon icon="md/ic_close" />
                    </Button>
                ) : null
            }
            toggle={!disabled ? onClose : undefined}
        >
            <h5 className={cx('title')}>{title}</h5>
        </ModalHeader>
        {children}
    </BsModal>
);

Modal.propTypes = {
    disabled: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.node,
    children: PropTypes.node,
};

Modal.defaultProps = {
    disabled: false,
    isOpen: false,
    onClose: _noop,
    title: 'Modal title',
    children: null,
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
