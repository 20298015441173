import { Col, Row } from 'reactstrap';
import { Elements, StripeProvider } from 'react-stripe-elements';

import API from 'helpers/api';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'components/Spinner';
import UpdateCardForm from 'components/UpdateCardForm';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'helpers/classNames';
import styles from './UpdateCardDetailsTab.scss';
import useAppState from 'hooks/useAppState';
import useRequest from 'hooks/useRequest';

const cx = classNames(styles);

const UpdateCardDetailsTab = ({ reload }) => {
    const { stripeKey, user } = useAppState();
    const [cardData, fetchErr, fetchState] = useRequest(
        API.get(`/client/clients/${user.client.uuid}/get-payment-data`)
    );
    if (fetchState.isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <Row>
                <Col xs="12">
                    <StripeProvider stripe={window.Stripe(stripeKey)}>
                        <Elements>
                            <UpdateCardForm
                                reload={reload}
                                cardHolder={cardData ? cardData.cardHolder : ''}
                            />
                        </Elements>
                    </StripeProvider>
                </Col>
            </Row>
        </>
    );
};

UpdateCardDetailsTab.propTypes = {
    reload: PropTypes.func,
};

UpdateCardDetailsTab.defaultProps = {
    reload: () => null,
};

export default UpdateCardDetailsTab;
