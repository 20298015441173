import { Alert, Col, Row } from 'reactstrap';
import React, { useCallback, useEffect, useMemo } from 'react';

import Client from 'models/Client';
import Form from 'components/Form';
import PropTypes from 'prop-types';
import RegenerateApiKeyButton from 'components/RegenerateApiKeyButton';
import Spinner from 'components/Spinner';
import _get from 'lodash/get';
import api from 'helpers/api';
import classNames from 'helpers/classNames';
import styles from './ClientApiAccessTab.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);

const ClientApiAccessTab = ({ client, reload }) => {
    const { user } = useAppState();
    const [trans] = useTranslate('common:edit_client_view.fields.api_access');
    const [t] = useTranslate();
    const apiNS = useApiNamespace();
    const notify = useNotifications();
    const api_customer = useMemo(
        () => ({
            allowed_ips: _get(client, 'api_customer.allowed_ips') || [],
            status: _get(client, 'api_customer.status') || 0,
        }),
        [client]
    );
    const is_form_enabled = api_customer.status === 1;

    const [request, res, err, saveStatus] = useExecuteRequest();
    useEffect(() => {
        if (saveStatus.isError) {
            notify.error(
                <>
                    <p>{t('common:edit_client_view.failed_to_update')}</p>
                    <p>{err.message}</p>
                </>
            );
        }

        if (saveStatus.isSuccess) {
            notify.success(t('common:edit_client_view.client_updated'));
            reload();
        }
    }, [res, saveStatus.isSuccess, saveStatus.isError]);

    const handleSubmit = useCallback(
        (form) => {
            if (!user.is_admin && !is_form_enabled) {
                return;
            }
            const url = `/${apiNS}/clients/${client.uuid}/api-access`;
            request(api.put(url, form));
        },
        [request, client]
    );

    return (
        <>
            {!!saveStatus.isLoading && <Spinner />}
            {!user.is_admin && !is_form_enabled && (
                <Alert color="warning">{trans('status_disabled_note')}</Alert>
            )}
            <div className={cx({ 'd-none': saveStatus.isLoading })}>
                <Form.Label>{trans('api_key')}</Form.Label>
                <Form.HelpText>{trans('api_key_help')}</Form.HelpText>
                <RegenerateApiKeyButton
                    disabled={!is_form_enabled}
                    clientUuid={client.uuid}
                />
            </div>
            <Form
                initialValues={api_customer}
                error={err}
                className={cx('mt-3', { 'd-none': saveStatus.isLoading })}
                onSubmit={handleSubmit}
            >
                <Row>
                    {user.is_admin && (
                        <Col md="6">
                            <Form.Field
                                type="SingleDropdownInput"
                                name="status"
                                options={[
                                    {
                                        value: 0,
                                        label: trans('status_disabled'),
                                    },
                                    {
                                        value: 1,
                                        label: trans('status_enabled'),
                                    },
                                ]}
                                label={trans('status')}
                            />
                        </Col>
                    )}
                    <Col md="12" />
                    <Col md="6">
                        <Form.Field
                            label={trans('allowed_ips')}
                            helpText={trans('allowed_ips_help')}
                            name="allowed_ips"
                            type="Repeater"
                            itemType="text"
                            disabled={!is_form_enabled}
                        />
                    </Col>

                    <Col xs="12" className={cx('mt-3')}>
                        <Form.Submit
                            icon="md/ic_save"
                            disabled={!user.is_admin && !is_form_enabled}
                        >
                            {t('common:buttons.save')}
                        </Form.Submit>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

ClientApiAccessTab.propTypes = {
    client: PropTypes.instanceOf(Client),
    reload: PropTypes.func,
};

ClientApiAccessTab.defaultProps = {};

export default ClientApiAccessTab;
