import {
    CLIENT_TYPE_ADMIN_OPTIONS,
    CLIENT_TYPE_BUSINESS,
} from 'constants/clientTypes';
import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect } from 'react';
import {
    VAT_VALIDATION_STATUSES,
    VAT_VALIDATION_STATUS_INVALID,
} from 'constants/vat-validation-statuses';

import Client from 'models/Client';
import Form from 'components/Form';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import _uniqBy from 'lodash/uniqBy';
import api from 'helpers/api';
import classNames from 'helpers/classNames';
import { countries } from 'constants/geoData';
import dayjs from 'helpers/dayjs';
import styles from './ClientBasicSettingsTab.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import usePlans from 'hooks/usePlans';
import useTranslate from 'hooks/useTranslate';
import useUsers from 'hooks/useUsers';

const cx = classNames(styles);
const planToKey = (plan) => `${plan.config_name}-${plan.billing_period}`;

const ClientBasicSettingsTab = ({ client, reload }) => {
    const [t] = useTranslate();
    const [trans] = useTranslate('common:edit_client_view.fields');
    const { user, vatCountries } = useAppState();
    const notify = useNotifications();
    const apiNS = useApiNamespace();
    const [request, res, err, saveStatus] = useExecuteRequest();
    const handleSubmit = useCallback(
        (form) => {
            request(
                api.request('PUT', `/${apiNS}/clients/${client.uuid}`, {
                    ...form,
                    trial_ends_at:
                        client.trial_ends_at && form.trial_ends_at
                            ? dayjs(
                                  form.trial_ends_at,
                                  'YYYY-MM-DD HH:mm:ss'
                              ).unix()
                            : null,
                })
            );
        },
        [request, client]
    );

    const [users, usersStatus] = useUsers({
        clients: [client.uuid],
        per_page: -1,
        include_current: true,
    });
    // only load list of plans when user is admin
    const [plans, plansStatus] = usePlans({}, user.is_admin);

    useEffect(() => {
        if (saveStatus.isError) {
            notify.error(
                <>
                    <p>{t('common:edit_client_view.failed_to_update')}</p>
                    <p>{err.message}</p>
                    {Object.values(err.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </>
            );
        }

        if (saveStatus.isSuccess) {
            notify.success(t('common:common.saved'));
            setTimeout(() => {
                reload();
            }, [500]);
        }
    }, [res, saveStatus.isSuccess, saveStatus.isError]);

    const isLoading =
        saveStatus.isLoading || plansStatus.isLoading || usersStatus.isLoading;

    return (
        <>
            <Form
                isSubmitting={isLoading}
                initialValues={{
                    ...client,
                    allowed_plans: (client.allowed_plans || []).map(planToKey),
                    trial_ends_at: client.trial_ends_at
                        ? dayjs
                              .unix(client.trial_ends_at)
                              .format('YYYY-MM-DD HH:mm:ss')
                        : '---',
                }}
                error={err}
                onSubmit={handleSubmit}
            >
                {({ data: formData }) => {
                    const is_standalone = client.uuid === formData.manager_uuid;
                    const vatStatus =
                        VAT_VALIDATION_STATUSES[
                            formData.vat_id_validation_status
                        ] ||
                        VAT_VALIDATION_STATUSES[VAT_VALIDATION_STATUS_INVALID];
                    return (
                        <>
                            <Row>
                                <Col md="6">
                                    <Form.Field
                                        label={trans('name')}
                                        name="name"
                                        required
                                    />
                                </Col>

                                {user.is_admin && (
                                    <Col md="6">
                                        <Form.Field
                                            label={trans('manager_uuid')}
                                            type="SelectClient"
                                            name="manager_uuid"
                                            required
                                            placeholder={trans(
                                                'select_manager'
                                            )}
                                        />
                                    </Col>
                                )}
                            </Row>
                            <Row className={cx('align-items-end')}>
                                <Col md="6">
                                    <Form.Field
                                        label={trans('email')}
                                        type="email"
                                        name="email"
                                        required
                                    />
                                </Col>
                                <Col md="6">
                                    <Form.Field
                                        label={trans('admin_user_uuid')}
                                        helpText={
                                            user.is_admin
                                                ? trans('admin_user_help_admin')
                                                : user.is_reseller
                                                ? trans(
                                                      'admin_user_help_reseller'
                                                  )
                                                : trans('admin_user_help')
                                        }
                                        type="SingleDropdownInput"
                                        searchable
                                        allowClear
                                        name="admin_user_uuid"
                                        disabled={users === null}
                                        placeholder={
                                            users
                                                ? t('common:common.select')
                                                : t('common:common.loading')
                                        }
                                        options={
                                            users
                                                ? users.data.map((user) => ({
                                                      value: user.uuid,
                                                      label: user.display_name
                                                          ? `${user.display_name} (${user.full_name})`
                                                          : user.full_name,
                                                  }))
                                                : []
                                        }
                                    />
                                </Col>
                                <Col md="6">
                                    <Form.Field
                                        label={trans('street')}
                                        name="street"
                                        required={is_standalone}
                                    />
                                </Col>
                                <Col md="6">
                                    <Form.Field
                                        label={trans('street_number')}
                                        name="street_number"
                                        required={is_standalone}
                                    />
                                </Col>
                                <Col md="6">
                                    <Form.Field
                                        label={trans('apartment')}
                                        name="apartment"
                                    />
                                </Col>
                                <Col md="6">
                                    <Form.Field
                                        label={trans('postcode')}
                                        name="postcode"
                                        required={is_standalone}
                                    />
                                </Col>
                                <Col md="6">
                                    <Form.Field
                                        label={trans('city')}
                                        name="city"
                                        required={is_standalone}
                                    />
                                </Col>
                                <Col md="6">
                                    <Form.Field
                                        label={trans('country_code')}
                                        name="country_code"
                                        type="SingleDropdownInput"
                                        searchable
                                        required={is_standalone}
                                        options={countries}
                                    />
                                </Col>
                            </Row>
                            <Row className={cx('align-items-end')}>
                                <Col xs="12" md="6">
                                    <Form.Field
                                        type="SingleDropdownInput"
                                        required={is_standalone}
                                        helpText={t(
                                            'common:client_profile.client_type.help'
                                        )}
                                        label={t(
                                            'common:client_profile.client_type.title'
                                        )}
                                        options={CLIENT_TYPE_ADMIN_OPTIONS(t)}
                                        name="client_type"
                                    />
                                </Col>
                                <Col xs="12" md="6">
                                    <Form.Field
                                        name="vat_id"
                                        required={
                                            formData.client_type ===
                                                CLIENT_TYPE_BUSINESS &&
                                            vatCountries.includes(
                                                formData.country_code
                                            )
                                        }
                                        disabled={
                                            formData.client_type !==
                                                CLIENT_TYPE_BUSINESS ||
                                            !vatCountries.includes(
                                                formData.country_code
                                            )
                                        }
                                        helpText={trans('vat_id_help')}
                                        label={
                                            <>
                                                {trans('vat_id')}
                                                <Tooltip
                                                    className={cx(
                                                        'vat-id-tooltip'
                                                    )}
                                                    label={trans(
                                                        `vat_id_status.${vatStatus.text}`
                                                    )}
                                                >
                                                    <Icon
                                                        color={vatStatus.color}
                                                        className={cx('ml-1')}
                                                        icon={vatStatus.icon}
                                                    />
                                                </Tooltip>
                                            </>
                                        }
                                    />
                                </Col>
                            </Row>

                            {!!user.is_admin && (
                                <Row className={cx('mt-5')}>
                                    <Col xs="12">
                                        <h2>
                                            {t(
                                                'common:edit_client_view.admin_only_section'
                                            )}
                                        </h2>
                                    </Col>
                                    <Col md="6">
                                        <Form.Field
                                            type="Toggle"
                                            inlineLabel={trans('is_reseller')}
                                            name="is_reseller"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <Form.Field
                                            type="Toggle"
                                            inlineLabel={trans(
                                                'allow_wildcard_urls'
                                            )}
                                            name="allow_wildcard_urls"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <Form.Field
                                            label={trans('dev_urls_number')}
                                            name="dev_urls_number"
                                            type="number"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('trial_ends_at')}
                                            disabled={
                                                !client.trial_ends_at ||
                                                dayjs
                                                    .unix(client.trial_ends_at)
                                                    .isBefore(dayjs(), 'second')
                                            }
                                            placeholder={'YYYY-MM-DD HH:mm:ss'}
                                            name="trial_ends_at"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12">
                                        <Form.Field
                                            label={trans('allowed_plans')}
                                            name="allowed_plans"
                                            type="ChoiceGroup"
                                            multiple
                                            options={_uniqBy(
                                                plans.data,
                                                planToKey
                                            )
                                                .sort((a, b) => {
                                                    return a.config_name >
                                                        b.config_name
                                                        ? 1
                                                        : -1;
                                                })
                                                .map((p) => ({
                                                    value: planToKey(p),
                                                    label: `${p.name} (${p.billing_period})`,
                                                }))}
                                        />
                                    </Col>
                                </Row>
                            )}

                            <Form.Submit icon="md/ic_save">
                                {t('common:buttons.save')}
                            </Form.Submit>
                        </>
                    );
                }}
            </Form>
        </>
    );
};

ClientBasicSettingsTab.propTypes = {
    client: PropTypes.instanceOf(Client),
    reload: PropTypes.func,
};

ClientBasicSettingsTab.defaultProps = {};

export default ClientBasicSettingsTab;
