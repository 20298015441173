import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import classNames from 'helpers/classNames';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames();
const INITIAL_VALUES = {
    password: '',
    new_password: '',
    new_password_confirmation: '',
};

const ChangePasswordForm = () => {
    const { t } = useTranslation();
    const { user } = useAppState();
    const notify = useNotifications();
    const [request, res, err, submitState] = useExecuteRequest();
    const handleSubmit = (data) => {
        if (submitState.isLoading) {
            return;
        }
        request(API.put(`/common/users/${user.uuid}/update-password`, data));
    };

    useEffect(() => {
        if (submitState.isSuccess) {
            notify.success(t('common:common.saved'));
        }
        if (submitState.isError) {
            notify.error(err.message);
        }
    }, [submitState.isSuccess, submitState.isError, notify]);

    return (
        <>
            <Form
                initialValues={{
                    ...INITIAL_VALUES,
                    email: user.email,
                }}
                error={err}
                onSubmit={handleSubmit}
            >
                {/* hidden field and autocomplete attributes are suggested by https://www.chromium.org/developers/design-documents/create-amazing-password-forms */}
                <Form.Field
                    id="username"
                    className={cx('d-none')}
                    type="text"
                    name="email"
                    autoComplete="email"
                />
                <Form.Field
                    id="current-password"
                    autoComplete="current-password"
                    disabled={submitState.isLoading}
                    name="password"
                    label={t(
                        'common:profile.change_password_form.current_password'
                    )}
                    type="password"
                    required
                />
                <Form.Field
                    id="new-password"
                    autoComplete="new-password"
                    disabled={submitState.isLoading}
                    name="new_password"
                    label={t(
                        'common:profile.change_password_form.new_password'
                    )}
                    type="password"
                    required
                />
                <Form.Field
                    id="new-password-confirmation"
                    autoComplete="new-password"
                    disabled={submitState.isLoading}
                    name="new_password_confirmation"
                    label={t(
                        'common:profile.change_password_form.new_password_confirmation'
                    )}
                    type="password"
                    required
                />
                <Form.Submit
                    icon="md/ic_save"
                    loading={submitState.isLoading}
                    label={t('common:buttons.save')}
                />
            </Form>
        </>
    );
};

export default ChangePasswordForm;
