const cleanLangString = (lang) => {
    if (lang.length === 2) {
        return lang;
    }
    if (lang.includes('-')) {
        return lang.split('-')[0];
    }

    return '';
};

const detectBrowserLang = (whitelist = []) => {
    // detect lang from browser preferences
    const found = [];
    if (typeof navigator !== 'undefined') {
        if (navigator.languages) {
            // chrome only: needs conversion to array
            const nav_langs = [...navigator.languages];
            found.push(...nav_langs);
        }

        found.push(navigator.userLanguage || navigator.language);
    }

    const browserLangs = found
        .filter((i) => typeof i === 'string')
        .map(cleanLangString)
        .map((i) => i.toLowerCase())
        .filter((i) => !!i);

    for (const lang of browserLangs) {
        if (whitelist.includes(lang)) {
            return lang;
        }
    }

    return 'en';
};

export default detectBrowserLang;
