import { Col, Row } from 'reactstrap';

import AuditTrailItem from '../AuditTrailItem';
import Button from 'components/Button';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './MobileAuditTrail.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import { useCallback } from 'react';
import useConsentExport from 'hooks/useConsentExport';
import useListing from 'hooks/useListing';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const MobileAuditTrail = ({ siteUuid, consent, onGoBack }) => {
    const handleExport = useConsentExport();
    const apiNS = useApiNamespace();
    const { t } = useTranslation();

    const [consents, { isSuccess, isLoading }] = useListing(
        `common/sites/${siteUuid}/consents/${consent?.visitor_id}`,
        {},
        !!consent
    );

    const handleExportBtnClick = useCallback(() => {
        if (!isSuccess) {
            return;
        }
        handleExport(siteUuid, {
            search: consent.visitor_id,
        });
    }, [siteUuid, consent, isSuccess]);

    return (
        <Modal
            isOpen={!!consent}
            onClose={onGoBack}
            title={consent ? consent.visitor_id : ''}
        >
            {isLoading && (
                <div className={cx('py-3')}>
                    <Spinner />
                </div>
            )}
            {!isLoading && isSuccess && (
                <>
                    <Modal.Body className={cx('px-0')}>
                        <div className={cx('px-3', 'mb-2')}>
                            <Button
                                className={cx('w-100', 'downloadBtn')}
                                icon="md/ic_cloud_download"
                                onClick={handleExportBtnClick}
                                label={t(
                                    'common:consents_list_view.download_csv'
                                )}
                            />
                        </div>
                        <ul className={cx('list', 'mb-0')}>
                            {consents.data.map((consent) => (
                                <AuditTrailItem
                                    key={consent.uuid}
                                    consent={consent}
                                />
                            ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row
                            form
                            className={cx('w-100', 'align-items-stretch')}
                        >
                            <Col xs="6" className={cx('footerCol')}>
                                <Button
                                    className={cx('w-100', 'h-100')}
                                    icon="md/ic_cloud_download"
                                    onClick={handleExportBtnClick}
                                    label={t(
                                        'common:consents_list_view.download_csv'
                                    )}
                                />
                            </Col>
                            <Col xs="6" className={cx('footerCol')}>
                                <Button
                                    className={cx('w-100', 'h-100')}
                                    outline
                                    onClick={onGoBack}
                                    label={t(`${apiNS}:common.close`)}
                                />
                            </Col>
                        </Row>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    );
};

MobileAuditTrail.propTypes = {
    siteUuid: PropTypes.string,
    consent: PropTypes.object,
    onGoBack: PropTypes.func,
};

export default MobileAuditTrail;
