import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'components/Spinner';
import { Table } from 'reactstrap';
import classNames from 'helpers/classNames';
import styles from './ClientInvoicesListing.scss';
import useListing from 'hooks/useListing';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const ClientInvoicesListing = ({ client = {} }) => {
    const { t } = useTranslation();
    const [invoices, reqState] = useListing(
        `/admin/clients/${client.uuid}/get-invoices-domains`,
        {},
        client.is_self_managed
    );

    if (reqState.isLoading) {
        return <Spinner />;
    }

    return (
        <Table responsive striped>
            <thead>
                <tr>
                    <th>{t('common:client_invoice_listing.invoice')}</th>
                    <th>{t('common:client_invoice_listing.sites')}</th>
                </tr>
            </thead>
            <tbody>
                {invoices.data.map((invoice) => (
                    <tr key={invoice.number}>
                        <th scope="row">{invoice.number}</th>
                        <td>
                            {invoice.sites.map((i) => i || '---').join(', ') ||
                                '---'}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

ClientInvoicesListing.propTypes = {
    client: PropTypes.shape({
        uuid: PropTypes.string,
    }),
};

export default ClientInvoicesListing;
