import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMedia, useWindowSize } from 'react-use';

import AuditTrail from './components/AuditTrail';
import Button from 'components/Button';
import ConsentsList from './components/ConsentsList';
import ErrorBoundary from 'core/ErrorBoundary';
import Icon from 'components/Icon';
import LockedFeatureNotice from 'components/LockedFeatureNotice/LockedFeatureNotice';
import MobileAuditTrail from './components/MobileAuditTrail';
import PropTypes from 'prop-types';
import Site from 'models/Site';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import styles from './SiteConsentsListTab.scss';
import useConsentExport from 'hooks/useConsentExport';
import { useSitePolicy } from 'policies/SitePolicy';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const SiteConsentsListTab = ({ site, reload }) => {
    const { t } = useTranslation();
    const [activeConsent, setActiveConsent] = useState(null);
    const container = useRef();
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const [containerHeight, setContainerHeight] = useState('auto');
    const isMobile = useMedia('(max-width: 767.99px)');
    const canChangePlan = useSitePolicy('changePlan', site);

    const handleExport = useConsentExport();
    const [search, setSearch] = useState('');

    const isFeatureLocked = useMemo(
        () => !site.getPlanFeature('consent_audit_trail'),
        []
    );

    /**
     * Update search value in state after user stops typing
     */
    const handleSearch = useMemo(
        () => _debounce((newSearch) => setSearch(newSearch), 300),
        []
    );

    useEffect(() => {
        if (!container.current) {
            return;
        }

        // calculate conciobsetainer height to be 100vh - the content above it - the view padding
        const rect = container.current.getBoundingClientRect();
        const viewPadding = isMobile ? 0 : 64;
        const heightModifier = rect.top + viewPadding;

        setContainerHeight(`calc(100vh - ${heightModifier}px)`);
    }, [windowHeight, windowWidth, isMobile]);

    return (
        <div
            ref={container}
            className={cx('tabView')}
            style={{ height: containerHeight }}
        >
            {isMobile ? (
                <MobileAuditTrail
                    siteUuid={site.uuid}
                    consent={activeConsent}
                    onGoBack={() => setActiveConsent(null)}
                />
            ) : (
                <AuditTrail
                    siteUuid={site.uuid}
                    consent={activeConsent}
                    onGoBack={() => setActiveConsent(null)}
                />
            )}

            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText className={cx('searchIconContainer')}>
                        <label htmlFor="search-id">
                            <Icon icon="md/ic_search" />
                            <span className={cx('sr-only')}>
                                {t('common:consents_list_view.search')}
                            </span>
                        </label>
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    id="search-id"
                    className={cx('searchInput')}
                    type="search"
                    name="search"
                    disabled={isFeatureLocked}
                    placeholder={t('common:consents_list_view.search')}
                    onChange={(e) => handleSearch(e.target.value)}
                    defaultValue=""
                />
                <InputGroupAddon
                    addonType="append"
                    className={cx('downloadAddon')}
                >
                    <InputGroupText className={cx('downloadBtnContainer')}>
                        <Button
                            className={cx('rounded-0', 'downloadBtn')}
                            icon="md/ic_cloud_download"
                            onClick={() => handleExport(site.uuid, { search })}
                            label={t('common:consents_list_view.download_csv')}
                        />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
            <div className={cx('consentsList')}>
                <ErrorBoundary>
                    {isFeatureLocked && (
                        <LockedFeatureNotice
                            disabled={!canChangePlan}
                            requiredPlan="plus"
                            siteUuid={site.uuid}
                            currentPlanUuid={_get(site, 'plan.uuid')}
                            clientUuid={_get(site, 'client.uuid')}
                            trialEndsAt={_get(site, 'client.trial_ends_at')}
                            onPlanChanged={reload}
                        />
                    )}
                    {!isFeatureLocked && (
                        <ConsentsList
                            siteUuid={site.uuid}
                            search={search}
                            onConsentItemClick={setActiveConsent}
                        />
                    )}
                </ErrorBoundary>
            </div>
        </div>
    );
};

SiteConsentsListTab.propTypes = {
    site: PropTypes.instanceOf(Site),
    reload: PropTypes.func,
};

export default SiteConsentsListTab;
