import PropTypes from 'prop-types';
import React from 'react';
import splitMultilineText from 'helpers/splitMultilineText';

const MultilineText = ({ wrapperTag, itemTag, text, ...props }) => {
    if (!text) {
        return null;
    }

    const paragraphs = splitMultilineText(text);
    if (!paragraphs.length) {
        return null;
    }

    const Wrapper = wrapperTag || React.Fragment;
    const Item = itemTag || 'p';

    return (
        <Wrapper>
            {paragraphs.map((item, index) => (
                <Item key={index}>{item}</Item>
            ))}
        </Wrapper>
    );
};

MultilineText.propTypes = {
    wrapperTag: PropTypes.string,
    itemTag: PropTypes.string,
    text: PropTypes.string,
};

MultilineText.defaultProps = {
    wrapperTag: null,
    itemTag: 'p',
    text: '',
};

export default MultilineText;
