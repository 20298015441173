const getPlanLabel = (plan, t) => {
    const __ = (key) => t(`components:choose_plan.${key}`);
    if (!plan) {
        return __('default_title');
    }

    if (plan.config_name === 'free') {
        return plan.name;
    }

    const billingPeriod = __(`billing_period.${plan.billing_period}`);

    return `${plan.name} (${billingPeriod})`;
};

export default getPlanLabel;
