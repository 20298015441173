import React, { useCallback, useMemo } from 'react';

import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './CopyToClipboard.scss';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const CopyToClipboard = ({ label, text, inline, ...props }) => {
    const notify = useNotifications();
    const { t } = useTranslation();
    const Tag = inline ? 'input' : 'textarea';
    const componentInstanceKey = useMemo(() => Math.random(), [text]);

    const executeCopy = useCallback(
        (e) => {
            const target = e.target;
            target.select();

            try {
                document.execCommand('copy');
                notify.success(t('components:copy_to_clipboard.copied'));
            } catch (err) {
                notify.error(t('components:copy_to_clipboard.failed'));
            }

            target.blur();
        },
        [notify]
    );
    return (
        <div {...props}>
            {!!label && <label className={cx('label')}>{label}</label>}
            <div className={cx('textContainer')}>
                <Tag
                    key={componentInstanceKey} // force re-render when text changes
                    className={cx('input')}
                    onClick={executeCopy}
                    readOnly
                    defaultValue={text}
                />
                <span className={cx('icon')}>
                    <Icon icon="md/ic_content_copy" />
                </span>
            </div>
        </div>
    );
};

CopyToClipboard.propTypes = {
    label: PropTypes.node,
    text: PropTypes.string,
    inline: PropTypes.bool,
};

CopyToClipboard.defaultProps = {
    label: '',
    text: '',
    inline: false,
};

export default CopyToClipboard;
