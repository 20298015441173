import ChangePasswordForm from 'views/common/account/ChangePasswordForm';
import ManageTwoFaAuthForm from 'views/common/account/ManageTwoFaAuthForm';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './PasswordTab.scss';

const cx = classNames(styles);

const PasswordTab = () => (
    <>
        <div className={cx('mb-3', 'mb-md-4')}>
            <ChangePasswordForm />
        </div>
        <ManageTwoFaAuthForm />
    </>
);

PasswordTab.propTypes = {};

PasswordTab.defaultProps = {};

export default PasswordTab;
