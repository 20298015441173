import PropTypes from 'prop-types';
import React from 'react';
import cx from 'clsx'; // imported from clsx package and not from helpers on purpose
import useInstanceSettings from 'hooks/useInstanceSettings';

const Preloader = ({ show }) => {
    const { logo_small } = useInstanceSettings();
    return (
        <div
            id="main-preloader"
            className={cx('preloader', { show })}
            style={{ display: 'none' }}
        >
            <img src={logo_small} className={cx('preloader-logo')} />
        </div>
    );
};

Preloader.propTypes = {
    show: PropTypes.bool,
};

Preloader.defaultProps = {
    show: false,
};

export default Preloader;
