import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './CountryFlag.scss';
import useFlag from './useFlag';

const cx = classNames(styles);

const CountryFlag = ({ className = '', country = null }) => {
    const flagUrl = useFlag(country);
    return (
        <span className={cx(className, 'flag-container')}>
            {!!country && (
                <span
                    className={cx('fib', 'flag')}
                    style={{ backgroundImage: `url(${flagUrl})` }}
                />
            )}
        </span>
    );
};

CountryFlag.propTypes = {
    className: PropTypes.string,
    country: PropTypes.string,
};

export default CountryFlag;
