import { Button as BsButton } from 'reactstrap';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './Button.scss';

const cx = classNames(styles);

const Button = ({
    children,
    icon,
    label,
    link,
    disabled,
    loading,
    color,
    className,
    outline,
    ...props
}) => (
    <BsButton
        {...props}
        disabled={disabled || loading}
        color={color}
        outline={outline || link}
        className={cx(className, 'button', { link })}
    >
        {!!loading && <Spinner inline className={cx('buttonSpinner')} />}
        <span className={cx('content')}>
            {!!icon && (
                <Icon
                    className={cx({ 'mr-2': !!(label || children) })}
                    icon={icon}
                />
            )}
            {label || children}
        </span>
    </BsButton>
);

Button.colors = [
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark',
    'link',
];

Button.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.oneOf([...Button.colors]),
    className: PropTypes.string,
    label: PropTypes.node,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    link: PropTypes.bool,
    loading: PropTypes.bool,
    outline: PropTypes.bool,
};

Button.defaultProps = {
    icon: null,
    color: Button.colors[0],
    className: '',
    label: null,
    children: null,
    disabled: false,
    link: false,
    loading: false,
    outline: false,
};

export default Button;
