/*global Promise*/
import React, { Suspense, useCallback, useEffect, useState } from 'react';

import API from 'helpers/api';
import LangContext from 'context/lang';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import dayjs from 'helpers/dayjs';
import initTranslations from './initTranslations';
import styles from './TranslationsProvider.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';

const cx = classNames(styles);

const TranslationsProvider = ({ children }) => {
    const { user, adminLanguages, updateAppState } = useAppState();
    const [isInitialized, setIsInitialized] = useState(false);
    const [i18nInstance, setI18nInstance] = useState(null);
    const [currentLang, setCurrentLang] = useState('en');
    const ns = useApiNamespace();
    const [getInfoBar] = useExecuteRequest();
    const url = '/common/latest-info-bar-message';

    useEffect(() => {
        initTranslations({
            userLang: user ? user.preferred_language : null,
            availableLanguages: adminLanguages,
            ns,
        })
            .then((i18n) => {
                const lang = i18n.languages[0];
                console.log(`Translations initialized for: ${lang}`);
                setI18nInstance(i18n);
                setCurrentLang(lang);
                dayjs.locale(lang);
            })
            .catch((e) => console.error(e.message))
            .finally(() => setIsInitialized(true));
    }, [user ? user.preferred_language : null]);

    const handleLangChange = useCallback(
        (newLang) => {
            if (newLang === currentLang) {
                return;
            }
            if (!i18nInstance) {
                return;
            }

            setIsInitialized(false);

            const promiseArray = [i18nInstance.changeLanguage(newLang)];

            if (user) {
                promiseArray.unshift(
                    getInfoBar(API.get(url, { lang: newLang }))
                );
            }

            Promise.all(promiseArray)
                .then((results) => {
                    updateAppState('infoBarMessage', results[0].infoBarMessage);
                    dayjs.locale(newLang);
                    document.documentElement.lang = newLang;
                    setCurrentLang(newLang);
                })
                .finally(() => setIsInitialized(true));
        },
        [currentLang, i18nInstance]
    );

    const handleReload = useCallback(() => {
        if (!i18nInstance) {
            window.location.reload();
            return;
        }

        setIsInitialized(false);
        i18nInstance.reloadResources().finally(() => setIsInitialized(true));
    }, [i18nInstance]);

    // perform some actions when lang changes
    useEffect(() => {
        if (window.zE) {
            window.zE(
                'webWidget',
                'setLocale',
                currentLang === 'de' ? currentLang : 'en-US'
            );
        }
    }, [currentLang]);

    if (!isInitialized) {
        return <Preloader show />;
    }
    return (
        <LangContext.Provider
            value={{
                lang: currentLang,
                changeLanguage: handleLangChange,
                reloadTranslations: handleReload,
            }}
        >
            <Suspense fallback={<Preloader show />}>{children}</Suspense>
        </LangContext.Provider>
    );
};

TranslationsProvider.propTypes = {
    children: PropTypes.node,
};

TranslationsProvider.defaultProps = {};

export default TranslationsProvider;
