import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './PlanFeature.scss';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);

const getFeatureSettings = ({ name, value, trans }) => {
    let disabled = false;
    let label = null;

    switch (name) {
        case 'widget_style_edit':
        case 'white_label_banner':
        case 'white_label_panel':
        case 'consent_reports':
        case 'consent_statistics':
        case 'consent_audit_trail':
        case 'banner_category_toggles':
            if (!value) {
                disabled = true;
            }
            break;
        case 'script_limit':
        case 'lang_limit':
            const sufix = value ? 'number' : 'none';
            label = trans(`${name}_${sufix}`, { count: value || undefined });
            break;
        default:
            break;
    }

    if (!label) {
        label = trans(`${name}_${value ? 'enabled' : 'disabled'}`);
    }
    return { disabled, label };
};

const PlanFeature = ({ name, value }) => {
    const [trans] = useTranslate('core:plan_features');
    const { label, disabled } = getFeatureSettings({ name, value, trans });

    if (!label) {
        return null;
    }

    return (
        <li className={cx('item', { disabled })}>
            <span>{label}</span>
        </li>
    );
};

PlanFeature.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
};

export default PlanFeature;
