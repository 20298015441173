import url from '../url';
import view from '../view';

export default [
    {
        path: url('auth.login'),
        view: view('auth/LoginView'),
    },
    {
        path: url('auth.register'),
        view: view('auth/RegisterView'),
    },
    {
        path: url('auth.resetPassword'),
        view: view('auth/RequestPasswordResetView'),
    },
    {
        path: url('auth.resetPassword', { token: ':token' }),
        view: view('auth/ResetPasswordView'),
    },
];
