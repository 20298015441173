import BasePolicy from './BasePolicy';
import Client from 'models/Client';
import User from 'helpers/user';
import usePolicy from 'hooks/usePolicy';

export const VIEW_ALL_PERMISSION = 'CLIENTS.VIEW_ALL';
export const VIEW_OWN_PERMISSION = 'CLIENTS.VIEW_OWN';
export const CREATE_ALL_PERMISSION = 'CLIENTS.CREATE_ALL';
export const CREATE_OWN_PERMISSION = 'CLIENTS.CREATE_OWN';
export const UPDATE_ALL_PERMISSION = 'CLIENTS.UPDATE_ALL';
export const UPDATE_OWN_PERMISSION = 'CLIENTS.UPDATE_OWN';
export const UPDATE_SELF_PERMISSION = 'CLIENTS.UPDATE_SELF';
export const VIEW_BILLING_ALL_PERMISSION = 'BILLING.VIEW_ALL';
export const VIEW_BILLING_OWN_PERMISSION = 'BILLING.VIEW_OWN';
export const UPDATE_BILLING_ALL_PERMISSION = 'BILLING.UPDATE_ALL';
export const UPDATE_BILLING_OWN_PERMISSION = 'BILLING.UPDATE_OWN';

export default class ClientPolicy extends BasePolicy {
    /**
     * Check if user can view the model listing
     * @param {User} user
     * @returns boolean
     */
    static viewAny(user) {
        return user.canAny([VIEW_ALL_PERMISSION, VIEW_OWN_PERMISSION]);
    }

    /**
     * Check if user can view the details view of model
     * @param {User} user
     * @param {Client} model
     * @returns boolean
     */
    static view(user, model) {
        if (user.can(VIEW_ALL_PERMISSION)) {
            return true;
        }

        if (user.can(VIEW_OWN_PERMISSION)) {
            return ClientPolicy.userOwnsModel(user, model);
        }
    }

    /**
     * Check if user can create new instances of model
     * @param {User} user
     * @returns boolean
     */
    static create(user) {
        return user.canAny([CREATE_ALL_PERMISSION, CREATE_OWN_PERMISSION]);
    }

    /**
     * Check if user can update an instance of model
     * @param {User} user
     * @param {Client} model
     * @returns boolean
     */
    static update(user, model) {
        // user with specific permission can manage all clients
        if (user.can(UPDATE_ALL_PERMISSION)) {
            return true;
        }

        // user can update their subclients
        if (
            user.can(UPDATE_OWN_PERMISSION) &&
            ClientPolicy.userOwnsModel(user, model)
        ) {
            return true;
        }
        // user who can update their own client data
        if (
            user.can(UPDATE_SELF_PERMISSION) &&
            user.client_uuid === model.uuid
        ) {
            return true;
        }
    }

    /**
     * Check if user can see billing details of a client
     * @param {User} user
     * @param {Client} model
     * @returns boolean
     */
    static viewBilling(user, model) {
        if (user.can(VIEW_BILLING_ALL_PERMISSION)) {
            return true;
        }
        if (user.can(VIEW_BILLING_OWN_PERMISSION)) {
            return ClientPolicy.userOwnsModel(user, model);
        }
    }

    /**
     * Check if user can update the billing details of a client
     * @param {User} user
     * @param {Client} model
     * @returns boolean
     */
    static updateBilling(user, model) {
        if (user.can(UPDATE_BILLING_ALL_PERMISSION)) {
            return true;
        }
        if (user.can(UPDATE_BILLING_OWN_PERMISSION)) {
            return ClientPolicy.userOwnsModel(user, model);
        }
    }

    /**
     * Check if user can delete an instance of model
     * @param {User} user
     * @param {Client} model
     * @returns boolean
     */
    static delete(user, model) {
        return ClientPolicy.update(user, model);
    }

    static userOwnsModel(user, model) {
        // user without client can't own any clients
        if (!user.client) {
            return false;
        }

        // client owns their own client
        if (user.client_uuid === model.uuid) {
            return true;
        }

        // user is a reseller user and is their subclient
        if (user.is_reseller && model.manager_uuid === user.client_uuid) {
            return true;
        }

        return false;
    }
}

/**
 * Check if user is allowed to perform an action
 * @param {string} permission
 * @param {Client} model
 * @returns {boolean}
 */
export const useClientPolicy = (permission, model) => {
    return usePolicy(ClientPolicy, permission, model);
};
