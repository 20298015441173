import API from 'helpers/api';
import qs from 'qs';

const execute = (siteUuid, { search = '', from = '', to = '' } = {}) => {
    const baseUrl = API._getUrl(`/common/sites/${siteUuid}/export-consents`);
    const query = qs.stringify({
        search: search || undefined,
        from: from || undefined,
        to: to || undefined,
    });

    const url = `${baseUrl}?${query}`;
    window.open(url, '_blank');
};

const useConsentExport = () => {
    return execute;
};

export default useConsentExport;
