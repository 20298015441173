import React, { useEffect } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

import MultilineText from 'components/MultilineText';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './NotificationToast.scss';
import { useSwipeable } from 'react-swipeable';

const cx = classNames(styles);
export const NOTIFICATION_DURATION = 3 * 1000;

const NotificationToast = ({
    className,
    show,
    notification,
    onDismiss,
    ...props
}) => {
    const handleDismiss = () => onDismiss(notification.id);

    useEffect(() => {
        // allow for permanent notifications
        if (notification.timeout < 0) {
            return;
        }

        const dismissTimeout = setTimeout(handleDismiss, notification.timeout);

        return () => clearTimeout(dismissTimeout);
    }, []);
    const swipeHandlers = useSwipeable({
        trackMouse: true,
        onSwipedRight: handleDismiss,
    });

    return (
        <Toast
            {...props}
            isOpen
            fade={false}
            className={cx('toast', className, {
                hide: !notification.show,
            })}
        >
            <div {...swipeHandlers}>
                <ToastHeader icon={notification.type} toggle={handleDismiss}>
                    {notification.title}
                </ToastHeader>
                <ToastBody className={cx('content')}>
                    {typeof notification.content === 'string' ? (
                        <MultilineText text={notification.content} />
                    ) : (
                        notification.content
                    )}
                </ToastBody>
            </div>
        </Toast>
    );
};

NotificationToast.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool,
    notification: PropTypes.object,
    onDismiss: PropTypes.func,
};

NotificationToast.defaultProps = {
    show: false,
    notification: {
        id: Math.random(),
        title: 'No title',
        content: 'No content',
        show: false,
        type: 'info',
        timeout: NOTIFICATION_DURATION,
    },
    onDismiss: _noop,
};

export default React.memo(NotificationToast);
